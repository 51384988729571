import { connect } from 'react-redux';
import { logout } from "./actions/applicationActions";
import { updateLayout } from './actions/layoutActions';
import KioskAppBar from "./KioskAppBar";


function mapStateToProps(state, ownProps) {
    const storeCode = state.application.currentStore?.code || state.application.groupStore?.code
    const location = state.application.storesConfiguration[storeCode]?.locationCode
    return {
        userData: state.application.userData,
        storeCode,
        storeDesc: state.application.currentStore?.desc || state.application.groupStore?.desc,
        groupStore: !!state.application.groupStore,
        history: ownProps.history,
        location,
        gisBaseUrl: state.application.applicationData.gisBaseUrl
    }
}

const KioskAppBarContainer = connect(mapStateToProps, {
    updateLayout,
    logout
})(KioskAppBar);

export default KioskAppBarContainer