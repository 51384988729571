import {connect} from 'react-redux';
import {setPage} from "../../../actions/layoutActions";
import {handleError} from '../../../react-eis-components/actions/uiActions';
import ContextSelectionPage from "./ContextSelectionPage";
import {updateApplication, logout} from "../../../actions/applicationActions";
import {
    updateTransaction,
    updateTransactionType,
    setTransactionOnWorkorder,
    setTransactionOnProject,
    setTransactionOnEquipment,
    setTransactionOnEmployee
} from "../../../actions/currentTransactionActions";

const mapStateToProps = (state, ownProps) => {
    const transactionType = ownProps.match.params.transactiontype;
    const storeCode = ownProps.match.params.storeCode;
    const store = state.application.currentStore;
    const storeConfiguration = state.application.storesConfiguration[storeCode];
    return {
        kioskMode: state.application.kioskMode,
        currentStore: store,
        currentTransaction: state.currentTransaction,
        transactionType: transactionType,
        storeConfiguration: storeConfiguration,
        storeAccess: state.application && state.application.storeAccess,
        isSimple: state.application.currentStore && state.application.storesConfiguration[state.application.currentStore.code] ?
            (state.application.storesConfiguration[state.application.currentStore.code].simple === '+') : false,
    }
};

const ContextSelectionPageContainer = connect(
    mapStateToProps, {
        setPage,
        updateApplication,
        updateTransaction,
        logout,
        updateTransactionType,
        setTransactionOnWorkorder,
        setTransactionOnProject,
        setTransactionOnEquipment,
        setTransactionOnEmployee,
        handleError
    }
)(ContextSelectionPage);

export default ContextSelectionPageContainer;
