import { handleError, showError, showSuccess } from '../../../../react-eis-components/actions/uiActions';
import { connect } from 'react-redux';
import { logout } from "../../../../actions/applicationActions";
import { updateAssetsForKit } from "../../../../actions/flexBuildingActions";
import { updateParts } from "../../../../actions/inboundProcessActions";
import { setPage } from "../../../../actions/layoutActions";
import SITPlaceItemsOnBin from "./SITPlaceItemsOnBin";
import { getAssets, getParts, getTransactionLines } from "../../../../reducers/inboundProcessReducer";


const mapStateToProps = (state, ownProps) => {
    const language = state.layout.lang;
    const store = state.application.currentStore;
    const storeConfiguration = store ? state.application.storesConfiguration[store.code] : {};
    const transLines = getTransactionLines(state);
    const parts =
        Object.entries(getParts(state)).reduce((acc, [key, val]) => ({...acc, [key]: {...val, bin: transLines[key]}}) ,{})

    return {
        applicationData: state.application.applicationData,
        storeConfiguration: storeConfiguration,
        translations: store ? state.application.translations[language]['KIOSK_' + store.code] : {},
        updateArticles: true,
        assetsForKit: state.flexBuilding.assetsForKit,
        documentId: ownProps.match.params.sitCode,
        storeCode: ownProps.match.params.storeCode,
        assets: getAssets(state),
        parts,
        storeAccess: state.application.storeAccess,
        currentStore: state.application.currentStore,
    }
};

const SITPlaceItemsOnBinContainer = connect(
    mapStateToProps, {
        setPage,
        logout,
        handleError,
        showSuccess,
        showError,
        updateAssetsForKit,
        updateParts,
    }
)(SITPlaceItemsOnBin);

export default SITPlaceItemsOnBinContainer;