export const ACTIONS = {
    INV_UPDATE_MODE_INFO: 'INV_UPDATE_MODE_INFO',
    INV_RESET_MODE_INFO: 'INV_RESET_MODE_INFO'
}

export function updateModeInfo(modeInfo) {
    return {
        type: ACTIONS.INV_UPDATE_MODE_INFO,
        modeInfo
    }
}

export function resetModeInfo() {
    return {
        type: ACTIONS.INV_RESET_MODE_INFO
    }
}

export default {
    updateModeInfo,
    resetModeInfo
};
