import { SET_REQUEST_ITEMS } from "../actions/requestProcessActions";

const itemsFromGrid2Object = itemsFromGrid =>
    Object
        .entries(itemsFromGrid)
        .reduce((obj, itemFromGrid) => Object.defineProperty(
            obj,
            itemFromGrid[0],
            {
                enumerable: true, 
                value: itemFromGrid[1]
            }
        ), {})

export default function requestProcess (state = {}, action) {
    switch (action.type) {
        case SET_REQUEST_ITEMS: {
            return {
                ...state,
                items: itemsFromGrid2Object(action.itemsFromGrid)
            }
        }
        default: {
            return state;
        }
    }
}