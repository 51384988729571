import { Container } from '@mui/material';
import EAMAutocomplete from 'eam-components/dist/ui/components/inputs-ng/EAMAutocomplete';
import React from 'react';
import BlockUi from 'react-block-ui';
import WS from '../../../tools/rest/WSPartSelection';
import BinLotQty from '../../components/elements/BinLotQty';
import KioskButton from "../../components/buttons/KioskButton";
import EAMSelect from 'eam-components/dist/ui/components/inputs-ng/EAMSelect';
import { createOnChangeHandler, createOnChangeHandlerObjectUpdate } from 'eam-components/dist/ui/components/inputs-ng/tools/input-tools';

const labelStyle = { fontSize: 18, minWidth: 230 };
const fieldStyle = { minWidth: 250 };

const loadOptionsForPartOrAsset = (storeCode) => (code) =>
    WS.autocompleteAvailableCodes(storeCode, code)

const loadBins = (storeCode, hint) =>
    WS.getBinsList(storeCode, encodeURIComponent(hint))
        .then(resp =>{
            resp.body.data = resp.body.data
                && resp.body.data.map(bin => ({...bin, code: bin.bin_code, desc: `${bin.bin_desc}`}))
            return resp;
        })

const submitBin2Bin  = (fromStoreCode, setLoading, showSuccess, handleError, resetLines, toStoreCode) => (data) => {
    setLoading(true);
    const isBin2Bin = fromStoreCode === toStoreCode;

    const wscall = isBin2Bin ? WS.submitBin2Bin(fromStoreCode, data) : WS.submitStore2Store(fromStoreCode, toStoreCode, data)
    wscall
        .then(resp => {
            showSuccess(`${isBin2Bin ? 'Bin2bin' : 'Store2Store'} transaction successfully completed.`)
            resetLines()
        })
        .catch(err => handleError(err))
        .finally(_ => setLoading(false))
}

class Bin2BinPage extends React.Component {

    state = {
        code: '',
        codeDesc: '',
        destBinCode: '',
        destBinDesc: '',
        type: '',
        transLines: [],
        destinationStore: this.props.storeCode,
    }

    updatePropertyAndHandleFocus(key, value) {
        this.setState({[key]: value})
        //handle focus
        //key == 'code' && this.setState({selectedData: {value}})
    }

    destinationStoreHandler(key, value) {
        this.updatePropertyAndHandleFocus(key, value);
        // Reset destination bin field so that we do not show a bin that is not
        // valid for the currently selected store
        this.setState({ destBinCode: ''});
        this.setState({ destBinDesc: ''});
    }

    render() {
        const { code, codeDesc, transLines, destBinCode, destBinDesc, submitting, destinationStore } = this.state;
        const { storeCode, handleError, showSuccess, stores, storeConfiguration } = this.props;

        const submitBin2bin = _ => submitBin2Bin(
                storeCode,
                loading => this.setState({submitting: loading}),
                showSuccess,
                handleError,
                _ => this.setState({transLines: []}),
                destinationStore
            )({storeCode, transLines: transLines.map(filterObjFromList(['assetCode', 'partCode', 'bin', 'lot', 'quantity'])), destinationBin: destBinCode})

        const showDestinationStore = storeConfiguration?.extraConfiguration?.store2store !== 'false';

        return (
            <Container fixed>
                <BlockUi blocking={submitting}>
                    <div style={{ marginBottom: 20 }}>
                        <EAMAutocomplete
                            label="Part or Asset"
                            style={fieldStyle}
                            // TODO: Missing clear icon to the right of the field: needs update of inputs-ng
                            value={code}
                            desc={codeDesc}
                            autocompleteHandler={loadOptionsForPartOrAsset(storeCode)}
                            // autoFocus // TODO: needs update of inputs-ng
                            // autoSelectSingleElement // TODO: needs update of inputs-ng
                            onChange={createOnChangeHandler(
                                'code', 'codeDesc', null, this.updatePropertyAndHandleFocus.bind(this), null
                            )}
                            barcodeScanner
                        />
                    </div>
                    <div style={{ marginBottom: 20 }}>
                        <BinLotQty
                            code={code}
                            codeDesc={codeDesc}
                            storeCode={storeCode}
                            transLines={transLines}
                            updateTransLines={transLines => this.setState.bind(this)({transLines})}
                            clearCode={() => {
                                this.updatePropertyAndHandleFocus.bind(this)('code', '');
                                this.updatePropertyAndHandleFocus.bind(this)('codeDesc', '');
                            }}
                            handleError
                            fieldStyle={fieldStyle}
                        />
                        {
                            <React.Fragment>
                                {showDestinationStore ?
                                    <>
                                        <EAMSelect
                                            label="Destination Store"
                                            options={stores}
                                            value={destinationStore}
                                            onChange={createOnChangeHandler(
                                                'destinationStore', null, null, this.destinationStoreHandler.bind(this), null
                                            )}
                                        />
                                    </>
                                    : null
                                    }

                                <EAMAutocomplete
                                    label="Destination Bin"
                                    // TODO: Missing clear icon to the right of the field: needs update of inputs-ng
                                    value={destBinCode}
                                    desc={destBinDesc}
                                    autocompleteHandler={loadBins}
                                    autocompleteHandlerParams={[destinationStore]}
                                    // creatable // TODO: needs update of inputs-ng
                                    // autoSelectSingleElement // TODO: needs update of inputs-ng
                                    onChange={createOnChangeHandler(
                                        'destBinCode', 'destBinDesc', null, this.updatePropertyAndHandleFocus.bind(this), null
                                    )}
                                    barcodeScanner
                                />

                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', marginTop: 20 }}>
                                    <KioskButton
                                        disabled={!transLines.length || !destBinCode}
                                        variant="contained"
                                        color="primary"
                                        onClick={submitBin2bin}
                                    >
                                        SUBMIT
                                    </KioskButton>
                                </div>
                            </React.Fragment>
                        }
                    </div>
                </BlockUi>
            </Container>
        );
    }
}

const aggregateEntries = list => list.reduce((acc, [key, value]) => ({...acc, [key]: value}), {})

const filterObjFromList = (fieldList) => (object) => aggregateEntries(Object.entries(object)
        .filter(([entry]) => fieldList.includes(entry)))

export default Bin2BinPage;
