import actionTypes from "../actionTypes/inboundProcessActionTypes";

export const resetInbound = () => ({
    type: actionTypes.RESET
});

export const updateParts = ({ parts }) => ({
    type: actionTypes.UPDATE_PARTS,
    payload: {
        parts
    }
});

export const updateAssets = ({ assets }) => ({
    type: actionTypes.UPDATE_ASSETS,
    payload: {
        assets
    }
});

export const updateTransactionLines = ({ transactionLines }) => ({
    type: actionTypes.UPDATE_TRANSACTION_LINES,
    payload: {
        transactionLines
    }
});

export const updateSitDoc = ({ sitDoc }) => ({
    type: actionTypes.UPDATE_SIT_DOC,
    payload: {
        sitDoc
    }
});

export const updateArticleLines = ({ articleLines }) => ({
    type: actionTypes.UPDATE_ARTICLE_LINES,
    payload: {
        articleLines
    }
});

export const updateRelations = ({ relations }) => ({
    type: actionTypes.UPDATE_RELATIONS,
    payload: {
        relations
    }
});

export const addItemToPackage = ({ packageId, itemId }) => ({
    type: actionTypes.ADD_ITEM_TO_PACKAGE,
    payload: {
        packageId,
        itemId
    }
})

export const removeItemFromPackage = ({ packageId, itemId }) => ({
    type: actionTypes.REMOVE_ITEM_FROM_PACKAGE,
    payload: {
        packageId,
        itemId
    }
})

export const removePackage = ({ id }) => ({
    type: actionTypes.REMOVE_PACKAGE,
    payload: {
        id
    }
})

export const addPackage = ({ id }) => ({
    type: actionTypes.ADD_PACKAGE,
    payload: {
        id
    }
})


export const placePartOnBin = ({ part, bin }) => ({
    type: actionTypes.PLACE_PART_BIN,
    payload: { 
        part, bin 
    }
});
