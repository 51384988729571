
import { updateApplication } from "actions/applicationActions";
import { updateEmployee, updateStore } from "actions/currentTransactionActions";
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router";
import { ROUTES, STORE_TYPES } from "Routes";

const KioskLoader = () => {
    const dispatch = useDispatch();
    const { storesConfiguration, userData, storeData, storeDataGeneral, currentStore, groupStore} = useSelector(state => state.application);
    const { params } = useRouteMatch(ROUTES.STORE_MENU) || {};
    const { storeType, storeCode } = params || {};

    const storeInfo = storeData.find(s => s.code === storeCode) || storesConfiguration?.[storeCode];
    const userCernId = userData?.eamAccount?.cernId;
    const userDescription = userData?.eamAccount?.userDesc;

    useEffect(() => {
        if (storeType === STORE_TYPES.GENERAL) {
            if (storeDataGeneral[storeCode] && groupStore?.code !== storeCode) {
                dispatch(updateApplication({currentStore: undefined, groupStore: storeDataGeneral[storeCode]}));
                dispatch(updateStore(storeCode, storeDataGeneral[storeCode].description));
            }
        } else if (storeType === STORE_TYPES.STORE && currentStore?.code !== storeCode) {
            dispatch(updateApplication({currentStore: {code: storeCode, desc: storeInfo?.description ?? storeInfo?.desc}, groupStore: undefined}));
            dispatch(updateStore(storeCode, storeInfo?.description ?? storeInfo?.desc));
        } else if(!storeCode) {
            dispatch(updateApplication({currentStore: undefined, groupStore: undefined}));
        }
    }, [storeCode, storeType]);

    useEffect(() => {
        if (userCernId) {
            dispatch(updateEmployee(userCernId, userDescription));
        }
    }, [userCernId]);

    return null;
};

export default KioskLoader;
