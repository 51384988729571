import React from 'react';

class KioskAccess extends React.Component {

    render () {
        const { storeAccess, currentStore, groupStore } = this.props

        if (!storeAccess?.includes(currentStore?.code) && !groupStore) {
            return <div style={{height: "100%", textAlign: "center", width: "100%", }}>You don't have access to this store.</div>;
        }
        return this.renderPage && this.renderPage();
    }
}

export default KioskAccess;
