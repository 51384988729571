import {connect} from 'react-redux';
import {setPage} from "../../../actions/layoutActions";
import {handleError, showWarning} from '../../../react-eis-components/actions/uiActions';
import ContextSelectionPageDetails from "./ContextSelectionPageDetails";
import {updateApplication} from "../../../actions/applicationActions";
import {updateTransaction} from "../../../actions/currentTransactionActions";
import { withStyles } from '@mui/styles';

const mapStateToProps = (state, ownProps) => {
    return {
        currentStore: state.application.currentStore,
        currentTransaction: state.currentTransaction,
        userData: state.application.userData,
    }
};

const ContextSelectionPageDetailsContainer = connect(
    mapStateToProps, {
        updateTransaction,
        setPage,
        updateApplication,
        handleError,
        showWarning
    }
)(ContextSelectionPageDetails);

export default ContextSelectionPageDetailsContainer;
