import withStyles from '@mui/styles/withStyles';
import KioskButton from 'ui/components/buttons/KioskButton';
import { useSelector, useDispatch } from "react-redux";
import { createButtonTranslations } from '../IssueReturnSelection/IssueReturnSelectionPage';
import { Cart, Home } from 'mdi-material-ui';
import { useHistory } from 'react-router';
import { ROUTES, STORE_TYPES} from 'Routes';
import { generatePath } from 'react-router-dom';
import { ButtonBase } from '@material-ui/core';
import ArrowForward from '@material-ui/icons/ArrowForward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { ArrowBack } from '@mui/icons-material';

const classes = {
    hidePanelOnMobile: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        flexGrow: '1',
        marginTop: '5px'
    },
    '@media (max-width: 400px)': {
        hidePanelOnMobile: {
            display: "none"
        }
    },
    rowContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
    menuContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        flexGrow: '1',
        marginTop: '5px',
    }
};

const GroupStoreMenu = ({ classes, history, match }) => {
    const { groupStore } = useSelector(state => state.application);

    return <div style={{height: '100%', overflowY: 'auto', marginTop: '20px'}}>
        {ROWS.map((row, i) => <RowButtons key={`storemenurow#${i}`} classes={classes} history={history} match={match} rowButtons={row} />)}
    </div>
}

const ROWS = [
    [
        {
            Icon: Cart,
            key: 'REQUEST',
            path: ROUTES.REQUEST_GRID,
            storeType: STORE_TYPES.GENERAL,
        },
    ],
    [
       {
            Icon: (props) => <>
                    <Home {...props}/>
                    <ArrowBack {...props}/>
                    {/* {this.getOutboundByStatusComponent()} */}
                </>,
            key: 'INBOUND',
            path: ROUTES.INBOUND_GRID,
            storeType: STORE_TYPES.GENERAL,
        },
        {
            Icon: (props) => <>
                    <Home {...props}/>
                    <ArrowForward {...props}/>
                </>,
            key: 'OUTBOUND',
            path: ROUTES.OUTBOUND_GRID,
            storeType: STORE_TYPES.GENERAL,
        },
    ]
];

const getButton = ({ Icon, key, path, history, match, storeType }) =>
    createButtonTranslations({})({
        Icon,
        key,
        onClick: () => history.push(generatePath(path, {...match.params, storeType})),
    })

const RowButtons = ({ classes, history, match, rowButtons }) =>
    <div className={classes.menuContainer}>
        <div className={classes.rowContainer}>
            {rowButtons.map(button => getButton({...button, match, history}))}
        </div>
    </div>

export {
    RowButtons,
    classes,
}

export default withStyles(classes)(GroupStoreMenu);
