import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import EAMSelect from 'eam-components/dist/ui/components/inputs-ng/EAMSelect';
import { Barcode, Magnify } from 'mdi-material-ui';
import React from 'react';
import BlockUi from 'react-block-ui';
import EISTable from '../../../../react-eis-components/ui/components/table';
import WSFlexBuilding from '../../../../tools/rest/WSFlexBuilding';
import KioskButton from '../../../components/buttons/KioskButton';
import { GROUP_METHODS } from './SITSelectionAssets';
import Select from 'react-select';
import { getEditableMeasurement } from './SITTools';
import { createOnChangeHandler } from 'eam-components/dist/ui/components/inputs-ng/tools/input-tools';

const DEBUG = false;

const TRANSLATIONS = {
    [GROUP_METHODS.DIRECTLY]: "Store individually"
}

const headerStyle = {
    width: '100%',
    height: '56px',
    borderRadius: '4px',
    position: 'relative',
    backgroundColor: 'rgba(255,255,255,0.3)',
    transition: '0.3s all',
  }

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const UOM = {
    CARTON: {
        code: 'carton',
        desc: 'Carton',
        partCode: 'ZZ000002'
    },
    BOC: {
        code: 'box',
        desc: 'Box',
        partCode: 'ZZ000003'
    },
    CONTAINER: {
        code: 'cont',
        desc: 'Container',
        partCode: 'ZZ000004'
    },
    PALLET: {
        code: 'pallet',
        desc: 'Pallet',
        partCode: 'ZZ000005'
    },
    DRUM: {
        code: 'drum',
        desc: 'Drum',
        partCode: 'ZZ000006'
    },
    PIECE: {
        code: 'pce',
        desc: 'Piece',
        partCode: 'ZZ000007'
    }
}

class SITPlaceItemsOnBin extends React.Component {

    iconStyle = {
        width: 20,
        height: 20
    };

    state = {
        items: [],
        groups: [],
        loading: false,
        open: false,
        finished: false,
        dialog: {
            title: '',
            message: ''
        },
        selectedPrinter: [],
    }

    handleItemsHeaders = ['Name', 'Code', 'Description', 'Hazards', 'UOM', 'Bin', 'Print label', 'Length/cm', 'Width/cm', 'Height/cm', 'Weight/kg', 'Responsible', 'Part'];
    handleItemsPropCodes = ['name', 'code', 'desc', 'hazardsList', 'uom', 'bin', 'barcodeprint', 'length', 'width', 'height', 'weight', 'responsible', 'part'];

    handlePartsHeaders = ['', 'Part code', 'Description', 'Bin', 'Lot', 'UOM', 'Print label', 'Length/cm', 'Width/cm', 'Height/cm', 'Weight/kg']
    handlePartsPropCodes = ['__', 'parCode', 'desc', 'bin', 'lot', 'uom', 'barcodeprint', 'length', 'width', 'height', 'weight']

    componentWillMount () {
        let { assetsForKit, storeCode } = this.props;
        WSFlexBuilding.getLots()
            .then(resp => this.setState({lots: resp.body.data}));
        WSFlexBuilding.getPrinters()
            .then((response) => {
                const printers = response.body.data.map((printer) => {
                    return { label: printer[0], value: printer[0] };
                });
                this.setState({ printers, selectedPrinter: printers.find(p => p.value === this.props.storeConfiguration?.printerCode) });
            });

        if (DEBUG) {
            let groupings = this.getDummyAssetsForKit();
            assetsForKit = {
                items: groupings.items,
                groups: groupings.groups
            }
        }

        if (!assetsForKit) {
            return;
        }

        // In the group GROUP_METHOD.DIRECTLY, each item is sort in a group of their own, so we reflect that on state
        let items = assetsForKit.items;
        let groups = assetsForKit.groups;

        if (assetsForKit) {
            this.setState({ loading: false, items, groups });
            return;
        }
    }

    componentDidUpdate () {
        let { assetsForKit, storeCode } = this.props;
        if (!assetsForKit) {
            this.props.history.push(process.env.REACT_APP_FRONTEND + `${storeCode}/inbound/${this.props.match.params.sitCode}`);
            return;
        }
    }

    getDummyAssetsForKit = _ => ({
        items: [
            {
            code: 'CR-084477',
            desc: 'Bac informatique pour recyclage',
            length: '120.0',
            width: '80.0',
            height: '100.0',
            weight: '500.0',
            systemStatus: 'I',
            total: -1
            },
            {
            code: 'CR-084478',
            desc: 'Bac informatique pour recyclage',
            length: '120.0',
            width: '80.0',
            height: '100.0',
            weight: '500.0',
            systemStatus: 'I',
            total: -1
            }
        ],
        groups: [
            {
            name: 'STORE',
            method: 'STORE',
            item: {
                code: 'CR-084477',
                desc: 'Bac informatique pour recyclage',
                length: '120.0',
                width: '80.0',
                height: '100.0',
                weight: '500.0',
                systemStatus: 'I',
                total: -1
                }
            },
            {
            name: 'PACKAGE 1',
            method: 'PACKAGE',
            item: {
                code: 'CR-084595',
                desc: 'Charges de test',
                part: 'ZZ000005',
                weight: '500.0',
                systemStatus: 'I',
                total: -1
            },
            items: [
                'CR-084478'
            ]
            },
            {
            name: 'PACKAGE 1',
            method: 'PACKAGE',
            item: {
                code: 'CR-084595',
                desc: 'Charges de test',
                part: 'ZZ000005',
                weight: '500.0',
                systemStatus: 'I',
                total: -1
            },
            items: [
                'CR-084478'
            ]
            },
            {
            name: 'PACKAGE 1',
            method: 'PACKAGE',
            item: {
                code: 'CR-084595',
                desc: 'Charges de test',
                part: 'ZZ000005',
                weight: '500.0',
                systemStatus: 'I',
                total: -1
            },
            items: [
                'CR-084478'
            ]
            },
            {
            name: 'PACKAGE 1',
            method: 'PACKAGE',
            item: {
                code: 'CR-084595',
                desc: 'Charges de test',
                part: 'ZZ000005',
                weight: '500.0',
                systemStatus: 'I',
                total: -1
            },
            items: [
                'CR-084478'
            ]
            },
            {
            name: 'PACKAGE 1',
            method: 'PACKAGE',
            item: {
                code: 'CR-084595',
                desc: 'Charges de test',
                part: 'ZZ000005',
                weight: '500.0',
                systemStatus: 'I',
                total: -1
            },
            items: [
                'CR-084478'
            ]
            }
        ]
      })

    handleNextButtonClick = () => {

    };

    emptyPromise = () => new Promise(resolve => resolve());

    conditionalUpdateEquipment (sitAssetsInfo) {
        const itemsToUpdate = sitAssetsInfo.assets.filter(item => item.updated);
        //let packages = sitAssetsInfo.packageAsset ? [sitAssetsInfo.packageAsset] : [];
        //toUpdate = [...toUpdate, ...packages.filter(article => article.updated)]
        return itemsToUpdate.length > 0 ?
            WSFlexBuilding.updateArticles({
                ...sitAssetsInfo,
                assets: itemsToUpdate
            }) :
            this.emptyPromise();
    }

    printBarcode = (asset) => () => {
        let barcodeInput = {
            type: 'E', // Set print for EQUIPMENT
            variables: [{
                code: asset.code, // Send info for main code to print,
                fields: {equipmentdesc: asset.desc, serialnumber: asset.serialno}
            }]
        };
        WSFlexBuilding.printBarcode(barcodeInput, this.state.selectedPrinter?.value)
            .then(response => {
                this.props.showSuccess(response.body.data);
            })
            .catch(error => {
                if (error && error.response && error.response.body) {
                    this.props.showError('ERROR '+error.response.body.data);
                }
                this.props.handleError(error);
            });
    }

    returnHome = () => {
        let {currentStore, history} = this.props;
        history.push( process.env.REACT_APP_FRONTEND + `${currentStore.code}/`);
    };

    handleBinForPart = part => {
        const storeCode = this.props.currentStore.code;
        this.props.history.push(process.env.REACT_APP_FRONTEND + `${storeCode}/inbound/${this.props.match.params.sitCode}/items/${part.parCode}/bin`)
    }

    handleBinClick = group => {
        const storeCode = this.props.currentStore.code;
        let sitAssetsInfo = {
            assets: [group.item],
            storeCode
        };
        const next = _ => {
            const { updateAssetsForKit, assetsForKit } = this.props;
            const { items, groups } = this.state;

            updateAssetsForKit({
                ...assetsForKit,
                items,
                groups
            })
            this.props.history.push(process.env.REACT_APP_FRONTEND + `${storeCode}/inbound/${this.props.match.params.sitCode}/items/${group.item.code}/bin`)

        }
        this.setState(prevState => ({
            ...prevState,
            loading: true
        }), _ =>
            this.conditionalUpdateEquipment(sitAssetsInfo)
                .then(
                    response =>
                    {
                        return response && response.body && response.body.data && response.body.data.length > 0 ?
                            this.setState( prevState =>
                                ({
                                    ...prevState,
                                    groups: prevState.groups.map( group => response && response.body && response.body.data &&
                                            response.body.data.length > 0 && response.body.data.some(item => item.code === group.item.code) ?
                                        {
                                            ...group,
                                            item: response.body.data.find(item => item.code === group.item.code)
                                        } :
                                        group
                                    ),
                                    items: prevState.items.map( existingItem => response && response.body && response.body.data &&
                                            response.body.data.length > 0 && response.body.data.some(item => item.code === existingItem.code) ?
                                        response.body.data.find(item => item.code === existingItem.code) :
                                        existingItem
                                )
                                }), next
                            ) : next()
                    }
                )
        )
    }

    validateFields = () =>
        this.mapGroups('groups', this.state.groups, null).reduce(
            (acc, row) =>
                Object.values(row)
                    .filter(elem => elem && elem.props && elem.props.validate)
                    .reduce((acc2, field) =>
                        field.props.validate(field.props.value) && acc2
                , true) && acc
            , true);

    mapParts = (parts, updateParts) => {
        return parts.map(part => ({
            parCode: part.parCode,
            desc: part.desc,
            uom: part.uom,
            bin: (
                <Button
                        variant="contained"
                        color="primary"
                        onClick={_ => this.handleBinForPart(part)}
                        style={{...(!part.bin && {backgroundColor: 'rgba(219, 54, 46, 0.7)'})}}
                >
                    {part.bin}
                    <Magnify
                        style={{...this.iconStyle, marginLeft: 4}}/>
                </Button>
            ),
            barcodeprint:
                (<Button variant="contained" color="primary" onClick={this.printBarcode({code: part.parCode, desc: part.desc})}>
                    Print <Barcode style={{...this.iconStyle, marginLeft: 4}}/>
                </Button>),
            lot:
            <EAMSelect
                value={part.lot}
                onChange={(option) =>
                    updateParts({
                        parts: {
                            ...parts.map(p => ({[p.parCode]: p})).reduce((acc, p) => ({...acc, ...p}), {}),
                            [part.parCode]: {
                                ...part,
                                lot: option.code
                            }
                        }
                    })
                }
                options={this.state.lots}
                style={{minWidth: '200px'}}
                //disabled={!part.byLot} // TODO: should this remain commented out?
                renderValue={value => value.desc || value.code}
            />

        }))
    }

    //TODO button function
    mapGroups = (arrayField, groups, callback) => {
            return groups
                .filter(g => !g.item.bin)
                .concat(groups.filter(g => g.item.bin))
                .map( group => {
                return {
                    name: <div style={{fontSize: '16px', fontWeight: 'bold', color: '#2196F3'}}>{group.name}</div>,
                    code: group.item.code,
                    responsible: getEditableMeasurement(arrayField, group.item, 'responsible', callback),
                    desc: getEditableMeasurement(arrayField, group.item, 'desc', callback, false),
                    weight: getEditableMeasurement(arrayField, group.item, 'weight', callback),
                    length: getEditableMeasurement(arrayField, group.item, 'length', callback),
                    width: getEditableMeasurement(arrayField, group.item, 'width', callback),
                    height: getEditableMeasurement(arrayField, group.item, 'height', callback),
                    bin: //group.item.bin ? group.item.bin :
                        (<>{group.item.bin}
                        <Button variant="contained" color="primary" onClick={_ => this.handleBinClick(group)} style={{...(!group.item.bin && {backgroundColor: 'rgba(219, 54, 46, 0.7)'})}}>
                            <Magnify style={{...this.iconStyle, marginLeft: 4}}></Magnify>
                        </Button></>),
                    part: group.item.part,
                    barcodeprint:
                        (<Button variant="contained" color="primary" onClick={this.printBarcode.bind(this)(group.item)}>
                            <Barcode style={{...this.iconStyle, marginLeft: 4}}/>
                        </Button>),
                    uom: group.method !== GROUP_METHODS.DIRECTLY ?
                        <EAMSelect
                            value={group.item.uom}
                            renderValue={(value) => value.desc || value.code}
                            onChange={createOnChangeHandler(
                                'uom', null, null, this.updateUOM, null, [arrayField,  group.item.code, callback]
                            )}
                            options={Object.values(UOM)}
                            // disabled={INFOR_CREATE_STRATEGIES.length < 2} // TODO: should this remain commented out?
                        />
                        : ''
                    ,
                    needingCompletion: !(group.item.weight && group.item.length && group.item.width && group.item.height && group.item.responsible),
                    hazardsList: group.item.hazardsList?.split(',').filter(s => s)
                        .map(haz => this.props.applicationData.hazardsList.find(h => h.code === haz)?.desc ?? haz)
                        .join(', ')
                    }
                }
            )};

    updateUOM = (key, value, arrayField, code, callback) => {
        if (!value) return;
        let newPart = Object.values(UOM).find(uom => uom.code === value).partCode;
        callback('part', newPart, arrayField, code)
        callback(key, value, arrayField, code)
    }

    updateGroup = (key, value, field, groupCode) => {
        this.setState(prevState => ({
            ...prevState,
            groups: prevState.groups.map( g =>
                g.item && (g.item.code === groupCode) ?
                    {
                        ...g,
                        item: {
                            ...g.item,
                            [key]: value,
                            updated: true
                        }
                    } :
                    g
            )
        }))
    }

    getAssetsToStoreDirectly = _ => {
        let storeDirectlyList =
            this.state.groups
                .find(g => g.method === GROUP_METHODS.DIRECTLY);
        storeDirectlyList.items.map(item => {
            let b =  this.state.items;
            let c = this.state.items.find(it => {
                return it.code === item
            })
            return c;
        }
        )

        return storeDirectlyList ?
        {
            ...storeDirectlyList,
            items: storeDirectlyList.items.map(item => this.state.items.find(it => it.code === item))
        } :
        null
    }

    //TODO move to utils
    waitOnAllPromises = promiseArray =>
        promiseArray.reduce((promiseChain, currentTask) =>
                promiseChain
                    .then(chainResults => currentTask()
                        .then(currentResult => [...chainResults, currentResult])
                    )
            ,
            Promise.resolve([]))

    openDialog = code => {
        this.setState({
            open: true,
            dialog: {
                title: 'All transactions were correctly executed',
                message: 'All transactions were correctly executed'
            }
        });
    };

    finish = (partial = false) => {
        let packageInfo = {
            storeCode: this.props.currentStore.code,
            sitCode: this.props.match.params.sitCode,
            partial
        };

        if (!this.validateFields()) {
            this.props.showError('Invalid measurements', '', null);
            return;
        }

        let sitAssetsInfo = {
            assets: this.state.groups.map(g => g.item),
            storeCode: this.props.currentStore.code
        };

        const next = _ => {
            // let promiseArray = this.state.groups
            //         .map(g => ({...packageInfo, binCode: g.item.bin, packageAsset: g.item, assets: g.items.map(item => this.state.items.find(it => it.code === item))}))
                    //.map(g => async () => WSFlexBuilding.placePackage(g));


            let inboundBean = {
                ...packageInfo,
                transLines: [
                    ...this.state.groups.map(g => ({
                        assetCode: g.item.code,
                        partCode: g.item.part,
                        bin: g.item.bin,
                        lot: g.item.lot,
                        quantity: g.item.quantity || 1}))
                    ,
                    ...Object.values(this.props.parts).map(part => ({
                        partCode: part.parCode,
                        bin: part.bin,
                        lot: part.lot,
                        quantity: this.props.assetsForKit.articles
                            .find(e => e.identifier === part.parCode)
                            .quantity
                    }))
                ]
            }
            //this.waitOnAllPromises(promiseArray)
            WSFlexBuilding.returnToStore(inboundBean)
                .then(responseArray => {
                    this.setState({loading: false, finished: true});
                    this.props.showSuccess('Every operation was successful');
                    //this.props.history.push(process.env.REACT_APP_FRONTEND + `${this.props.currentStore.code}/inbound/`)
                })
                .catch(e => {
                    this.props.handleError(e);
                    this.setState({loading: false})
                })
        }

        this.setState({loading: true}, _ => {
            this.conditionalUpdateEquipment(sitAssetsInfo)
            .then(
                response =>
                {
                    return response ?
                        this.setState( prevState =>
                            ({
                                ...prevState,
                                groups: prevState.groups.map( group => response && response.body && response.body.data &&
                                    response.body.data.length > 0 && response.body.data.some(item => item.code === group.item.code) ?
                                    {
                                        ...group,
                                        item: response.body.data.find(item => item.code === group.item.code)
                                    } :
                                    group
                                ),
                                items: prevState.items.map( existingItem => response && response.body && response.body.data &&
                                        response.body.data.length > 0 && response.body.data.some(item => item.code === existingItem.code) ?
                                    response.body.data.find(item => item.code === existingItem.code) :
                                    existingItem
                            )
                            }), next
                        ) : next()
            })
        })
        //TODO handle package already in store
    }

    render () {
        //Validate that there is a current store
        if (!this.props.currentStore || !this.state.items) return null;
        const {loading, items, groups, finished} = this.state;
        const { parts, updateParts } = this.props;
        const groupList = groups.filter(g => g.item)
        const isFinished = groups.every(g => g.item.bin) && Object.values(parts).every(p => p.bin);

        return (
            <BlockUi tag="div" blocking={loading} style={{margin: 0, height: '100%', width: '100%', overflow: 'auto'}}>
                <div style={{display: 'flex', justifyContent: 'center', paddingTop: 10}}>
                    <KioskButton
                            disabled={!isFinished || finished}
                            onClick={() => this.finish(true)}
                            style={{
                                padding: 2,
                                marginLeft: 'auto',
                                marginRight: 10,
                                ...((!isFinished || finished) ? {} : {backgroundColor: '#CECB10'})
                            }}
                    >
                        Partial
                    </KioskButton>
                    <KioskButton
                            disabled={!isFinished || finished}
                            onClick={() => this.finish(false)}
                            style={{padding: 2, marginRight: 10}}
                    >
                        Finish
                    </KioskButton>
                    <KioskButton disabled={!finished} onClick={this.returnHome} style={{padding: 2, marginRight: 'auto'}}>
                        Return
                    </KioskButton>
                    <Select
                        options={this.state.printers}
                        onChange={(option) => this.setState({ selectedPrinter: option })}
                        styles={{ marginLeft: 'auto' }}
                        style={{ width: 200 }}
                        value={this.state.selectedPrinter}
                    />
                </div>
                {items && groupList && groupList.length > 0 &&
                    <EISTable
                        data = {
                                this.mapGroups(
                                    'groups',
                                    groupList,
                                    this.updateGroup
                                )
                            }
                        headers={[...this.handleItemsHeaders]}
                        propCodes={[...this.handleItemsPropCodes]}
                        stylesMap={{
                            needingCompletion: {
                                backgroundColor: "rgba(255, 255, 204, 0.85)"
                            }
                        }}
                    />
                }

                {(parts && Object.values(parts) && Object.values(parts).length) ?
                    <EISTable
                        data = {
                            this.mapParts(Object.values(parts), updateParts)
                        }
                        headers={[...this.handlePartsHeaders]}
                        propCodes={[...this.handlePartsPropCodes]}
                    />
                    : null
                }

                <Dialog
                    open={this.state.open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.returnHome}
                    disableBackdropClick={true}
                    disableEscapeKeyDown={true}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">
                        {this.state.dialog.title}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            {this.state.dialog.message}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </BlockUi>
        );
    }
}

export default SITPlaceItemsOnBin;