import {connect} from 'react-redux';
import {setPage} from "../../../actions/layoutActions";
import {handleError} from '../../../react-eis-components/actions/uiActions';
import OutstandingIssuesPage from "./OutstandingIssuesPage";
import {logout} from "../../../actions/applicationActions";

const mapStateToProps = (state) => {
    const currentTransaction= state.currentTransaction;
    const language = state.layout.lang;
    const store = state.application.currentStore;
    const storeConfiguration = store ? state.application.storesConfiguration[store.code] : {};
    return {
        kioskMode: state.application.kioskMode,
        applicationData: state.application.applicationData,
        currentTransaction: currentTransaction,
        currentStore: store,
        storeConfiguration: storeConfiguration,
        translations: store ? state.application.translations[language]['KIOSK_' + store.code] : {},
        storeAccess: state.application && state.application.storeAccess,
        userData: state.application.userData
    }
};

const OutstandingIssuesPageContainer = connect(
    mapStateToProps, {
        setPage,
        logout,
        handleError
    }
)(OutstandingIssuesPage);

export default OutstandingIssuesPageContainer;