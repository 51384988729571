import React from 'react';
import AMMGrid from '../../components/AMMGrid';
import StoreInfo from "../../components/elements/StoreInfo";
import KioskAccess from '../KioskAccess';
import Button from '@mui/material/Button';


class OutstandingIssuesPage extends KioskAccess {

    state = {
        gridReference: undefined
    };

    _setRefresh = (props) => {
        let refreshTime = (props && props.storeConfiguration) ? props.storeConfiguration.outstandingRefreshTime : undefined;
        if (refreshTime) {
            refreshTime *= 1000;
            this._clearTimer();
            this.timer = setInterval(this.grid.runSearch, refreshTime);
        }
    };

    _clearTimer() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    };

    componentWillUnmount() {
        this._clearTimer();
    }

    componentDidMount() {
        //Set the refrsh time
        this._setRefresh(this.props);
    }

    componentDidUpdate() {
        //Set the refrsh time
        this._setRefresh(this.props);
    }

    getStoreFilter = () => ({
        operator: "EQUALS",
        fieldValue: this.props.currentStore?.code,
        joiner: "AND",
        fieldName: "trl_store"
    })

    gridRequestAdapter = gridRequest => {
        return {
            ...gridRequest,
            gridFilter: [...gridRequest.gridFilter, this.getStoreFilter()],
        }
    }

    handleClick = row => () => {
        this.props.history.push(`${process.env.REACT_APP_FRONTEND}store/${this.props.currentStore?.code}/return/employee?employee=${row.values.tra_pers}&partCode=${encodeURIComponent(row.values.trl_object || row.values.trl_part)}&bin=${encodeURIComponent(row.values.trl_bin)}&lot=${encodeURIComponent(row.values.trl_lot)}`)
    }

    cellRenderer = ({ value, column, row }) => {
        if (column.id === 'returnEquipment') {
            return <Button variant="contained" color="primary" style={{width: '100%'}} onClick={this.handleClick(row)}>
                Return
            </Button>
        }
        return null;
    };

    renderPage () {
        const { currentStore } = this.props;

        //Validate that there is a current store
        if (!currentStore) {
            return <div/>
        }

        const {applicationData} = this.props;
        return (
            <React.Fragment>
                <StoreInfo currentStore={currentStore} history={this.props.history} kioskMode={this.props.kioskMode} logout={this.props.logout}/>
                    <div style={{height: 'calc(100% - 100px)'}}>
                        <AMMGrid
                            onRef={ref => this.grid = ref}
                            gridName={applicationData.outstandingIssuesGridName}
                            showDataspySelection={true}
                            extraColumns={[{
                                width: '130px',
                                label: 'Return Equipment',
                                t: 'returnEquipment'
                            }]}
                            cellRenderer={this.cellRenderer}
                            gridRequestAdapter={this.gridRequestAdapter}
                        />
                    </div>
            </React.Fragment>
        );
    }
}

export default OutstandingIssuesPage;