import {connect} from 'react-redux';
import {setPage} from "../../../../actions/layoutActions";
import {handleError, openConfirmDialog, showError, showWarning} from '../../../../react-eis-components/actions/uiActions';
import TransactionsTable from "./TransactionsTable";
import {updateApplication, logout} from "../../../../actions/applicationActions";
import {removeLineFromCurrentTransaction, resetTransaction, updateLineInTransaction} from "../../../../actions/currentTransactionActions";

const mapStateToProps = (state, ownProps) => {
    const language = state.layout.lang;
    const store = state.application.currentStore;
    return {
        kioskMode: state.application.kioskMode,
        currentStore: state.application.currentStore,
        currentTransaction: state.currentTransaction,
        translations: store ? state.application.translations[language]['KIOSK_'+store.code] : {}
    }
};

const TransactionsTableContainer = connect(
    mapStateToProps, {
        setPage,
        updateApplication,
        handleError,
        openConfirmDialog,
        logout,
        showError,
        showWarning,
        removeLineFromTransaction: removeLineFromCurrentTransaction,
        resetTransaction,
        updateLineInTransaction
    }
)(TransactionsTable);

export default TransactionsTableContainer;
