import { RESET_OUTBOUND, SET_OUTBOUND_PICK_TICKET, SET_OUTBOUND_ITEMS } from "../actions/outboundProcessActions";

const itemsFromGrid2Object = itemsFromGrid => Object
        .entries(itemsFromGrid)
        .reduce((obj, itemFromGrid) => Object.defineProperty(
            obj,
            itemFromGrid[0],
            {
                enumerable: true, 
                value: itemFromGrid[1]
                    .cell
                    .reduce((acc, cell) => Object.defineProperty(acc, cell.t, {enumerable: true, value: cell.value}), {})
            }
        ), {})

const rowFromGrid2Object = row =>
    row
        .cell
        .reduce((acc, cell) => Object.defineProperty(acc, cell.t, {enumerable: true, value: cell.value}), {})

export default function outboundProcess (state = {}, action) {
    switch (action.type) {
        case RESET_OUTBOUND: return {}
        case SET_OUTBOUND_PICK_TICKET: 
            return {
                ...state,
                pickTicket: action.pickTicket
            }
        case SET_OUTBOUND_ITEMS: 
            return {
                ...state,
                items: action.items
            }
        default: return state
    }
}