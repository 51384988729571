const theme = {
    PROD: "BLUE",
    TEST: "GREEN",
    DEV: "RED",
    DEFAULT: "BLUE",
};

export default {
    theme,
};
