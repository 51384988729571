export const RESET_OUTBOUND = 'RESET_OUTBOUND';
export const SET_OUTBOUND_PICK_TICKET = 'SET_OUTBOUND_PICK_TICKET';
export const SET_OUTBOUND_ITEMS = 'SET_OUTBOUND_ITEMS';

export function resetOutboundInfo() {
    return {
        type: RESET_OUTBOUND
    }
}

export function setOutboundPickTicket(pickTicket) {
    return {
        type: SET_OUTBOUND_PICK_TICKET,
        pickTicket
    }
}

export function setOutboundItems(items) {
    return {
        type: SET_OUTBOUND_ITEMS,
        items
    }
}