import React from 'react';

class PickTicketPage extends React.Component {

    state = {};

    render() {
        return (
            <div>PickTicketPage</div>
        );
    }
}

export default PickTicketPage;