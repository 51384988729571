import { UPDATE_ASSETS_FOR_KIT, UPDATE_ITEMS_FOR_REQUEST } from "../actions/flexBuildingActions";


export default function flexBuilding(state = {}, action) {

    switch (action.type) {
        case UPDATE_ASSETS_FOR_KIT: {
            return {
                ...state,
                assetsForKit: action.assetsForKit
            }
        }
        default: {
            return state;
        }

    }
}