import WS from "./WS";

export const CELL_TYPES = {
    PK: 'pk',
    INVENTORY_CODE: 'stl_trans',
    LINE_NUMBER: 'stl_line',
    ASSET_CODE: 'ial_asset',
    DESCRIPTION: 'stl_desc',
    PART_CODE: 'stl_part',
    BIN: 'stl_bin',
    LOT: 'stl_lot',
    STORE: 'stl_store',
    EXPQTY: 'stl_expqty',
    PHYQTY: 'stl_phyqty',
    COMMENT: 'ial_comment',
    ACTION: 'ial_action',
    DEST: 'ial_dest',
    DESTBIN: 'ial_destbin',
    DESTLOC: 'ial_destloc',

    INPUT_QTY: 'inputQty',
    BLOCKING: 'blocking',
    ACTION_NEEDED: 'actionNeeded',
    DESTINATION: 'destination'
}

export const INVLINES_DATA_CONVERTER = {
    [CELL_TYPES.PK]: "pk",
    [CELL_TYPES.INVENTORY_CODE]: "inventoryCode",
    [CELL_TYPES.LINE_NUMBER]: "lineNumber",
    [CELL_TYPES.ASSET_CODE]: "assetCode",
    [CELL_TYPES.PART_CODE]: "partCode",
    [CELL_TYPES.BIN]: "binCode",
    [CELL_TYPES.LOT]: "lotCode",
    [CELL_TYPES.STORE]: "storeCode",
    [CELL_TYPES.EXPQTY]: "expectedQuantity",
    [CELL_TYPES.PHYQTY]: "physicalQuantity",
    [CELL_TYPES.COMMENT]: "comment",
    [CELL_TYPES.ACTION]: "action",
    [CELL_TYPES.DEST]: "destination",
    [CELL_TYPES.DESTBIN]: "destinationBin",
    [CELL_TYPES.DESTLOC]: "destinationLocation",
}

const WSInventory = {
    getInventoryList: (store, open = true) => {
        const request = {
            gridID:"396",
            gridName: 'SSPINV',
            //dataspyID: "1999",
            //rowCount: 20,
            cursorPosition: 1,
            useNative: false,
            includeMetadata: true,
            gridFilter: [
                {
                    fieldName: 'storecode',
                    fieldValue: store,
                    joiner: 'AND',
                    operator: 'EQUALS'
                },
                {
                    fieldName: 'udfchar04',
                    fieldValue: null,
                    joiner: 'AND',
                    operator: 'NOT EMPTY'
                },
                ...(
                    open ? [{
                        fieldName: 'statusrcode',
                        fieldValue: 'U',
                        joiner: 'AND',
                        operator: 'EQUALS',
                    }]
                    : []
                )
            ],
            gridSort: [
                {
                    sortBy: 'datecreated',
                    sortType: 'ASC'
                }
            ]

        }

        return WS.requestGridData(request);
    },

    createInventory: (data, config = {}) => WS._post('/inventories', data, config),

    readInventory: (inventoryCode, config = {}) => WS._get(`/inventories/${inventoryCode}`, config),

    updateInventory: (inventory, config = {}) => WS._put(`/inventories/${inventory.code}`, inventory, config),

    getInventoryGridRequest: (inventoryCode) => {
        const request = {
            //gridID:"104534",
            gridName: 'KUIALL',
            //dataspyID: "1999",
            rowCount: 10000,
            cursorPosition: 1,
            useNative: false,
            includeMetadata: true,
            gridFilter: [
                {
                    fieldName: 'stl_trans',
                    fieldValue: inventoryCode,
                    joiner: 'AND',
                    operator: 'EQUALS'
                },
            ],
            gridSort: [
                {
                    sortBy: 'stl_bin',
                    sortType: 'ASC'
                },
                {
                    sortBy: 'ial_asset',
                    sortType: 'ASC'
                },
                {
                    sortBy: 'stl_part',
                    sortType: 'ASC'
                },
            ]

        }
        return request;
    },

    readInventoryAllLines: (inventoryCode, config = {}) => {
        const request = WSInventory.getInventoryGridRequest(inventoryCode);
        return WS.requestGridData(request);
    },

    exportInventoryAllLines: (inventoryCode) => WS._post(`/grids/export/`, WSInventory.getInventoryGridRequest(inventoryCode)),

    addInventoryAssetLine: (inventoryCode, line) => WS._post(`/inventories/${inventoryCode}/assetlines/`, line),

    updateInventoryPartLine: (inventoryCode, lineNumber, line, config = {}) => WS._put(`/inventories/${inventoryCode}/partlines/${lineNumber}`, line, config),

    updateInventoryAssetLine: (inventoryCode, line, config = {}) => WS._put(`/inventories/${inventoryCode}/assetlines/`, line, config),

    getInventoryStatusTransitions: (status) => WS._get(`/inventories/status/${status}/transitions`),

    getCode: (code) => WS._get(`/inventories/equipment/?code=${encodeURIComponent(code)}`),
}

export default WSInventory;