import { connect } from 'react-redux';
import { logout } from "../../../../actions/applicationActions";
import { resetOutboundInfo, setOutboundItems, setOutboundPickTicket } from "../../../../actions/outboundProcessActions";
import { handleError, openConfirmDialog, showError, showSuccess } from '../../../../react-eis-components/actions/uiActions';
import { getRoutes } from "../../../../tools/routes";
import OutboundHandlePickTicket from "./OutboundHandlePickTicket";

const mapStateToProps = (state, ownProps) => {
    const language = state.layout.lang;
    const store = state.application.currentStore;
    const storeConfiguration = store ? state.application.storesConfiguration[store.code] : {};
    return {
        kioskMode: state.application.kioskMode,
        applicationData: state.application.applicationData,
        storeConfiguration,
        currentStore: store,
        translations: store ? state.application.translations[language]['KIOSK_' + store.code] : {},
        storeCode: ownProps.storeCode,
        outboundProcess: state.outboundProcess,
        routes: getRoutes(process.env.REACT_APP_FRONTEND, store ? store.code : ''),
        storeAccess: state.application.storeAccess,
        canAccess: state.application.storeData.find(s => s.code === store?.code),
    }
};

const OutboundItemListContainer = connect(
    mapStateToProps, {
        logout,
        handleError,
        showSuccess,
        showError,
        openConfirmDialog,
        resetOutboundInfo,
        setOutboundItems,
        setOutboundPickTicket
    }
)(OutboundHandlePickTicket);

export default OutboundItemListContainer;