import React from 'react';
import BlockUi from 'react-block-ui';
import EISTable from '../../../react-eis-components/ui/components/table';
import { Link } from 'react-router-dom';
import WSInventory from '../../../tools/rest/WSInventory';
import KioskButton from '../../components/buttons/KioskButton';
import Containerize from './Containerize';
import { Typography , Container} from '@mui/material';
import KioskAccess from '../KioskAccess';

const CELL_TYPES = {
    CODE: 'physicalinventorycode',
    DESC: 'physicalinventorydescription',
    STATUS_DISP: 'statuscode_display',
    APPROVEDBY: 'createdby',
    ASSIGNEDTO: 'assignedto',
    DATECREATED: 'datecreated',

    CODE_LINK: 'inventoryLink'
}

const dataRenderer = storeCode => (data) => data.map(el => {
        const link = `${process.env.REACT_APP_FRONTEND}${storeCode}/inventory/${el[CELL_TYPES.CODE]}`;
        return {
            ...el,
            [CELL_TYPES.CODE_LINK]: <Link to={link} >{el[CELL_TYPES.CODE]}</Link>
        }
    })

class InventoryListPage extends KioskAccess {

    state = {
        inventoryList: [],
        loading: true
    }

    componentDidMount() {
        const { storeCode, handleError } = this.props;

        WSInventory.getInventoryList(storeCode)
            .then(resp => this.setState({loading: false, inventoryList: resp.body.data}))
            .catch(err => handleError(err))
            ;
    }

    renderPage() {
        const { storeCode } = this.props;
        const { inventoryList, loading } = this.state;

        const tableData = [
            {
                code: CELL_TYPES.CODE_LINK,
                header: 'Code'
            },
            {
                code: CELL_TYPES.DESC,
                header: 'Description'
            },
            {
                code: CELL_TYPES.STATUS_DISP,
                header: 'Status'
            },
            {
                code: CELL_TYPES.APPROVEDBY,
                header: 'Approved By'
            },
            {
                code: CELL_TYPES.ASSIGNEDTO,
                header: 'Assigned To'
            },
            {
                code: CELL_TYPES.DATECREATED,
                header: 'Date Created'
            },
        ]

        return (
            <Container maxWidth="lg">
                <div style={{height: '100%'}}>
                    <div style={{ display: 'flex', justifyContent: 'center', padding: '15px', }}>
                        <KioskButton
                            color={'primary'}
                            size={'small'}
                            component={(props) => <Link to={`${process.env.REACT_APP_FRONTEND}${storeCode}/inventory/create`} {...props}/>}>
                            + Create
                        </KioskButton>
                    </div>
                    <BlockUi blocking={loading} message="Loading inventory list..." renderChildren={false} style={{ minHeight: '100px', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                        <EISTable
                            data={dataRenderer(storeCode)(inventoryList)}
                            headers={tableData.map(el => el.header)}
                            propCodes={tableData.map(el => el.code)}
                            style={{borderStyle: 'solid'}} />
                    </BlockUi>
                </div>
            </Container>
        )
    }
}

export default Containerize(InventoryListPage);
