import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import './Snackbar.css';

/**
 * Display a success or error message
 */
export default class EISSnackbar extends React.Component {

    render() {
        return (
            <Snackbar
                className={"snackbar " + this.props.type}
                anchorOrigin={{
                    vertical: this.props.vertical,
                    horizontal: this.props.horizontal,
                }}
                open={this.props.open}
                autoHideDuration={this.props.autoHideDuration}
                onClose={this.props.onClose}
                message={
                    <div>
                        <div><b>{this.props.title}</b></div>
                        <div>{this.props.message}</div>
                    </div>
                }
            />
        );
    }
}

EISSnackbar.defaultProps = {
    vertical: 'top',
    horizontal: 'center'
};