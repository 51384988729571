import React from 'react';
import KioskButton from '../../components/buttons/KioskButton';
import { withStyles } from '@mui/styles';
import { alpha, Typography } from '@mui/material';

const styles = theme => ({
    outerFlex: {
        display:'flex',
        flexDirection:'row',
        justifyContent: 'center',
        flexGrow:'1',
        height: '100%',
        boxSizing: 'border-box',
        overflow: 'hidden'
    },
    innerFlex: {
        width:'100%',
        padding: '10px',
        flexDirection:'column',
        boxSizing: 'border-box',
        justifyContent: 'end',
        overflowY: 'auto',
        alignContent: 'start'
    },
    groupFlex: {
        padding: '10px',
        display:'flex',
        flexDirection:'row',
        flexWrap: 'wrap',
        boxSizing: 'border-box',
        justifyContent: 'center',
        overflowY: 'visible',
        alignContent: 'start',
        margin: '5px',
        backgroundColor: alpha(theme.palette.primary.main, 0.10),
        borderRadius: '5px',
    },
    headerText: {
        padding: '4px',
        border: '1px solid',
        color: theme.palette.secondary.main,
        borderRadius: '4px',
        fontWeight: '500',
    }
});

const Group = ({ classes, headerText, onClick, data, color = 'primary' }) => {
    return data?.length ?
        <div className={classes.groupFlex}>
            <span style={{width: '100%'}}>
                <Typography
                    variant="h6" component="span"
                    className={classes.headerText}
                >
                    {headerText}
                </Typography>
            </span>
            {
                data.map((store, index) => (
                    <div key={index} style={{margin: '10px'}}>
                        <KioskButton color={color} onClick={onClick(store.code)} style={{width:'260px', minHeight:'120px'}}>
                            <div style={{display:'flex', flexDirection:'column', flexWrap: 'wrap', justifyContent: 'center'}}>
                                <div style={{fontSize:'22px', whiteSpace: 'nowrap'}}>{store.code}</div>
                                <div style={{fontSize:'12px'}}>{store.desc}</div>
                            </div>
                        </KioskButton>
                    </div>
                ))
            }
        </div>
        : null
}

class StoreIndexPage extends React.Component {

    componentDidMount() {
        //reset the store information in the Redux Store
        this.props.updateStore(undefined, undefined);
        this.props.updateApplication({currentStore: undefined, groupStore: undefined});
    }

    onClick = store => () => {
      this.props.history.push(process.env.REACT_APP_FRONTEND + `${store}`)
    };

    render() {
        const { stores, classes, publicStores, generalStores, history } = this.props;
        const missingPublicStores = publicStores
            .filter(pub => !Object.values(generalStores).some(gen => gen.storeList.find(st => st.store === pub.code)));

        return (
            <div className={classes.outerFlex}>
                <div className={classes.innerFlex}>
                    <Group
                        classes={classes}
                        headerText={'My stores'}
                        onClick={this.onClick}
                        data={stores}
                    />
                    <Group
                        classes={classes}
                        headerText={'Grouped public storage'}
                        onClick={(groupStore) => () => history.push(process.env.REACT_APP_FRONTEND + `general/${groupStore}`)}
                        data={Object.values(generalStores)}
                        color='secondary'
                    />
                    <Group
                        classes={classes}
                        headerText={'Public storage'}
                        onClick={this.onClick}
                        data={missingPublicStores}
                    />
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(StoreIndexPage);