import { logout as logoutAuth } from 'AuthWrapper';
import WS from "../tools/rest/WS";

export const UPDATE_APPLICATION = 'UPDATE_APPLICATION';
export const SET_LOGIN_TOKEN = "SET_LOGIN_TOKEN";
export const UPDATE_TRANSACTION_TYPE = 'UPDATE_TRANSACTION_TYPE';
export const UPDATE_TRANSACTION_ENTITY = 'UPDATE_TRANSACTION_ENTITY';

export function updateApplication(value) {
    return {
        type: UPDATE_APPLICATION,
        value: value
    }
}

export function getInitData() {
    return (dispatch) => {
                Promise.all([
            WS.getUserData(),
            WS.getApplicationData(),
            WS.getStoreData(),
            WS.isKioskMode(),
            WS.getStoresConfiguration(),
            WS.getTranslations()
        ]).then(values => {
            const stores = [...values[2].body.data]
            const storesConfiguration = values[4].body.data ? values[4].body.data.reduce((obj, storecfg) => {
                obj[storecfg.store] = {
                    ...storecfg,
                    extraConfiguration: JSON.parse(storecfg.configuration)
                }
                return obj
            }, {}) : {};

            const storeAccess = [...new Set(Object.values(storesConfiguration)
                .filter(s => s.extraConfiguration?.public)
                .map(s => s.store)).addAll(...stores.map(store => store.code))]
                ;

            const missingPublicStores = Object.values(storesConfiguration).filter(st => st.configuration)
                .filter(ps => stores.every(st => st.code !== ps.store))
                .filter(s => s.extraConfiguration?.public)
                .map(({store, description}) => ({code: store, desc: description}));

            const generalStores = Object.values(storesConfiguration)
                .filter(s => s.storeGroup)
                .filter(s => storeAccess.includes(s.store))
                .reduce((acc, el) => ({...acc, [el.storeGroup]: {
                    code: el.storeGroup,
                    desc: [...(acc?.[el.storeGroup]?.storeList || []), el].map(s => s.store).join(', '),
                    storeList: [...(acc?.[el.storeGroup]?.storeList || []), el]
                }}), {});

            dispatch(updateApplication({
                userData: values[0].body.data,
                applicationData: values[1].body.data,
                storeData: stores,
                storeDataPublic: missingPublicStores,
                storeDataGeneral: generalStores,
                kioskMode: values[3].body.data,
                storesConfiguration,
                translations: {
                    EN: values[5].body.data
                },
                storeAccess,
            }))
        }).catch(response => {
            dispatch(updateApplication({userData: {invalidAccount: true}}))
        })
    }
}

export function logout() {
    return (dispatch, getState) => {
        const isKioskMode = getState().application.kioskMode;

        if (isKioskMode) {
            dispatch({
                type: SET_LOGIN_TOKEN,
                value: null
            })
        } else {
            logoutAuth();
        }
    }
}

export function login(token) {
    return {
        type: SET_LOGIN_TOKEN,
        value: token
    }
}