import Button from '@mui/material/Button';
import { generatePath, Link } from 'react-router-dom';
import { ROUTES } from 'Routes';
import WSFlexBuilding from '../../../../tools/rest/WSFlexBuilding';
import AMMGrid from '../../../components/AMMGrid';
import KioskAccess from '../../KioskAccess';

export const COLORS = {
    red: {
        color: '#bf360C',
        backgroundColor: 'rgba(251,233,231,0.7)'
    },
    green: {
        color: '#00701a',
        backgroundColor: 'rgba(118,210,117,0.1)'
    },
    orange: {
        color: "#FF7F2B",
        backgroundColor: "rgba(255,183,42,0.13)"
    },
    blue: {
        color: '#1976d2',
        backgroundColor: 'rgba(227,242,253,0.5)'
    },
    yellow: {
        color: '#330a00',
        backgroundColor: 'rgba(255, 255, 204, 0.85)'
    }
}

const linkStyle = {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
}

const GRID_KUPICK_FIELDALIAS = {
    STORE: null,
    LOCATION: 'deliverybld',
}

class SITSelectionPage extends KioskAccess {

    onSelect = (row) => () => {
        const { stores, history } = this.props;
        const storeCode = stores.find(s => s.locationCode === row[GRID_KUPICK_FIELDALIAS.LOCATION])?.store;
        storeCode && history.push(generatePath(ROUTES.INBOUND_SIT, { storeCode, sitCode: row.doc_id}));
    };

    rowStyler = ({ values }) => {
        switch (values.edhdocstatus) {
            case 'P':
                return COLORS.yellow;
            case 'C':
                return COLORS.green;
            default:
                return {minHeight: '52px' };
        }
    };

    cellRenderer = ({ column, value, row }) => {
        const docid = row.values.doc_id;

        if (column.id === 'selectsit') {
            return <Button variant="contained" color="primary" onClick={this.onSelect(row.values)}>
                Select
            </Button>
        } else if (column.id  === 'doc_id') {
            return this.getLink({url: `https://edh.cern.ch/Document/${docid}`, text: docid});
        }
        return value;
    };

    getLink = ({ url, text, external = true }) =>
        <Link
                style={linkStyle}
                to={{pathname: url}}
                {...external ? {rel: 'noopener noreferrer', target: '_blank'} : {}}
            >
            {text}
        </Link>

    gridRequestAdapter = gridRequest => {
        const { stores, storeData } = this.props;

        const storesAccess =  stores
            .filter(s => s.sitInbound === '+')
            .filter(el => storeData.some(s => s.code === el.store));

        const filtersStore = storesAccess?.length ?
                storesAccess.map((el, i) =>
                    ({
                        operator: "EQUALS",
                        fieldValue: el.locationBuilding,
                        joiner: i == storesAccess.length - 1 ? "AND" : "OR",
                        fieldName: GRID_KUPICK_FIELDALIAS.LOCATION,
                        leftParenthesis: i === 0,
                        rightParenthesis: i === storesAccess.length - 1
                    }))
                    : [{
                        operator: "EQUALS",
                        fieldValue: 'asdqweasdawweqew',
                        joiner: "AND",
                        fieldName: 'doc_id',
                    }]
                    ;

        return {
            ...gridRequest,
            gridFilter: [...gridRequest.gridFilter, ...filtersStore]
        }
    }

    renderPage () {
        const { applicationData, handleError, stores, storeData } = this.props;

        return (
            <AMMGrid
                gridRequestAdapter={this.gridRequestAdapter}
                screenCode={applicationData.flexBuildingSITGridName}
                handleError={handleError}
                useNative={false}
                cellRenderer={this.cellRenderer}
                rowStyler={this.rowStyler}
                extraColumns={[{
                    width: '105px',
                    headerLabel: '',
                    t: 'selectsit'
                }]}
            />
        );
    }
}

export default SITSelectionPage;