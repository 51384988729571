import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle  from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import BlockUi from 'react-block-ui';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const styles = () => ({
});

class EISConfirmDialog extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            blocking: false
        }
    }

    _onClickCancel = () => {
        if (this.props.cancelHandler) {
            this.props.cancelHandler();
        }
        this.props.closeConfirmDialog();
    };

    _closeDialog = (closeConfirmDialog) => {
        this.setState(() => ({ blocking: false}));
        closeConfirmDialog();
    };

    _onClickConfirm = () => {
        const { confirmHandler, closeConfirmDialog } = this.props;

        if (!confirmHandler) {
            return;
        }

        const _this = this;
        if (this.props.waitForCompletion) {
            _this.setState(() => ({ blocking: true}));
            const confirmPromise = confirmHandler();
            if (confirmPromise != null && typeof confirmPromise.then === 'function') {
                // wait for the promise to complete
                confirmPromise.then(
                    () => {_this._closeDialog(closeConfirmDialog)},
                    () => {_this._closeDialog(closeConfirmDialog)}
                );
            } else {
                _this._closeDialog(closeConfirmDialog);
            }
        } else {
            confirmHandler();
            closeConfirmDialog();
        }
    };

    render() {

        const { open, title, message, cancelButtonLabel, confirmButtonLabel } = this.props;
        const { blocking } = this.state;

        return (
            <Dialog
                open={open}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <BlockUi blocking={blocking}>
                        <DialogContentText>
                            {message}
                        </DialogContentText>
                    </BlockUi>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this._onClickCancel} color="primary" variant="contained" disabled={blocking}>
                        {cancelButtonLabel}
                    </Button>
                    <Button onClick={this._onClickConfirm} color="primary" variant="contained" disabled={blocking}>
                        {confirmButtonLabel}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

EISConfirmDialog.propTypes = {
    cancelButtonLabel: PropTypes.string,
    confirmButtonLabel: PropTypes.string,
    title: PropTypes.string,
    message: PropTypes.string,
    waitForCompletion: PropTypes.bool,
    open: PropTypes.bool
};

EISConfirmDialog.defaultProps = {
    cancelButtonLabel: 'Cancel',
    confirmButtonLabel: 'Confirm',
    message: 'Are you sure?',
    title: 'Confirm',
    waitForCompletion: false,
    open: false
}

export default withStyles(styles)(EISConfirmDialog);