import withStyles from '@mui/styles/withStyles';
import { useSelector, useDispatch } from "react-redux";
import KioskButton from 'ui/components/buttons/KioskButton';
import WS from 'tools/rest/WS';
import { handleError, showSuccess } from 'react-eis-components/actions/uiActions';

const classes = {
};

const UserSettings = ({  }) => {
    const dispatch = useDispatch();
    const { userData } = useSelector(state => state.application);

    if (userData.eamAccount?.userGroup?.startsWith('R5')) {
        return <div>
            <KioskButton
                onClick={async () => {
                    try {
                        const resp = (await WS.clearCache())?.body.data;
                        dispatch(showSuccess("Cache cleared successfully. Refresh the application"));
                    } catch (err) {
                        dispatch(handleError(err));
                    }
                }}
            >
                Clear Server Cache
            </KioskButton>
        </div>
    }
    return <div>You don't have access to any functionality on this page.</div>;
}

export default withStyles(classes)(UserSettings);