import { connect } from 'react-redux';
import { updateApplication } from "./actions/applicationActions";
import { initializeApplication } from "./actions/actions";

import Kiosk from './Kiosk';

const mapStateToProps = (state) => {
    return {
        userData: state.application.userData,
        storeData: state.application.storeData,
        loginToken: state.application.loginToken,
        kioskMode: state.application.kioskMode,
        applicationData: state.application.applicationData,
        storeAccess: state.application.storeAccess,
        currentStore: state.application.currentStore,
        groupStore:  state.application.groupStore,
    }
};

const KioskContainer = connect(mapStateToProps, {
    updateApplication,
    initializeApplication
})(Kiosk);

export default KioskContainer;
