// @flow
import ajax from './ajax';

/**
 * Handles all calls to REST Api
 */
class WS {

    //
    // GENERAL
    //
    getUserData(config = {}) {
        return this._get('/application/userdata', config);
    }

    clearCache(config = {}) {
        return this._delete('/application/cache', config);
    }


    getApplicationData(config = {}) {
        return this._get('/application/applicationdata', config);
    }

    getIdentificationResult(input, store, config = {}) {
        return this._get(`/identification/result/?input=${input}&store=${store}`, config);
    }

    postLoginData(input, config = {}) {
        return this._post('/identification/login', input, config);
    }

    getTranslations(config = {}) {
        return this._get('/application/translations', config);
    }


    //
    // STORES
    //
    getStoreData(config = {}) {
        return this._get('/store/storedata', config);
    }

    isKioskMode(config = {}) {
        return this._get('/store/kioskmode', config);
    }

    getStoresConfiguration(config = {}) {
        return this._get('/store/storesconfiguration', config);
    }

    //
    // AUTOCOMPLETE
    //
    autocompleteWorkorder(store, transactionType, key, config = {}) {
        return this._get(`/autocomplete/workorder/${store}/${transactionType}/${key}`, config);
    }

    autocompleteEquipment(key, config = {}) {
        return this._get(`/autocomplete/equipment?hint=${encodeURIComponent(key)}`, config);
    }

    autocompleteLocation(key, config = {}) {
        return this._get(`/autocomplete/location?code=${encodeURIComponent(key)}`, config);
    }

    autocompleteEmployee(key, config = {}) {
        return this._get(`/autocomplete/employee/${key}`, config);
    }

    autocompleteProject(key, config = {}) {
        return this._get(`/autocomplete/project/${key}`, config);
    }

    readWorkOrderActivities(workorder, config = {}) {
        return this._get(`/activities/read?workorder=${workorder}`)
    }

    readProjectBudgets(project, config = {}) {
        return this._get(`/budgetselection/budgetList?projectCode=${project}`)
    }

    submitTransaction(transaction, config = {}) {
        return this._put('/transaction/submit', transaction, config);
    }

    requestGridData(data, config = {}) {
        return this._post('/grids/data/', data, config)
            .then(resp => {
                resp.body.data =
                    resp.body.data
                    && resp.body.data.row
                    && resp.body.data.row.map(row => row.cell.reduce((acc, el) => ({...acc, [el.t]: el.value}), {}))
                return resp;
            }
        );
    }

    getPickTicketsByStatus = (store, config = {}) => this._get(`/store/${store}/outbound/count`, config)
    //
    //
    //

    _get(url, config = {}) {
        return ajax.get(process.env.REACT_APP_BACKEND + url, config);
    }

    _post(url, data, config = {}) {
        return ajax.post(process.env.REACT_APP_BACKEND + url, data, config);
    }

    _put(url, data, config = {}) {
        return ajax.put(process.env.REACT_APP_BACKEND + url, data, config);
    }

    _delete(url, config = {}) {
        return ajax.delete(process.env.REACT_APP_BACKEND + url, config);
    }

}

export default new WS();
