import { Button, Grid } from '@mui/material';
import React from 'react';

class StoreInfo extends React.Component {

    gotToStoreMenu = () => {
        if (this.props.kioskMode) {
            this.props.logout();
        }
        this.props.history.push(process.env.REACT_APP_FRONTEND)
    };

    render() {

        if (!this.props.currentStore) {
            return (
                <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <span>
                        <b>{this.props.storeCode}</b>{` STORE NOT FOUND`}
                    </span>
                    <Button variant="contained" color="primary" onClick={this.gotToStoreMenu} style={{ marginTop: 20 }}>
                        Change
                    </Button>
                </div>
            )
        }

        return (
            <div style={{ padding: 8 }}>
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    justify="flex-start"
                    alignItems="center">
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={this.gotToStoreMenu}>
                            {this.props.currentStore.code === '3SAF-STORE' ? 'Home' : 'Change Store'}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={() => this.props.history.goBack()}>
                            Back
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default StoreInfo;