import React from 'react';
import KioskButton from '../../../components/buttons/KioskButton';
import KITContextSelectionPageDetailsContainer from "./KITContextSelectionPageDetailsContainer";
import TransactionOn from '../../../../enums/TransactionOn';

class KITContextSelectionPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            [TransactionOn.WORKORDER]: true,
            [TransactionOn.EQUIPMENT]: false,
        };
    }

    componentDidMount() {
        const {transactionType} = this.props;
        if (transactionType) {
            this.props.updateTransaction({transactionType});
        } else {
            this.props.updateTransaction({transactionType: undefined});
        }

        this.checkForVisibleContexts(this.props);
    }

    componentWillReceiveProps(newProps) {
        if (newProps.storeConfiguration !== this.props.storeConfiguration) {
            this.checkForVisibleContexts(newProps);
        }
    }

    setTransactionOnWorkorder = () => {
        const {setTransactionOnWorkorder} = this.props;
        setTransactionOnWorkorder();
    };

    setTransactionOnProject = () => {
        const {setTransactionOnProject} = this.props;
        setTransactionOnProject();
    };

    setTransactionOnEquipment = () => {
        const {setTransactionOnEquipment} = this.props;
        setTransactionOnEquipment();
    };

    setTransactionOnEmployee = () => {
        const {setTransactionOnEmployee} = this.props;
        setTransactionOnEmployee();
    };

    checkForVisibleContexts = (props) => {
        const {storeConfiguration} = props;

        if (storeConfiguration && storeConfiguration.visibleFunctions) {
            const visibleFunctions = storeConfiguration.visibleFunctions?.split(',')
                .map(s => [...s])
                .reduce((acc, s) => [...acc, ...s], [])
            const isJustOneOption = visibleFunctions.length === 1;
            let newState = {};
            visibleFunctions.forEach(fun => {
                if (fun === 'W') {
                    newState = {
                        ...newState,
                        [TransactionOn.WORKORDER]: true
                    };
                    if (isJustOneOption) {
                        this.setTransactionOnWorkorder();
                    }
                }
                if (fun === 'P') {
                    newState = {
                        ...newState,
                        [TransactionOn.PROJECT]: true
                    };
                    if (isJustOneOption) {
                        this.setTransactionOnProject();
                    }
                }
                if (fun === 'O') {
                    newState = {
                        ...newState,
                        [TransactionOn.EQUIPMENT]: true
                    };
                    if (isJustOneOption) {
                        this.setTransactionOnEquipment();
                    }
                }
                if (fun === 'E') {
                    newState = {
                        ...newState,
                        [TransactionOn.EMPLOYEE]: true
                    };
                    if (isJustOneOption) {
                        this.setTransactionOnEmployee();
                    }
                }
            });
            this.setState(() => ({
                ...newState
            }));
        } else {
            this.setState(() => ({
                [TransactionOn.WORKORDER]: true,
                [TransactionOn.PROJECT]: true,
                [TransactionOn.EQUIPMENT]: true,
                [TransactionOn.EMPLOYEE]: true
            }));
        }

    };

    render() {

        if (!this.props.currentStore) {
            return null
        }

        const {currentTransaction} = this.props;

        return (
            <div style={{height: '100%', overflowY: 'auto'}} id="scrollthis">
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    flexGrow: '1',
                    marginTop: '20px'
                }}>
                    <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center'}}>
                        {this.state[TransactionOn.WORKORDER] &&
                        <KioskButton
                            color={currentTransaction.transactionOn === TransactionOn.WORKORDER ? 'primary' : 'info'}
                            onClick={this.setTransactionOnWorkorder}>
                            Work Order/Activity
                        </KioskButton>
                        }
                        {this.state[TransactionOn.EQUIPMENT] &&
                        <KioskButton
                            color={currentTransaction.transactionOn === TransactionOn.EQUIPMENT ? 'primary' : 'info'}
                            onClick={this.setTransactionOnEquipment}>
                            Equipment
                        </KioskButton>
                        }
                    </div>
                </div>

                <KITContextSelectionPageDetailsContainer
                    history={this.props.history}
                    kitcode={this.props.kitcode}
                />

            </div>
        );
    }
}

export default KITContextSelectionPage;
