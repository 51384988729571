import React from 'react';
import KioskButton from '../../components/buttons/KioskButton';
import ContextSelectionWorkOrder from "./SelectionPages/ContextSelectionWorkOrder";
import ContextSelectionProject from "./SelectionPages/ContextSelectionProject";
import ContextSelectionEquipment from "./SelectionPages/ContextSelectionEquipment";
import ContextSelectionEmployee from "./SelectionPages/ContextSelectionEmployee";
import TransactionOn from "../../../enums/TransactionOn";

class ContextSelectionPage extends React.Component {

    goToPartsAssetsSelection = entityType => () => {

        const {history, currentStore, currentTransaction} = this.props;
        const {transactionOn} = currentTransaction;

        switch (transactionOn) {
            case TransactionOn.WORKORDER:
                const {workOrderNumber, activityCode} = currentTransaction;
                if (!workOrderNumber || !activityCode) {
                    this.props.showWarning("Wrong workorder number or activity code");
                    return;
                }
                history.push(process.env.REACT_APP_FRONTEND + `${currentStore.code}/${currentTransaction.transactionType}/${entityType}?wo=${workOrderNumber}&activity=${activityCode}`);
                break;
            case TransactionOn.PROJECT:
                const {projectCode, budgetCode} = currentTransaction;
                if (!projectCode || !budgetCode) {
                    this.props.showWarning("Wrong project or budget code");
                    return;
                }
                history.push(process.env.REACT_APP_FRONTEND + `${currentStore.code}/${currentTransaction.transactionType}/${entityType}?project=${projectCode}&budget=${budgetCode}`);
                break;
            case TransactionOn.EQUIPMENT:
                const {equipmentCode} = currentTransaction;
                if (!equipmentCode) {
                    this.props.showWarning("Wrong equipment code");
                    return;
                }
                history.push(process.env.REACT_APP_FRONTEND + `${currentStore.code}/${currentTransaction.transactionType}/${entityType}?equipment=${equipmentCode}`);
                break;
            case TransactionOn.EMPLOYEE:
                const {employeeCode} = currentTransaction;
                if (!employeeCode) {
                    this.props.showWarning("Wrong employee code");
                    return;
                }
                history.push(process.env.REACT_APP_FRONTEND + `${currentStore.code}/${currentTransaction.transactionType}/${entityType}?employee=${employeeCode}`);
                break;
            default:
                return null;
        }
    };

    renderEntityComponent() {
        const {transactionOn} = this.props.currentTransaction;
        switch (transactionOn) {
            case TransactionOn.WORKORDER:
                return <ContextSelectionWorkOrder {...this.props}/>;
            case TransactionOn.PROJECT:
                return <ContextSelectionProject {...this.props}/>;
            case TransactionOn.EQUIPMENT:
                return <ContextSelectionEquipment {...this.props}/>;
            case TransactionOn.EMPLOYEE:
                return <ContextSelectionEmployee {...this.props}/>;
            default:
                return null;
        }
    }

    render() {
        const entityComponent = this.renderEntityComponent();
        return (
            <div style={{margin: '20px'}}>
                {
                    this.props.currentTransaction && entityComponent &&
                    <div style={{ borderRadius: '5px',  }}>
                        {
                            entityComponent
                        }
                        <div style={{display: 'flex', justifyContent: 'center', flexGrow: 1}}>
                            <KioskButton style={{margin:'15px'}} onClick={this.goToPartsAssetsSelection(this.props.currentTransaction.transactionOn.toLowerCase())}>
                                Next
                            </KioskButton>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default ContextSelectionPage;