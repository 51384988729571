import {connect} from 'react-redux';
import {setPage} from "../../../actions/layoutActions";
import {handleError} from '../../../react-eis-components/actions/uiActions';
import SITFlexMainPage from "./SITFlexMainPage";
import {logout} from "../../../actions/applicationActions";
import {updateAssetsForKit} from "../../../actions/flexBuildingActions";

const mapStateToProps = (state) => {
    const currentTransaction= state.currentTransaction;
    const language = state.layout.lang;
    const store = state.application.currentStore;
    const storeConfiguration = store ? state.application.storesConfiguration[store.code] : {};
    return {
        kioskMode: state.application.kioskMode,
        applicationData: state.application.applicationData,
        currentTransaction: currentTransaction,
        storeConfiguration: storeConfiguration,
        translations: store ? state.application.translations[language]['KIOSK_' + store.code] : {},
        storeAccess: state.application.storeAccess,
        currentStore: state.application.currentStore,
    }
};

const SITFlexMainPageContainer = connect(
    mapStateToProps, {
        setPage,
        logout,
        handleError,
        updateAssetsForKit
    }
)(SITFlexMainPage);

export default SITFlexMainPageContainer;