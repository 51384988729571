import Transaction from './Transaction';

const TRANSACTION_TYPE = {
    ISSUE: 'issue',
    RETURN: 'return',
}


export {
    TRANSACTION_TYPE,
}

export default Transaction;
