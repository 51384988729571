import { handleError, showError, showSuccess } from '../../../../react-eis-components/actions/uiActions';
import { connect } from 'react-redux';
import { logout } from "../../../../actions/applicationActions";
import { updateAssetsForKit } from "../../../../actions/flexBuildingActions";
import * as inboundActions from "../../../../actions/inboundProcessActions";
import { setPage } from "../../../../actions/layoutActions";
import SITDocsArticles from "./SITDocArticles";

const mapStateToProps = (state, ownProps) => {
    const currentTransaction = state.currentTransaction;
    const language = state.layout.lang;
    const store = state.application.currentStore;
    const storeCode = ownProps.match.params.storeCode;
    let storeConfiguration = state.application.storesConfiguration[storeCode];

    return {
        kioskMode: state.application.kioskMode,
        applicationData: state.application.applicationData,
        currentTransaction: currentTransaction,
        storeConfiguration: storeConfiguration,
        translations: store ? state.application.translations[language]['KIOSK_' + store.code] : {},
        updateArticles: true,
        documentId: ownProps.match.params.sitCode,
        storeAccess: state.application.storeAccess,
        currentStore: state.application.currentStore,
    }
};

const SITDocArticlesContainer = connect(
    mapStateToProps, {
        setPage,
        logout,
        handleError,
        showSuccess,
        showError,
        updateAssetsForKit,
        ...inboundActions
    }
)(SITDocsArticles);

export default SITDocArticlesContainer;