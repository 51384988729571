import React from 'react';
import KioskButton from '../../components/buttons/KioskButton';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import RemoveRedEye from '@material-ui/icons/RemoveRedEye';
import Search from '@material-ui/icons/Search';
import Link from '@material-ui/icons/Link';
import StoreInfo from "../../components/elements/StoreInfo";
import { withStyles } from '@mui/styles';
import {Account, Logout, Home} from 'mdi-material-ui';

const classes = {
    hidePanelOnMobile: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        flexGrow: '1',
        marginTop: '5px'
    },
    '@media (max-width: 400px)': {
        hidePanelOnMobile: {
            display: "none"
        }
    }
};

class SITFlexMainPage extends React.Component {

    state = {};

    goToOutbound = () => () => {
        this.props.history.push(process.env.REACT_APP_FRONTEND + `${this.props.currentStore.code}/outbound`)
    };

    goToInbound = () => () => {
        this.props.history.push(process.env.REACT_APP_FRONTEND + `${this.props.currentStore.code}/inbound`)
    };

    render() {

        if (!this.props.currentStore) {
            return <div></div>
        }

        const {translations} = this.props;
        return (
            <div style={{height: '100%', overflowY: 'auto'}}>

                <StoreInfo currentStore={this.props.currentStore} history={this.props.history} kioskMode={this.props.kioskMode} logout={this.props.logout}/>

                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    flexGrow: '1',
                    marginTop: '20px'
                }}>
                    <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center'}}>
                        <KioskButton onClick={this.goToInbound()}>
                            <div>
                                <Home style={{fontSize: '40px'}}/>
                                <ArrowBack style={{fontSize: '40px'}}/>
                            </div>
                            {translations['INBOUND'] || 'Inbound'}
                        </KioskButton>
                        <KioskButton onClick={this.goToOutbound()}>
                            <div>
                                <Home style={{fontSize: '40px'}}/>
                                <ArrowForward style={{fontSize: '40px'}}/>
                            </div>
                            {translations['OUTBOUND'] || 'Outbound'}
                        </KioskButton>
                    </div>
                </div>

            </div>
        );
    }
}

export default withStyles(classes)(SITFlexMainPage);