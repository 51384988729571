import WS from "./WS";

class WSFlexBuilding  {

    getArticles = sitCode => WS._get(`/flex/docs/${sitCode}/articles`);

    rejectDocument = sitCode => WS._delete(`/flex/docs/${sitCode}/`);

    receiveDocument = (sitCode, partial) => WS._put(`/flex/docs/${sitCode}/`, partial);

    createPartsAndAssets = (sit) => {
        return WS._put(`/flex/part/`, sit);
    };

    createAssetsAndPackage = sit => WS._put(`/flex/packages/`, sit);

    createKitTemplate = (kit) => {
        return WS._put(`/flex/kittemplate/`, kit);
    };

    buildKit = (kit) => {
        return WS._put(`/flex/buildkit/`, kit);
    };

    placePackage = packageInfo => WS._put(`/flex/packages/place`, packageInfo);

    returnToStore = inboundBean => WS._post(`/flex/stores/${inboundBean.storeCode}/return`, inboundBean);

    updateWOWithKit = (kit) => {
        return WS._put(`/flex/updatewo/`, kit);
    };

    createWOWithKit = (kit) => {
        return WS._put(`/flex/createwo/`, kit);
    };

    getPrinters = () => WS._get(`/barcode/printers/`);

    printBarcode(barcodeInput, printer, config = {}) {
        const printerValue = printer || '';
        return WS._put(`/barcode/printBarcode?printer=${printerValue}`, barcodeInput, config);
    }

    updateArticles(sitAssetsInfo, config = {}) {
        return WS._put('/flex/equipment/', sitAssetsInfo, config);
    }

    getOrCreateInInforByStrategy = (sitAssetsInfo, config = {}) => WS._put(`/flex/articles`, sitAssetsInfo, config);

    createPackage = (sitAssetsInfo, config = {}) => WS._post(`/flex/packages/`, sitAssetsInfo, config);

    getStoreInfo = (storeCode, config = {}) => WS._get(`/flex/stores/${storeCode}`, config);

    createPickTicket = (createPickTicketBean, config = {}) => WS._post(`/request/picktickets`, createPickTicketBean, config)

    getPickTicketItems = (pickTicketCode, config = {}) => WS._get(`/request/picktickets/${pickTicketCode}`, config)

    handlePickTicket = (pickTicketCode, outboundBean, config = {}) => WS._put(`/request/picktickets/${pickTicketCode}`, outboundBean, config)

    pickupPickTicket = (pickTicketCode, config = {}) => WS._put(`/request/picktickets/${pickTicketCode}/pickup`, config)

    rejectPickTicket = (pickTicketCode, config = {}) => WS._delete(`/request/picktickets/${pickTicketCode}`, config)

    getLots = () => WS._get(`/flex/lots`)
}

export default new WSFlexBuilding();