import { ACTIONS } from "../actions/inventoryActions";

export const INV_STATE_KEY = 'INV';

const initialState = {
    modeInfo: {}
}

export default function inventoryReducer (state = initialState, action = {}) {
    switch (action.type) {
        case ACTIONS.INV_UPDATE_MODE_INFO: {
            return {
                ...state,
                modeInfo: {
                    ...state.modeInfo,
                    ...action.modeInfo
                }
            }
        }
        case ACTIONS.INV_RESET_MODE_INFO: {
            return {
                ...state,
                modeInfo: {}
            }
        }
        default: {
            return state;
        }
    }
}

const getModeInfo = (state) => () => state.modeInfo

const inventoryGetters = {
    getModeInfo
}

export const inventoryGettersWithState = state => Object.keys(inventoryGetters)
        .reduce((acc, key) => Object.assign(acc, {[key]: inventoryGetters[key](state[INV_STATE_KEY])}), {})