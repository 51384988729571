import { Button } from '@mui/material';
import EAMTextField from 'eam-components/dist/ui/components/inputs-ng/EAMTextField';
import EAMSelect from 'eam-components/dist/ui/components/inputs-ng/EAMSelect';
import { CloseCircle } from 'mdi-material-ui';
import React from 'react';
import BlockUi from 'react-block-ui';
import EISTable from '../../../react-eis-components/ui/components/table';
import WS from '../../../tools/rest/WSPartSelection';
import { createOnChangeHandler } from 'eam-components/dist/ui/components/inputs-ng/tools/input-tools';

const treatNumber = (value, max) => {
    value = value || ""
    value = value.replace(/[^.0-9]/g, "").replace(/([^.]*\.[^.]*)(\.)/g, "$1")
    value = value.replace(/([^.]*\.[^.]*)(\.)/g, "$1") // Remove extra dots
    value = (+value < 0 ? "1" : +value > max ? max : value)
    return value;
}

const computedData = (codeLines, transLines, { bin, lot, uom, quantity, partCode, assetCode }, selectedItemData) => {
    const selectableLots = (selectedItemData && selectedItemData.lot && selectedItemData.lot.split(',')) || null;

    const availLines = codeLines.filter(line =>
            !transLines
                //Only consider lines for the same entity
                .filter(tl => tl.partCode === partCode && tl.assetCode === assetCode)
                //Exclude bin/lot pairs already considered
                .some(tl => tl.bin === line.bin && tl.lot === line.lot)
            )
            //If user specified bins, use only those ones
            .filter(tl => !selectableLots || selectableLots.includes(tl.lot))

    const getShouldConsiderLine = (keyFilter, keyValue) => line => !keyValue || line[keyFilter] === keyValue;
    const availBins = getLinesConstrained(availLines, 'bin', getShouldConsiderLine('lot', lot), uom);
    const availLots = getLinesConstrained(availLines, 'lot', getShouldConsiderLine('bin', bin), uom);
    const qty = getLinesConstrained(
            availLines,
            '__neverMatch',
            line => getShouldConsiderLine('bin', bin)(line) && getShouldConsiderLine('lot', lot)(line)
        ).reduce((acc, line) => acc + line.quantity, 0)

    const canHandleIt = bin && lot && quantity && quantity <= qty && qty > 0;

    return {
        availLines,
        availBins,
        availLots,
        quantity: qty,
        canHandleIt
    }
}

const getLinesConstrained = (codeLines, keyWanted, shouldConsiderLine, uom = '') => {
    const linesToConsider = codeLines.filter(shouldConsiderLine);
    const result = [...new Set(linesToConsider.map(line => line[keyWanted]))]
        .map(code => {
            const quantity = linesToConsider.reduce((acc, el) => code === el[keyWanted] ? acc + el.quantity : acc, 0);
            return {
                code,
                quantity,
                uom,
                desc: `${code} - (${quantity}${uom})`
            }
        })
    return result;
}

const addTransLine = ({ line, selectedItemData = {}, updateState, transLines, updateTransLines }) => {
    line = {
        id: [line.assetCode, line.partCode, line.bin, line.lot].join('#'),
        other: selectedItemData,
        ...line,
    }

    updateTransLines([...transLines, line]);
    updateState(prevState => ({selectedData: {...prevState.selectedData, bin: '', lot: '', quantity: "1"}}));
}

const addRemoveButton = (item, transLines, updateTransLines) => ({
    remove: (<CloseCircle
            onClick={_ => updateTransLines(transLines.filter(line => line.id !== item.id))}
            style={{color: 'rgba(230, 63, 43, 0.7)'}}>
        </CloseCircle>),
        ...item
    })

const loadData = ({code, codeDesc, selectedItemData, storeCode, quantityRequired, updateState, transLines, updateTransLines, clearCode, handleError}) => {
    updateState({selectedData: {}, codeLines: [], show: true})
    WS.autocompleteCodeInfo(storeCode, {code})
        .then(resp => {
            const codeLines = resp.body.data;
            const uom = (codeLines[0] || {}).uom
            const assetCode = (codeLines[0] || {}).assetCode;
            const partCode = (codeLines[0] || {}).partCode;
            const serialNumber = (codeLines[0] || {}).serialno;
            const selectedData = {code, assetCode, partCode, serialNumber, uom, quantity: 1}
            selectedData.desc = codeDesc;
            if (assetCode) {
                if (transLines.some(s => s.assetCode === code)) {
                    //TODO show message saying Asset already handled
                    updateState({selectedData: {}, show: null})
                    clearCode();
                    return
                }
                selectedData.bin = (codeLines[0] || {}).bin;
                selectedData.lot = (codeLines[0] || {}).lot;
                addTransLine({line: selectedData, selectedItemData, updateState, quantityRequired, transLines, updateTransLines, clearCode, selectedData: {}});
                clearCode();
            } else {
                updateState({selectedData, codeLines: resp.body.data});
            }

        })
        .catch(err => {
            handleError(err);
            clearCode();
        })
}

export const qtyHandled = (code, transLines) => transLines
        .filter(it => it.code === code)
        .map(it => +it.quantity || 0)
        .reduce((sum, n) => n + sum, 0)

class BinLotQty extends React.Component {

    state = {
        loading: undefined,
        codeDesc: '',
        selectedData: {},
        codeLines: []
    }

    componentDidMount() {
        const { code, codeDesc, storeCode, quantityRequired, updateTransLines, clearCode = () => 1, selectedItemData = {}, handleError } = this.props;

        if (code) loadData({code, codeDesc, selectedItemData, storeCode, quantityRequired, updateState: this.setState.bind(this), updateTransLines, clearCode, handleError});
    }

    componentDidUpdate(prevProps, prevState) {
        const { code, codeDesc, storeCode, quantityRequired, updateTransLines, transLines, clearCode = () => 1, selectedItemData = {}, handleError } = this.props;
        const { codeLines, selectedData } = this.state;
        const prevCode = prevProps.code;
        const updateState = this.setState.bind(this);

        if (!code) return;

        if (prevCode !== code) {
            loadData({code, codeDesc, storeCode, selectedItemData, updateState, transLines, quantityRequired, updateTransLines, clearCode, handleError});
            return;
        }

        const isFinished = quantityRequired && qtyHandled(code, transLines) === quantityRequired;
        if (isFinished) {
            this.setState({selectedData: {}})
            clearCode();
        }

        const { availBins, availLots, quantity } = computedData(codeLines, transLines, selectedData, selectedItemData);

        //TODO move inside functional component or component generator
        if (availBins.length === 1 && !selectedData.bin) {
            this.updateSelectedData('bin', availBins[0].code)
        }

        if (availLots.length === 1 && !selectedData.lot) {
            this.updateSelectedData('lot', availLots[0].code)
        }

        if (quantity < selectedData.quantity) {
            this.updateSelectedData('quantity', quantity)
        }
    }

    updateSelectedData(key, value) {
        this.setState(prevState => {
            const selectedData = {...prevState.selectedData, [key]: value}
            // In case one of these is removed, remove also the other to eliminate constraints
            if (['bin', 'lot'].includes(key) && !value) {
                selectedData.bin = '';
                selectedData.lot = '';
            }
            return {selectedData};
        })
    }

    updateSelectedDataIfAvailable(key, value, availableList) {
        // Checking for empty string enables clearing the field
        if (availableList.some((item) => item.code === value) || value === '') {
            this.updateSelectedData(key, value);
        }
    }

    updateSelectedDataTreatedNumber(key, value, maxQuantity) {
        const treatedNumber = treatNumber(value, maxQuantity);
        this.updateSelectedData(key, treatedNumber);
    }

    validateQuantity = (maxQuantity) => (value) => {
        const treatedNumber = treatNumber(value, maxQuantity);

        // In case of a failed validation, the behavior of EAMTextField would be
        // to revert to the previous valid value that the user inputted. However,
        // the expectation in this component is to transform the invalid value
        // according to the result of the 'treatNumber' function. Hence, we
        // update the stored quantity to reflect the expected behavior.
        if (treatedNumber !== value) {
            this.updateSelectedData('quantity', treatedNumber);
            return false;
        }

        return true;
    }

    render() {
        const { code, quantityRequired, codeDesc, updateTransLines, transLines = [], clearCode = () => 1, selectedItemData = {}, fieldStyle } = this.props;
        const { codeLines, selectedData, show } = this.state;

        const { availBins, availLots, quantity, canHandleIt } = computedData(codeLines, transLines, selectedData, selectedItemData);

        const remainingQuantity = code && quantityRequired ? quantityRequired - qtyHandled(code, transLines) : quantity;

        const maxQuantity = Math.min(remainingQuantity, quantity);

        return <div style={{ display: "flex", flexDirection: "column", flexWrap: "wrap", justifyContent: "space-between", width: "100%" }}>
            {code && show && <BlockUi tag="div" blocking={!selectedData.code} style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between", width: "100%" }}>
                    <div>
                        <EAMSelect
                            label="Bin - (Qty. on Hand)"
                            style={fieldStyle}
                            disabled={availBins.length === 1}
                            onChange={createOnChangeHandler(
                                'bin', null, null, this.updateSelectedDataIfAvailable.bind(this), null, [availBins]
                            )}
                            value={selectedData.bin}
                            options={availBins}
                            renderValue={value => value.desc || value.code}
                            // autoFocus // TODO: needs update of inputs-ng
                            barcodeScanner
                        />
                    </div>

                    <div>
                        <EAMSelect
                            label="Lot - (Qty. on Hand)"
                            style={fieldStyle}
                            disabled={availLots.length === 1}
                            onChange={createOnChangeHandler(
                                'lot', null, null, this.updateSelectedDataIfAvailable.bind(this), null, [availLots]
                            )}
                            value={selectedData.lot}
                            renderValue={value => value.desc || value.code}
                            options={availLots}
                            barcodeScanner
                        />
                    </div>

                    <div>
                        <EAMTextField
                            label={`Qty (On Hand: ${quantity})`}
                            style={{maxWidth: '140px'}}
                            value={"" + (selectedData.quantity || "")}
                            onChange={createOnChangeHandler(
                                'quantity', null, null, this.updateSelectedDataTreatedNumber.bind(this), null, [maxQuantity]
                            )}
                            validator={this.validateQuantity(maxQuantity)}
                        />
                        {}
                        {quantityRequired &&
                            <React.Fragment>
                                <span style={{ fontSize: "small", color: "#b5b5b5" }}>Lacking </span>
                                <span
                                        style={{ textDecoration: 'underline', fontSize: "small", color: "#FF7F50" }}
                                        onClick={() => this.updateSelectedData('quantity', treatNumber("" + remainingQuantity, maxQuantity))}
                                    >
                                    {remainingQuantity}
                                </span>
                                <span style={{ fontSize: "small", color: "#b5b5b5" }}> of {quantityRequired} requested.</span>
                            </React.Fragment>
                        }
                    </div>

                    <Button
                            onClick={clearCode}
                            variant="contained"
                            color="primary"
                            style={{margin: '8px'}}
                        >
                        Cancel
                    </Button>
                    <Button
                            disabled={!canHandleIt}
                            variant="contained"
                            color="primary"
                            onClick={() => addTransLine({line: {...selectedData, desc: codeDesc}, selectedItemData, updateState: this.setState.bind(this), quantityRequired, updateTransLines, transLines, clearCode})}
                            style={{margin: '8px'}}
                        >
                        ADD
                    </Button>
                </BlockUi>
            }
            { transLines.length > 0 &&
                <EISTable
                        data={transLines.map(item => addRemoveButton(item, transLines, updateTransLines))}
                        headers={['', 'Asset', 'Part', 'Serial', 'Desc', 'Bin', 'Lot', 'Qty']}
                        propCodes={['remove', 'assetCode', 'partCode', 'serialNumber', 'desc', 'bin', 'lot', 'quantity']}
                />
            }
            </div>
    }

}

export default BinLotQty;