import { handleError, openConfirmDialog, showSuccess } from '../../../../react-eis-components/actions/uiActions';
import { connect } from 'react-redux';
import { logout } from "../../../../actions/applicationActions";
import { getRoutes } from "../../../../tools/routes";
import OutboundPickTicketSearch from "./OutboundPickTicketSearch";
import { resetOutboundInfo, setOutboundPickTicket, setOutboundItems } from "../../../../actions/outboundProcessActions";

const mapStateToProps = (state, ownProps) => {
    const language = state.layout.lang;
    const store = state.application.currentStore;
    const storeConfiguration = store ? state.application.storesConfiguration[store.code] : {};

    return {
        kioskMode: state.application.kioskMode,
        applicationData: state.application.applicationData,
        storeConfiguration,
        translations: store ? state.application.translations[language]['KIOSK_' + store.code] : {},
        storeCode: ownProps.match.params.storeCode,
        requestProcess: state.requestProcess,
        reduxStore: state,
        routes: getRoutes(process.env.REACT_APP_FRONTEND, store ? store.code : ''),
        storeAccess: state.application.storeAccess,
        currentStore: state.application.currentStore,
        groupStore: state.application.groupStore,
    }
};

const OutboundPickTicketSearchContainer = connect(
    mapStateToProps, {
        logout,
        handleError,
        showSuccess,
        openConfirmDialog,
        resetOutboundInfo,
        setOutboundItems,
        setOutboundPickTicket
    }
)(OutboundPickTicketSearch);

export default OutboundPickTicketSearchContainer;