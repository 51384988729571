import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import withStyles from '@mui/styles/withStyles';
import EAMFormLabel from 'eam-components/dist/ui/components/inputs/EAMFormLabel';
import EAMSelect from 'eam-components/dist/ui/components/inputs-ng/EAMSelect';
import EAMAutocomplete from 'eam-components/dist/ui/components/inputs-ng/EAMAutocomplete';
import EAMTextField from 'eam-components/dist/ui/components/inputs-ng/EAMTextField';
import React from 'react';
import BlockUi from 'react-block-ui';
import EISTable from '../../../react-eis-components/ui/components/table';
import WS from '../../../tools/rest/WS';
import WSInventory, { CELL_TYPES, INVLINES_DATA_CONVERTER } from '../../../tools/rest/WSInventory';
import Containerize from './Containerize';
import { loadBins } from './InventoryModeSelector';
import TextField from '@mui/material/TextField';
import { Container, Typography, IconButton } from '@mui/material';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import KioskAccess from '../KioskAccess';
import { createOnChangeHandlerObjectUpdate } from 'eam-components/dist/ui/components/inputs-ng/tools/input-tools';

const ACTIONS = {
    BIN2BIN: 'BIN2BIN',
    RESTOCK: 'RESTOCK',
    RETURN_TRANSACTION: 'RETURN',
    ISSUE_TRANSACTION: 'ISSUE',
    DECLARE_LOST: 'DECLARE_LOST',
}

const styles = theme => ({
    root: {
        boxShadow: '0 3px 5px 2px rgba(128, 128, 128, .20)',
        fontSize: 18
    },
    '@media ( min-width : 641px)': {
        sizeLarge: {
            minWidth: "130px",
            minHeight: "65px",
            marginLeft: '3px',
            marginRight: '3px'
        }
    },
    '@media ( max-width : 640px)': {
        sizeLarge: {
            marginLeft: '2px',
            marginRight: '2px'
        }
    },
});


const inventoryFields = (inventory, updateState, statusTransitions) => inventory && [
    {
        header: 'Code',
        code: inventory.code,
        description: inventory.desc
    },
    {
        header: 'Mode',
        code: inventory.mode,
        description: inventory.hierarchyLocationDesc
    },
    {
        header: 'Status',
        code: 'status',
        //code: inventory.status,
        Component: _ => <div style={{display: 'flex', direction: 'row', maxWidth: '300px'}}>
                <EAMFormLabel
                    style={{color: 'black', width:'100%'}}
                    label="Status"
                />
                <Select
                        id="status-select"
                        value={inventory.status}
                        onChange={evt => updateState(prevState => ({inventory: {...prevState.inventory, status: evt.target.value}}))}
                >
                    {statusTransitions.map(st => <MenuItem value={st.code} key={'statusselect@' + st.code}>{`${st.code}: ${st.desc}`}</MenuItem>)}
                </Select>
            </div>
    }
];

const updateInventoryAssetLine = (el, updateLine) => (line) => _ => {
    updateLine(el.pk, {[CELL_TYPES.BLOCKING]: true});
    return WSInventory.updateInventoryAssetLine(
            el[CELL_TYPES.INVENTORY_CODE],
            convertInventoryLine(line)
        ).then(_ => {
            updateLine(el.pk, {...line, [CELL_TYPES.BLOCKING]: false});
            return _
        })
        .catch(err => {
            updateLine(el.pk, {[CELL_TYPES.BLOCKING]: false});
            return err;
        })
    }

const updateInventoryPartLine = (el, updateLine) => (line) => _ => {
    updateLine(el.pk, {[CELL_TYPES.BLOCKING]: true});
    return WSInventory.updateInventoryPartLine(
            el[CELL_TYPES.INVENTORY_CODE],
            el[CELL_TYPES.LINE_NUMBER],
            convertInventoryLine(line)
        ).finally(resp => {
            updateLine(el.pk, {[CELL_TYPES.BLOCKING]: false});
            return resp;
        })
    }

const dataRenderer = (data, updateLine, storeCode) => data.map(el => {
    const inputPhys = el[CELL_TYPES.ASSET_CODE] ?
            // Repeated code since it will change for ButtonGroup
            <BlockUi blocking={el[CELL_TYPES.BLOCKING]}>
                <Button
                        color={el[CELL_TYPES.PHYQTY] == 0 ? "primary" : "info"}
                        variant="contained"
                        onClick={_ => {
                            const line = {
                                ...el,
                                [CELL_TYPES.PHYQTY]: "0"
                            }
                            if (line[CELL_TYPES.PHYQTY] == line[CELL_TYPES.EXPQTY]) {
                                line[CELL_TYPES.ACTION] = null;
                                // line[CELL_TYPES.DESTBIN] = null;
                            }
                            updateInventoryAssetLine(el, updateLine)(line)()
                        }}
                    >
                    NOT IN BIN
                </Button>
                <Button
                        color={el[CELL_TYPES.PHYQTY] == 1 ? "primary" : "info"}
                        variant="contained"
                        onClick={_ => {
                            const line = {
                                ...el,
                                [CELL_TYPES.PHYQTY]: "1"
                            }
                            if (line[CELL_TYPES.PHYQTY] == line[CELL_TYPES.EXPQTY]) {
                                line[CELL_TYPES.ACTION] = null;
                                // line[CELL_TYPES.DESTBIN] = null;
                            } else {
                                line[CELL_TYPES.ACTION] = ACTIONS.RETURN_TRANSACTION
                            }
                            updateInventoryAssetLine(el, updateLine)(line)()
                        }}
                    >
                    IN BIN
                </Button>
            </BlockUi>
            : <BlockUi blocking={el[CELL_TYPES.BLOCKING]}>
                <EAMTextField
                    style={{maxWidth: '200px'}}
                    value={el[CELL_TYPES.PHYQTY] || ''}
                    onChangeInput={(value) =>
                        updateLine(el.pk, {[CELL_TYPES.PHYQTY]: value})
                    }
                    onBlur={updateInventoryPartLine(el, updateLine)(el)}
                />
            </BlockUi>

    //TODO improve action needed
    const actionNeeded =
            (!el[CELL_TYPES.PHYQTY] && el[CELL_TYPES.PHYQTY] != 0) ? <span style={{color: 'orange'}}>Fill quantity</span>
            : el[CELL_TYPES.PHYQTY] === el[CELL_TYPES.EXPQTY] ? <span style={{color: 'lightgrey'}}>None</span>
            //: !el[CELL_TYPES.ASSET_CODE] && el[CELL_TYPES.EXPQTY] == 0 ? <span style={{color: 'blue'}}>{ACTIONS.RESTOCK}</span>
            : !el[CELL_TYPES.ASSET_CODE] ? <span style={{color: 'blue'}}>Quantity will be corrected.</span>
            : el[CELL_TYPES.PHYQTY] == 0 ?<span style={{color: 'red'}}>{getDropDown({line: el, updateLine, updateAssetLine: updateInventoryAssetLine(el, updateLine)})}</span>
            : <span style={{color: 'green'}}>{el[CELL_TYPES.ACTION]}</span>

    const destination = getFromTo({line: el, updateAssetLine: updateInventoryAssetLine(el, updateLine), loadBins: loadBins(storeCode)})

    const comment =  <BlockUi blocking={el[CELL_TYPES.BLOCKING]}>
        <TextField id="standard-basic"
            onBlur={(evt) => updateInventoryAssetLine(el, updateLine)({...el, [CELL_TYPES.COMMENT]: evt.target.value})()}
            onChange={(evt) => updateLine(el[CELL_TYPES.PK], {[CELL_TYPES.COMMENT]: evt.target.value})}
            variant="outlined"
            value={el[CELL_TYPES.COMMENT] || ""}
            multiline={true}
            rowsMax={3}
            style={{padding: '5px'}}
        />
        </BlockUi>

    return {
        ...el,
        [CELL_TYPES.INPUT_QTY]: inputPhys,
        [CELL_TYPES.ACTION_NEEDED]: actionNeeded,
        [CELL_TYPES.DESTINATION]: destination,
        [CELL_TYPES.COMMENT]: comment
    };
})

const getFromTo = ({line, updateAssetLine, loadBins}) => {
    const getLineUpdated = (loc, bin) => ({
        ...line,
        [CELL_TYPES.ACTION]: line[CELL_TYPES.ACTION],
        [CELL_TYPES.DESTLOC]: loc,
        [CELL_TYPES.DESTBIN]: bin
    })

    return line[CELL_TYPES.ACTION] === ACTIONS.ISSUE_TRANSACTION ?
        <BlockUi blocking={line[CELL_TYPES.BLOCKING]}>
            <EAMAutocomplete
                key={`loc@${line[CELL_TYPES.ASSET_CODE]}`}
                value={line[CELL_TYPES.DESTLOC] || ''}
                onChange={(value) => {
                    const code = value.code;
                    if (code !== line[CELL_TYPES.DESTLOC]) {
                        updateAssetLine(getLineUpdated(code, ''))();
                    }
                }}
                autocompleteHandler={key => WS.autocompleteEquipment(key)}
                style={{minWidth: '200px', maxWidth: '200px'}}
            />
        </BlockUi>
    : line[CELL_TYPES.ACTION] === ACTIONS.BIN2BIN && line[CELL_TYPES.PHYQTY] == 0 ?
        <BlockUi blocking={line[CELL_TYPES.BLOCKING]}>
            <EAMAutocomplete
                key={`bin@${line[CELL_TYPES.ASSET_CODE]}`}
                value={line[CELL_TYPES.DESTBIN] || ''}
                onChange={(value) => {
                    const code = value.code;
                    if (code !== line[CELL_TYPES.DESTBIN]) {
                        updateAssetLine(getLineUpdated("", code))();
                    }
                }}
                autocompleteHandler={loadBins}
            />
        </BlockUi>
    : line[CELL_TYPES.ACTION] === ACTIONS.DECLARE_LOST ? null
    // /: line[]
    : line[CELL_TYPES.DEST] || line[CELL_TYPES.DESTBIN] || line[CELL_TYPES.DESTLOC]
}

const getDropDown = ({ line, updateLine, updateAssetLine }) => {
    return <Select
                id={`status-select-action-${line[CELL_TYPES.PK]}`}
                value={line[CELL_TYPES.ACTION] || ""}
                onChange={evt => {
                    const action = evt.target.value;
                    if (action === ACTIONS.DECLARE_LOST) {
                        updateAssetLine({...line, [CELL_TYPES.ACTION]: action})();
                        return;
                }
                    updateLine(line[CELL_TYPES.PK], {[CELL_TYPES.ACTION]: action})
                }}
                displayEmpty
                renderValue={(value) => value ?
                        <MenuItem style={{margin: 0, padding: 0, color: 'green', fontSize: '12px'}} value={value} key={'statusselect1'}>{`${value}`}</MenuItem>
                        : <MenuItem style={{margin: 0, padding: 0, color: '#FF6666', fontSize: '12px'}} value={""}>Action Needed</MenuItem>
                }
            >
            {[ACTIONS.BIN2BIN, ACTIONS.ISSUE_TRANSACTION, ACTIONS.DECLARE_LOST].map(st => <MenuItem value={st} key={'statusselect@' + st}>{`${st}`}</MenuItem>)}
        </Select>
}

const convertInventoryLine = (line) => Object.keys(line)
    .filter(s => Object.keys(INVLINES_DATA_CONVERTER).includes(s))
    .reduce((acc, k) => ({ ...acc, [INVLINES_DATA_CONVERTER[k]]: line[k]}), {})

const tableData = [
    {code: CELL_TYPES.BIN, header: 'Bin'},
    {code: CELL_TYPES.ASSET_CODE, header: 'Asset'},
    {code: CELL_TYPES.PART_CODE, header: 'Part'},
    {code: CELL_TYPES.DESCRIPTION, header: 'Description'},
    {code: CELL_TYPES.LOT, header: 'Lot'},
    {code: CELL_TYPES.EXPQTY, header: 'Expected'},
    {code: CELL_TYPES.INPUT_QTY, header: 'Quantity In Bin'},
    {code: CELL_TYPES.ACTION_NEEDED, header: 'Action'},
    {code: CELL_TYPES.DESTINATION, header: 'From/To'},
    {code: CELL_TYPES.COMMENT, header: 'Comment'},
]

const getCurrentLines = (inventoryLines, bin) =>
    inventoryLines.filter(line =>
        //Filter by bin if present
        (!bin || line[CELL_TYPES.BIN] === bin || line[CELL_TYPES.DESTBIN] === bin)
        //Filter parts with 0 lines that have no action
        && (line[CELL_TYPES.ASSET_CODE] || line[CELL_TYPES.EXPQTY] > 0 || line[CELL_TYPES.ACTION])
    )

const handleItemScanned = ({ code, inventoryLines, updateLine, bin, inventory, reload, setLoading, handleError, clearScannedField }) => async _ => {
    if (!code) return;

    code = code.trim();

    setLoading(true);

    try {
        code = (await WSInventory.getCode(code)).body.data;
    } catch(err) {

    }


    //If the ASSET is on the lines, mark it as FOUND
    const currentLines = getCurrentLines(inventoryLines, bin);
    if (currentLines.some(s => s[CELL_TYPES.ASSET_CODE] === code)) {
        const el = currentLines.filter(s => s[CELL_TYPES.ASSET_CODE] === code)[0]
        const line = {
            ...el,
            [CELL_TYPES.PHYQTY]: "1"
        }
        return updateInventoryAssetLine(el, updateLine)(line)()
            .then(_ => {
                clearScannedField();
                reload();
            })
            .catch(handleError)
            .finally( _ => {
                clearScannedField();
                setLoading(false)
            })
    }

    //If the ASSET is on the other lines, mark it as BIN2BIN transfer
    if (inventoryLines.some(s => s[CELL_TYPES.ASSET_CODE] === code)) {
        const el = inventoryLines.filter(s => s[CELL_TYPES.ASSET_CODE] === code)[0]
        const line = {
            ...el,
            [CELL_TYPES.PHYQTY]: "0",
            [CELL_TYPES.ACTION]: ACTIONS.BIN2BIN,
            [CELL_TYPES.DESTBIN]: bin
        }
        return updateInventoryAssetLine(el, updateLine)(line)()
            .then(_ => {
                clearScannedField();
                reload();
            })
            .catch(handleError)
            .finally( _ => {
                clearScannedField();
                setLoading(false)
            })
    }

    const partLine = inventoryLines.filter(s => !s[CELL_TYPES.ASSET_CODE] && s[CELL_TYPES.PART_CODE] === code && s[CELL_TYPES.BIN] === bin);
    if (partLine && partLine.length === 1) {
        if (partLine[0][CELL_TYPES.EXPQTY] == 0 && !partLine[0][CELL_TYPES.ACTION]) {
            const line = {
                ...convertInventoryLine(partLine[0]),
                [INVLINES_DATA_CONVERTER[CELL_TYPES.ACTION]]: ACTIONS.RESTOCK,
            }
            updateLine(line.pk, line)
            return WSInventory.addInventoryAssetLine(inventory.storeCode, line)
                .then(_ => {
                    clearScannedField();
                    reload();
                })
                .catch(handleError)
                .finally( _ => {
                    clearScannedField();
                    setLoading(false)
                })
        } else {
            setLoading(false);
        }
        //FOCUS PART QUANTITY
        return;
    }

    if (partLine && partLine.length > 1) {
        handleError({type: "SERVER_ERROR", response: {body: {errors: [{title: '123', message: 'Multiple lots are not yet supported.'}]}}})
        clearScannedField();
        setLoading(false)
        return;
    }

    //TODO if part is not on lines

    //Add asset line
    if (inventoryLines.filter(s => s[CELL_TYPES.ASSET_CODE] === code).length === 0) {
        return WSInventory.addInventoryAssetLine(inventory.storeCode, {
                [INVLINES_DATA_CONVERTER[CELL_TYPES.STORE]]: inventory.storeCode,
                [INVLINES_DATA_CONVERTER[CELL_TYPES.INVENTORY_CODE]]: inventory.code,
                [INVLINES_DATA_CONVERTER[CELL_TYPES.ASSET_CODE]]: code,
                [INVLINES_DATA_CONVERTER[CELL_TYPES.BIN]]: bin,
            })
            .then(_ => {
                clearScannedField();
                reload();
            })
            .catch(handleError)
            .finally( _ => {
                clearScannedField();
                setLoading(false)
            })
    }

    setLoading(false);
}

const finish = ({inventory, inventoryLines, showSuccess, handleError, setLoading, reload}) => {
    setLoading(true);
    WSInventory.updateInventory(inventory)
        .then(resp => showSuccess('Inventory updated'))
        .catch(handleError)
        .finally(_ => {setLoading(false); reload()})
}

class InventoryPerform extends KioskAccess {

    state = {
        inventoryLines: [],
        loading: null,
        inventory: {},
        error: null,
        bin: null,
        code: '',
        statusTransitions: null,
    }

    componentDidMount() {
        this.reload(true);
    }

    reload(includeStatusTransitions = false) {
        const { handleError, inventoryCode } = this.props;
        const { inventory, statusTransitions } = this.state;

        this.setState({loading: true})
        Promise.all([
                WSInventory.readInventory(inventoryCode),
                WSInventory.readInventoryAllLines(inventoryCode)
            ])
            .then(resp => {
                let status = resp[0].body.data.status;
                return (includeStatusTransitions ? WSInventory.getInventoryStatusTransitions(status) : Promise.resolve({body: {data: statusTransitions}}))
                    .then(r => this.setState({
                        inventory: resp[0].body.data,
                        inventoryLines: resp[1].body.data,
                        statusTransitions: r.body.data,
                    }))
                    .catch(err => {
                        handleError(err);
                        this.setState({error: err})
                    })
            }
            )
            .catch(err => {
                handleError(err);
                this.setState({error: err})
            })
            .finally(_ => this.setState({loading: false}))
    }

    componentDidUpdate() {
        const { inventoryLines, bin } = this.state;
        const bins = [...new Set(inventoryLines.map(s => s[CELL_TYPES.BIN]))];
        if (!bin && bins.length === 1) {
            this.setState({bin: bins[0]});
        }
    }

    filterVisibleLines = (currentLines) => currentLines.filter(line => {
        const partCode = (line[CELL_TYPES.PART_CODE] || '').toUpperCase();
        const assetCode = (line[CELL_TYPES.ASSET_CODE] || '').toUpperCase();
        const scannedCode = this.state.code.toUpperCase().trim();
        return partCode.startsWith(scannedCode) || assetCode === scannedCode;
    })

    renderPage() {
        const { handleError, showSuccess, storeCode, openConfirmDialog } = this.props;
        const { inventory, inventoryLines, statusTransitions, error, loading, bin, code } = this.state;

        const updateLine = (pk, line) => {
            this.setState.bind(this)(prevState => ({
                inventoryLines: prevState.inventoryLines.map(l => l[CELL_TYPES.PK] === pk ? {...l, ...line} : l)
            }))
        }

        //Include lines filtered
        const currentLines = getCurrentLines(inventoryLines, bin)

        const setLoading = loading => this.setState.bind(this)({loading})

        // const reload = _ => {
        //     setLoading(true);
        //     WSInventory.readInventoryAllLines(inventory.code)
        //         .then(resp => this.setState({inventoryLines: resp.body.data}))
        //         .catch(handleError)
        //         .finally(_ => setLoading(false))
        // }
        const reload = this.reload.bind(this);

        const clearScannedField = _ => this.setState({code: '', lot: ''})

        const notFilledLines = currentLines.filter(line => ["", undefined, null].includes(line[CELL_TYPES.PHYQTY]) && (line[CELL_TYPES.PART_CODE]  || line[CELL_TYPES.ASSET_CODE] ));

        const submitItemScannedHandler = handleItemScanned({code, inventoryLines, updateLine, bin, inventory, setLoading, reload, handleError, clearScannedField})

        return error ?
            <div>Error loading data.</div>
            : <Container maxWidth="lg">
            <BlockUi style={{height: '100%'}} blocking={loading}>
                {/* <DetailsPanel
                    overlay={error}
                    title={'Inventory'}
                    list={!inventory ? [] : inventoryFields(inventory, this.setState.bind(this), statusTransitions)}/> */}
                <section style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    margin: '24px 0px',
                    borderBottom: '1px solid #dcdcdc',
                 }}>
                    <section style={{ paddingBottom: '24px' }}>
                        <Typography variant="h6" component="span">{"Inventory "}</Typography>
                        {inventory.code &&
                            <Typography variant="h6" component="span" style={{
                                padding: '4px',
                                border: '1px solid',
                                color: '#006598',
                                borderRadius: '4px',
                                fontWeight: '500',
                            }}>
                                {inventory.code}
                            </Typography>
                        }
                        <Typography variant="subtitle1" component="p" color="textSecondary">{inventory.desc}</Typography>
                        {statusTransitions &&
                            <div style={{ display: 'flex', direction: 'row', maxWidth: '300px', alignItems: 'center' }}>
                                <span style={{ color: '#006598', fontWeight: '500', paddingRight: 12 }}>Status</span>
                                <Select
                                    value={inventory.status}
                                    onChange={evt => this.setState(prevState => ({
                                        inventory: {
                                            ...prevState.inventory,
                                            status: evt.target.value
                                    }}))}>
                                    {statusTransitions.map(st => <MenuItem value={st.code} key={'statusselect@' + st.code}>{`${st.code}: ${st.desc}`}</MenuItem>)}
                                </Select>
                            </div>
                        }
                    </section>
                    <section style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', paddingBottom: '24px' }}>
                        <Button
                            style={{ margin: 5, marginLeft: 0}}
                            size="large"
                            color="primary"
                            variant="contained"
                            onClick={_ => finish({inventory, inventoryLines, showSuccess, handleError, setLoading, reload})}>
                            Save
                        </Button>
                        <Button
                            style={{ margin: 5 }}
                            size="large"
                            color="info"
                            variant="contained"
                            onClick={_ => {
                                setLoading(true);
                                WSInventory.exportInventoryAllLines(inventory.code)
                                .then(resp => {
                                    setLoading(false);
                                    const date = new Date();
                                    const hiddenElement = document.createElement("a");
                                    // utf8BOM used to enable detection of utf-8 encoding by excel when opening the CSV file
                                    const utf8BOM = "\uFEFF";
                                    hiddenElement.href = "data:text/csv;charset=UTF-8," + `${utf8BOM}${encodeURIComponent(resp.body)}`;
                                    hiddenElement.target = "_blank";
                                    hiddenElement.download = `InventoryLines_${inventory.code}_${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}.csv`;
                                    hiddenElement.click();
                                })
                                .catch(err => {})
                            }}>
                            EXPORT TO CSV
                        </Button>
                    </section>
                </section>
                <div style={{
                    maxWidth: '500px',
                    margin: '24px auto',
                    background: '#eee',
                    padding: '16px',
                    borderRadius: '8px',
                }}>
                    <EAMSelect
                        label="Bin"
                        onChange={createOnChangeHandlerObjectUpdate(
                            'bin', null, null, this.setState.bind(this), null
                        )}
                        value={bin}
                        options={[...new Set(inventoryLines.map(s => s[CELL_TYPES.BIN]))].map(s => ({code: s, desc: s}))}
                        // autoFocus // TODO: needs update of inputs-ng
                    />
                    {bin &&
                        <React.Fragment>
                            <EAMTextField
                                label="Scan Item"
                                onChange={createOnChangeHandlerObjectUpdate(
                                    'code', null, null, this.setState.bind(this), null
                                )}
                                value={code}
                                // autoFocus // TODO: needs update of inputs-ng
                                // TODO: needs update of inputs-ng
                                // onKeyDown={(e) =>
                                //     e.keyCode === 27 // escape
                                //     ? clearScannedField()
                                //     : e.keyCode === 13 // enter
                                //     ? submitItemScannedHandler()
                                //     : null
                                // }
                                barcodeScanner
                            />
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                        {!this.state.code &&
                        <Button
                        color="primary"
                        variant="contained"
                        size="large"
                        onClick={
                            _ => openConfirmDialog({
                                title: "Bin inventory completed",
                                message: <div>
                                            <span>{`The following assets/parts will be marked as not found in the bin ${bin}:`}<br/></span>
                                            <span>{notFilledLines.map(line => line[CELL_TYPES.ASSET_CODE] || line[CELL_TYPES.PART_CODE] + " - " + line[CELL_TYPES.LOT]).join(', ')}</span>
                                        </div>,
                                        waitForCompletion: true
                                    },
                                    _ => {
                                        if (notFilledLines.length === 0) {
                                            clearScannedField();
                                            return;
                                        }
                                        setLoading(true);
                                        const promises = notFilledLines
                                        .map(line => {
                                            const updatedLine = {
                                                ...line,
                                                [CELL_TYPES.PHYQTY]: "0",
                                            }
                                            // updateLine(line.pk, line)
                                            if (line[CELL_TYPES.ASSET_CODE]) {
                                                return updateInventoryAssetLine(line, updateLine)(updatedLine)()
                                                .catch(handleError)
                                                .finally( _ => {
                                                    clearScannedField();
                                                    setLoading(false)
                                                });
                                            } else {
                                                return updateInventoryPartLine(line, updateLine)(updatedLine)()
                                                .catch(handleError)
                                                .finally( _ => {
                                                    clearScannedField();
                                                    setLoading(false)
                                                });
                                            }
                                        });
                                        Promise.all(promises)
                                        .then(_ => {
                                            setLoading(false);
                                            this.setState({code: '', bin: ''})
                                            reload();
                                        })
                                        .catch(handleError)
                                    }
                                        )
                                    }
                                    >
                            Next
                        </Button>
                        }
                        {this.state.code &&
                            <section style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", flex: "1" }}>
                                <IconButton
                                    id="clear-btn"
                                    fontSize="large"
                                    onClick={clearScannedField}>
                                    <ClearIcon/>
                                </IconButton>
                                <IconButton
                                    id="done-btn"
                                    color="primary"
                                    fontSize="large"
                                    onClick={submitItemScannedHandler}>
                                    <DoneIcon/>
                                </IconButton>
                            </section>
                        }
                        </div>
                        </React.Fragment>
                    }
                </div>
                <EISTable
                data={dataRenderer(this.filterVisibleLines(currentLines), updateLine, storeCode)}
                    headers={tableData.map(el => el.header)}
                    propCodes={tableData.map(el => el.code)}
                style={{borderStyle: 'solid'}}
                />
                </BlockUi>
                </Container>
            }
}

export default Containerize(withStyles(styles)(InventoryPerform));
