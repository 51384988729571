export const ADD_TRANSACTION = 'ADD_TRANSACTION';
export const ADD_LINE = 'ADD_LINE';
export const REMOVE_LINE = 'REMOVE_LINE';
export const UPDATE_TRANSACTION = 'UPDATE_TRANSACTION';
export const RESET_TRANSACTION = 'RESET_TRANSACTION';
export const UPDATE_WORKORDER = 'UPDATE_WORKORDER';
export const UPDATE_ACTIVITY = 'UPDATE_ACTIVITY';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const UPDATE_BUDGET = 'UPDATE_BUDGET';
export const UPDATE_EQUIPMENT = 'UPDATE_EQUIPMENT';
export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE';
export const UPDATE_STORE = 'UPDATE_STORE';
export const UPDATE_TRANSACTION_TYPE = 'UPDATE_TRANSACTION_TYPE';
export const UPDATE_DEPARTMENT_CODE = 'UPDATE_DEPARTMENT_CODE';
export const UPDATE_LINE = 'UPDATE_LINE';
export const SET_TRANSACTION_ON_WORKORDER = 'SET_TRANSACTION_ON_WORKORDER';
export const SET_TRANSACTION_ON_PROJECT = 'SET_TRANSACTION_ON_PROJECT';
export const SET_TRANSACTION_ON_EQUIPMENT = 'SET_TRANSACTION_ON_EQUIPMENT';
export const SET_TRANSACTION_ON_EMPLOYEE = 'SET_TRANSACTION_ON_EMPLOYEE';
export const RESET_TRANSACTION_ON = 'RESET_TRANSACTION_ON';

export function updateWorkOrder(code, desc) {
    return {
        type: UPDATE_WORKORDER,
        pair: {code, desc}
    }
}

export function updateActivity(code, desc) {
    return {
        type: UPDATE_ACTIVITY,
        pair: {code, desc}
    }
}

export function updateProject(code, desc) {
    return {
        type: UPDATE_PROJECT,
        pair: {code, desc}
    }
}

export function updateBudget(code, desc) {
    return {
        type: UPDATE_BUDGET,
        pair: {code, desc}
    }
}

export function updateEquipment(code, desc) {
    return {
        type: UPDATE_EQUIPMENT,
        pair: {code, desc}
    }
}

export function updateEmployee(code, desc) {
    return {
        type: UPDATE_EMPLOYEE,
        pair: {code, desc}
    }
}

export function updateStore(code, desc) {
    return {
        type: UPDATE_STORE,
        pair: {code, desc}
    }
}

export function updateTransactionType(transactionType) {
    return {
        type: UPDATE_TRANSACTION_TYPE,
        value: transactionType
    }
}

export function updateDepartmentCode(departmentCode) {
    return {
        type: UPDATE_DEPARTMENT_CODE,
        value: departmentCode
    }
}

export function setTransactionOnWorkorder() {
    return {
        type: SET_TRANSACTION_ON_WORKORDER
    }
}

export function setTransactionOnProject() {
    return {
        type: SET_TRANSACTION_ON_PROJECT
    }
}

export function setTransactionOnEquipment() {
    return {
        type: SET_TRANSACTION_ON_EQUIPMENT
    }
}

export function setTransactionOnEmployee() {
    return {
        type: SET_TRANSACTION_ON_EMPLOYEE
    }
}

export function resetTransactionOn() {
    return {
        type: RESET_TRANSACTION_ON
    }
}

export function addLineToCurrentTransaction(line) {
    return {
        type: ADD_LINE,
        value: [line]
    }
}

export function addLinesToCurrentTransaction(lines) {
    return {
        type: ADD_LINE,
        value: lines
    }
}

export function removeLineFromCurrentTransaction(index) {
    return {
        type: REMOVE_LINE,
        value: index
    }
}

export function updateLineInTransaction(index, line) {
    return {
        type: UPDATE_LINE,
        value: {
            index,
            line
        }
    }
}

export function addTransaction(lines, transactionType) {
    return {
        type: ADD_TRANSACTION,
        value: {
            lines,
            transactionType
        }
    }
}

export function updateTransaction(value) {
    return {
        type: UPDATE_TRANSACTION,
        value
    }
}

export function resetTransaction() {
    return {
        type: RESET_TRANSACTION
    }
}

