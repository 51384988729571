import EAMAutocomplete from 'eam-components/dist/ui/components/inputs-ng/EAMAutocomplete';
import EAMSelect from 'eam-components/dist/ui/components/inputs-ng/EAMSelect';
import Minus from 'mdi-material-ui/Minus';
import Plus from 'mdi-material-ui/Plus';
import React from 'react';
import 'react-select/dist/react-select.css';
import TransactionTypes from '../../../../enums/TransactionTypes';
import WS from '../../../../tools/rest/WSPartSelection';
import Tools from '../../../../tools/Tools';
import KioskButton from '../../../components/buttons/KioskButton';
import { createOnChangeHandler } from 'eam-components/dist/ui/components/inputs-ng/tools/input-tools';
import EAMTextField from 'eam-components/dist/ui/components/inputs-ng/EAMTextField';

const ASSET = "ASSET";
const PART = "PART";
const BIN = "BIN";
const LOT = "LOT";
const QUANTITY = "QUANTIITY";
const TRACKED_BY_ASSET = "TRACKED_BY_ASSET";

const ADD_TO_LIST_BUTTON = "addToListButton";

class SelectionPartsDetails extends React.Component {

    plusMinusIconStyle = {
        color: "#2196f3",
        cursor: "pointer",
        fontSize: 40,
        position: "relative",
        top: "13px"
    };

    plusMinusDisabledStyle = {
        ...this.plusMinusIconStyle,
        cursor: "auto",
        opacity: 0.5
    };

    state = {};

    componentDidMount = () => {
        document.getElementsByTagName('input')[0].focus();
    };

    prepareAutocompleteInputPart = (code, config, forceClear) => {
        const directLink = code === Tools.getURLParameterByName("partCode");
        const exact = directLink || forceClear;
        exact && this.props.setLoading(true);
        return WS.autocompleteParts(code, this.props.currentStore.code, this.props.currentTransaction.transactionType, null, config)
            .then(response => {
                const data = response.body.data;
                const obj = data.find(d => d.code === code);
                if (exact) {
                    if (obj) {
                        this.props.updateProperty("entityType", obj.entityType);
                        this.props.updateProperty("partCode", code);
                        this.props.updateProperty("partDesc", obj.desc);
                    } else {
                        this.props.showWarning(`Part ${code} not found in this store `);
                        this.props.updateProperty("partCode", "");
                        this.props.setLoading(false);
                    }
                    return [];
                }
                return response;
            })
            ;
    };

    prepareAutocompleteInputAsset = (code, config) => {
        return WS.autocompleteAsset(code, this.props.partCode, this.props.currentStore.code, this.props.currentTransaction.transactionType, this.props.bin, this.props.lot, config);
    };

    handlePlusClick = () => {
        this.props.updateProperty("quantity", +this.props.quantity + 1)
    };

    handleMinusClick = () => {
        this.props.updateProperty("quantity", +this.props.quantity - 1)
    };

    validateQuantity = (quantity) => {
        const quantityToValidate = +quantity;
        const { availableQuantity } = this.props;
        const availableQuantityNumber = +availableQuantity;

        if (quantityToValidate > availableQuantityNumber) {
            this.props.showWarning("Available quantity is " + availableQuantityNumber + ".");
            this.props.updateProperty("quantity", availableQuantityNumber);
            return false;
        } else {
            if (quantityToValidate <= 0) {
                this.props.updateProperty("quantity", "0");
                this.props.showWarning("Quantity must be greater than 1.");
                return false;
            }
        }

        return true;
    };

    updatePropertyAndHandleFocus = (key, value) => {
        this.props.updateProperty(key, value);
    };

    updatePartCode = (key, value) => {
        if (key === 'partCode') {
            value ? this.prepareAutocompleteInputPart(value, null, true)
                :  this.props.updateProperty(key, '');
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        /**
         * Tells the focusing function what was changed
         */
        if (prevProps.partCode !== this.props.partCode) {
            if (window.innerWidth >= 540) {
                this.focusOnRightThing(PART);
            }
        }

        else if (prevProps.assetCode !== this.props.assetCode) {
            this.focusOnRightThing(ASSET);
            //this.handleBinMapping('assetCode', this.props.assetCode);
        }

        else if (prevProps.lot !== this.props.lot) {
            this.focusOnRightThing(LOT);
        }

        else if (prevProps.bin !== this.props.bin) {
            this.focusOnRightThing(BIN);
        }

        else if (prevProps.quantity !== this.props.quantity) {
            this.focusOnRightThing(QUANTITY);
        }

        else if (prevProps.isTrackedByAsset !== this.props.isTrackedByAsset) {
            this.focusOnRightThing(TRACKED_BY_ASSET);
        }

        const { isEditMode, partCode, updateProperty } = this.props;
        if (isEditMode) {
            const selectedTransactionLine = this.props.currentTransaction.transactionlines[this.props.selectedRowIndexes[0]];
            const futureState = {
                partCode: selectedTransactionLine.partCode,
                bin: selectedTransactionLine.bin,
                lot: selectedTransactionLine.lot,
                quantity: selectedTransactionLine.transactionQty,
            };
            if (partCode !== selectedTransactionLine.partCode) {
                updateProperty("partCode", selectedTransactionLine.partCode, futureState);
                updateProperty("bin", selectedTransactionLine.bin, futureState);
                updateProperty("lot", selectedTransactionLine.lot, futureState);
                updateProperty("quantity", selectedTransactionLine.transactionQty, futureState);
            }
        }
    };

    /**
     * Handles the focusing of different fields during the workflow.
     */
    // NOTE: inputs-ng do not currently support passing a ref from a class
    // component to the focusable input, hence the commented out code.
    focusOnRightThing = (whatChanged) => {
        // if (whatChanged === PART) {
        //     // if there is no part, always focus on the part
        //     if (!this.props.partCode) {
        //         if (this.autocompleteParts) {
        //             this.autocompleteParts.focus();
        //         }
        //     }

        //     // if in the main autocomplete we have a part tracked by asset, focus on the
        //     // autocomplete for assets
        //     if (this.props.partCode && this.props.isTrackedByAsset && this.props.entityType === "PART") {
        //         if (this.autocompleteAsset) {
        //             this.autocompleteAsset.focus();
        //         }
        //     }

        //     if (this.binSelect && this.props.entityType === "PART") {
        //         this.binSelect.focus();
        //     }

        //     // if in the main autocomplete an asset was entered, go staright to the
        //     // add to list button
        //     if (this.props.entityType === "ASSET" && this.props.partCode) {
        //         if (this.props.currentTransaction.transactionType === TransactionTypes.ISSUE) {
        //             this.focusOnAddButton();
        //         }
        //         else {
        //             if (this.binSelect) {
        //                 this.binSelect.focus();
        //             }
        //         }
        //     }
        // }

        // if (whatChanged === BIN) {
        //     // after bin, go to lot
        //     if (this.props.bin && this.lotSelect) {
        //         this.lotSelect.focus();
        //     }
        // }

        // if (whatChanged === LOT) {
        //     if (this.props.lot) {
        //         this.focusOnAddButton();
        //     }
        // }

        // if (whatChanged === ASSET) {
        //     if (this.props.assetCode) {
        //         if (this.props.currentTransaction.transactionType === TransactionTypes.RETURN) {
        //             if (!this.props.bin) {
        //                 if (this.binSelect) {
        //                     this.binSelect.focus();
        //                 }
        //             }

        //             if (this.props.bin && !this.props.lot) {
        //                 if (this.lotSelect) {
        //                     this.lotSelect.focus();
        //                 }
        //             }

        //             if (this.props.bin && this.props.lot) {
        //                 this.focusOnAddButton();
        //             }
        //         }

        //         else if (this.props.currentTransaction.transactionType === TransactionTypes.ISSUE) {
        //                 this.focusOnAddButton();
        //         }
        //     }

        //     if (!this.props.assetCode) {
        //         if (this.autocompleteAsset) {
        //             this.autocompleteAsset.focus();
        //         }
        //     }
        // }

        // if (whatChanged === TRACKED_BY_ASSET) {
        //     if (this.props.partCode && this.props.isTrackedByAsset && this.autocompleteAsset) {
        //         this.autocompleteAsset.focus();
        //     }
        // }
    };

    focusOnAddButton = () => {
        document.getElementById(ADD_TO_LIST_BUTTON).focus();
    };

    render() {
        let {
            partCode, partDesc, bin, bins, lot, lots, quantity, assetCode, assetDesc, updateProperty, isTrackedByAsset, isSimple,
            isPartSelected, isAssetSelected, currentTransaction, availableQuantity, isEditMode, departmentalSecurity, assetDepartment,
            assetGroupResponsibleEmails, ...other
        } = this.props;

        let isBinDisabled = !partCode || (isAssetSelected && currentTransaction.transactionType === TransactionTypes.ISSUE) ||
            (currentTransaction.transactionType === TransactionTypes.ISSUE && partCode && assetCode);
        isBinDisabled = !!isBinDisabled;

        let isLotDisabled = !bin || isBinDisabled || (partCode && assetCode && currentTransaction.transactionType === TransactionTypes.ISSUE);
        isLotDisabled = !!isLotDisabled;
        if (!lot) {
            isLotDisabled = false;
        }

        const isPlusEnabled = ((!isLotDisabled && !isAssetSelected) && ((+(quantity + 1) <= +availableQuantity) || this.props.currentTransaction.transactionType === TransactionTypes.RETURN));
        const isMinusEnabled = (!isLotDisabled && !isAssetSelected) && (+quantity > 0);

        const readOnly = departmentalSecurity[assetDepartment] && departmentalSecurity[assetDepartment].readOnly;

        return (
            <React.Fragment>
                <div style={{width: "40%", minWidth: 250}}>
                    <EAMAutocomplete
                        label="Part or Asset"
                        value={partCode}
                        desc={partDesc}
                        onChange={createOnChangeHandler(
                            'partCode', 'partDesc', null, this.updatePartCode, null
                        )}
                        autocompleteHandler={this.prepareAutocompleteInputPart}
                        // autoFocus // TODO: needs update of inputs-ng
                        // refToReactSelect={ref => this.autocompleteParts = ref} // TODO: needs update of inputs-ng
                        disabled={isEditMode} // TODO: check
                        // autoSelectSingleElement// TODO: needs update of inputs-ng
                        barcodeScanner
                    />
                    <div style={{paddingBottom: 15}}>
                        {isPartSelected && (
                            "(Part)"
                        )}
                        {isAssetSelected && (
                            "(Asset)"
                        )}
                    </div>
                </div>
                {!isSimple && (
                    <React.Fragment>
                        {(isPartSelected || isAssetSelected) && (
                            <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
                                <div style={{width: "40%", minWidth: 250, marginRight: 50}}>
                                    {/* NOTE: given blockUI's re-focus behavior, the dropdown re-opens after picking an option*/}
                                    <EAMSelect
                                        label="Bin"
                                        onChange={createOnChangeHandler(
                                            'bin', null, null, updateProperty, null
                                        )}
                                        value={bin}
                                        renderValue={value => value.desc || value.code}
                                        options={bins}
                                        disabled={isBinDisabled}
                                        // refToReactSelect={ref => this.binSelect = ref} // TODO: needs update of inputs-ng
                                        barcodeScanner
                                    />
                                </div>
                                <div style={{width: "40%", minWidth: 250}}>
                                    {/* NOTE: given blockUI's re-focus behavior, the dropdown re-opens after picking an option*/}
                                    <EAMSelect
                                        label="Lot"
                                        onChange={createOnChangeHandler(
                                            'lot', null, null, updateProperty, null
                                        )}
                                        value={lot}
                                        options={lots}
                                        renderValue={value => value.desc || value.code}
                                        disabled={isLotDisabled}
                                        // refToReactSelect={ref => this.lotSelect = ref} // TODO: needs update of inputs-ng
                                        barcodeScanner
                                    />
                                </div>
                            </div>
                        )}
                        {(isPartSelected || isAssetSelected) && (
                            <div style={{width: "20%", minWidth: 245, marginRight: 50}}>
                                <div style={{display: "flex", minWidth: '230px', alignItems: "center"}}>
                                    <EAMTextField
                                        label={"Quantity" + (availableQuantity ? " (available " + availableQuantity + ")" : " ")}
                                        onChange={createOnChangeHandler(
                                            'quantity', null, null, updateProperty, null
                                        )}
                                        validator={this.validateQuantity}
                                        value={quantity}
                                        disabled={!!isLotDisabled || isAssetSelected}
                                        // type="number" // TODO: needs update of inputs-ng
                                        endTextAdornment={this.props.partUom}
                                    />
                                    <Plus style={isPlusEnabled ? this.plusMinusIconStyle : this.plusMinusDisabledStyle}
                                          onClick={isPlusEnabled ? this.handlePlusClick : null}/>
                                    <Minus
                                        style={isMinusEnabled ? this.plusMinusIconStyle : this.plusMinusDisabledStyle}
                                        onClick={isMinusEnabled ? this.handleMinusClick : null}/>
                                </div>
                            </div>
                        )}
                        {(isTrackedByAsset && isPartSelected) && (
                            <div style={{width: "40%", minWidth: 250}}>
                                <EAMAutocomplete
                                    label="Asset"
                                    value={assetCode}
                                    desc={assetDesc}
                                    onChange={createOnChangeHandler(
                                        'assetCode', 'assetDesc', null, this.updatePropertyAndHandleFocus, null
                                    )}
                                    autocompleteHandler={this.prepareAutocompleteInputAsset}
                                    // refToReactSelect={ref => this.autocompleteAsset = ref} // TODO: needs update of inputs-ng
                                    // autoSelectSingleElement // TODO: needs update of inputs-ng
                                    barcodeScanner
                                />
                            </div>
                        )}
                    </React.Fragment>
                )}
                {readOnly && <div style={{color: 'red', fontWeight: 'bold'}}>
                    <p>You have no write access to this asset, so you are unable to make an issue/return transaction on it.</p>
                    <p>Please contact the following local administrator responsible for the service unit {assetDepartment}: {assetGroupResponsibleEmails}.</p>
                </div>}
                <div style={{display: 'flex', justifyContent: 'center', flexGrow: 1}}>
                    <KioskButton
                        style={{margin: '15px'}}
                        onClick={isEditMode ? this.props.updateItem : this.props.addToList}
                        id={ADD_TO_LIST_BUTTON}
                        disabled={readOnly}
                    >
                        {isEditMode ? "Update" : "Add to list"}
                    </KioskButton>
                </div>
            </React.Fragment>
        )
    }
}

export default SelectionPartsDetails;