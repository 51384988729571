import React from 'react';
import EAMAutocomplete from 'eam-components/dist/ui/components/inputs-ng/EAMAutocomplete';
import EAMSelect from 'eam-components/dist/ui/components/inputs-ng/EAMSelect';
import WS from '../../../../tools/rest/WS';
import { createOnChangeHandlerObjectUpdate } from 'eam-components/dist/ui/components/inputs-ng/tools/input-tools';

const CODE_KEY = 'projectCode';
const DESC_KEY = 'projectDesc';

class ContextSelectionProject extends React.Component {

    state = {};

    componentWillMount() {
        let project = this.props.currentTransaction.projectCode
        if (project) {
            this.readBudgets(project)
        }
    }

    componentWillReceiveProps(newProps) {
        let newproject = newProps.currentTransaction.projectCode
        let oldproject = this.props.currentTransaction.projectCode
        // Clean up budgets when new project is not there
        if (!newproject) {
            this.setState({budgets: null})
        }

        // Read budgets only when the project has changed and is defined
        if (newproject && (newproject !== oldproject)) {
            this.readBudgets(newproject)
        }
    }


    readBudgets = (project) => {
        WS.readProjectBudgets(project)
            .then(response => {
                let budgets = response.body.data;
                this.setState({budgets})
                // If the Project has only one budget set it
                if (budgets.length === 1) {
                    this.props.updateTransaction({budgetCode: budgets[0].code})
                }
            })
    };

    render() {
        const { currentTransaction, updateTransaction } = this.props;
        const {projectCode, projectDesc, budgetCode} = currentTransaction;

        return (
            <div>
                <EAMAutocomplete
                    label="Project"
                    value={projectCode}
                    desc={projectDesc}
                    onChange={createOnChangeHandlerObjectUpdate(
                        CODE_KEY, DESC_KEY, null, updateTransaction, null
                    )}
                    autocompleteHandler={(key) => WS.autocompleteProject(key)}
                    // autoFocus // TODO: needs update of inputs-ng
                    // autoSelectSingleElement // TODO: needs update of inputs-ng
                    barcodeScanner
                />

                {this.state.budgets && this.state.budgets.length > 0 &&
                    <React.Fragment>
                        <EAMSelect
                            label="Budget"
                            value={budgetCode}
                            onChange={createOnChangeHandlerObjectUpdate(
                                'budgetCode', null, null, updateTransaction, null
                            )}
                            options={this.state.budgets}
                        />
                    </React.Fragment>
                }
            </div>
        )}
}

export default ContextSelectionProject;