import {SET_SNACKBAR_MESSAGE, SET_CONFIRM_DIALOG} from "../actions/uiActions";

export default function ui(state = {snackbar: {message: "", type: "", open: false}}, action) {
    switch(action.type) {
        case SET_SNACKBAR_MESSAGE:
            return  {
                ...state,
                snackbar: action.snackbar
            };
        case SET_CONFIRM_DIALOG:
            return  {
                ...state,
                confirmDialog: {
                    ...state.confirmDialog,
                    ...action.confirmDialog
                }
            };
        default:
            return state
    }
}