import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import { format } from 'date-fns';
import Forklift from 'mdi-material-ui/Forklift';
import PackageVariantClosed from 'mdi-material-ui/PackageVariantClosed';
import Radioactive from 'mdi-material-ui/Radioactive';
import React from 'react';
import BlockUi from 'react-block-ui';
import EISPanel from '../../../../react-eis-components/ui/components/panel';
import EISTable from '../../../../react-eis-components/ui/components/table';
import WSFlexBuilding from '../../../../tools/rest/WSFlexBuilding';
import KioskButton from '../../../components/buttons/KioskButton';
import BinLotQty, { qtyHandled } from '../../../components/elements/BinLotQty';

class OutboundHandlePickTicket extends React.Component {
    state = {
        pickTicket: {},
        selectedItemCode: '',
        selectedItemDesc: '',
        transLines: []
    };

    componentDidMount() {
        if (this.props.outboundProcess && this.props.outboundProcess.pickTicket && !this.state.loaded) {
            this.setState({loaded: true, pickTicket: this.props.outboundProcess.pickTicket})
        }
    }

    componentDidUpdate() {
        if (this.props.outboundProcess && this.props.outboundProcess.pickTicket && !this.state.loaded) {
            this.setState({loaded: true, pickTicket: this.props.outboundProcess.pickTicket})
        }
    }

    getRequiredData = (lines) =>
        lines.map(line => ({
            ...line,
            objCode: line.objCode && <a target={'_blank'} href={`${this.props.applicationData.assetUrl}${line.objCode}`}>{line.objCode}</a>,
            parCode: line.parCode && <a target={'_blank'} href={`${this.props.applicationData.partUrl}${line.parCode}`}>{line.parCode}</a>,
        })
    )

    isItemHandled = (item, transLines) => qtyHandled(item.objCode || item.parCode, transLines) === +item.qtyRequired

    partQtyHandled = (item, transLines) => transLines
        .filter(it => it.parCode === item.parCode)
        .map(it => +it.qtyRequired || 0)
        .reduce((sum, n) => n + sum, 0)

    selectItem = (item, transLines) => ({
        handle: !this.isItemHandled(item, transLines) &&  this.props.canAccess
            && (<Button
                onClick={_ => this.setState({selectedItemData: item, selectedItemCode: item.objCode || item.parCode, selectedItemDesc: item.itemDesc, selectedItemQtyRequired: item.qtyRequired })}
                style={{
                    boxShadow: '0 3px 5px 2px rgba(128, 128, 128, .20)',
                    variant: "contained",
                    backgroundColor: '#2196F3',
                    color: 'white',
                    padding: 0,
                    size: 'big'
                }}>
                <Forklift />
            </Button>),
        ...item
    });

    squareStyle = size => ({
        maxWidth: size + 'px',
        minWidth: size + 'px',
        minHeight: size + 'px',
        maxHeight: size + 'px'
    })

    requestRPMeasurement = item => {
        return {
        action: (<React.Fragment>
            {item.rpClassification === 'Radioactive' ? this.getRadioactiveInfoButton(item) : ''}
            {item.packaging === 'IN PACKAGE' ? this.getPackagedInfoButton(item) : ''}
        </React.Fragment>),
        ...item}
    };

    addHazardsList = line => ({
        ...line,
        hazardsList: this.props.outboundProcess.pickTicket?.items?.[line.objCode || line.assetCode]
            ?.hazardsList?.split(',')
                .filter(s => s)
                .map(haz => this.props.applicationData.hazardsList.find(h => h.code === haz)?.desc ?? haz)
                .join(', '),
    })

    getPackagedInfoButton = item =>
        (<Button
            style={{
                boxShadow: '0 3px 5px 2px rgba(128, 128, 128, .20)',
                variant: "contained",
                borderRadius: '50%',
                backgroundColor: 'rgb(66, 134, 244, 0.8)',
                color: 'white',
                padding: 0,
                size: 'small',
                ...this.squareStyle(40)
            }}>
            <PackageVariantClosed style={{size: '20px'}} onClick={() => this.getPackagedInfoDialog(item)}>
            </PackageVariantClosed>
        </Button>)

    getRadioactiveInfoButton = item =>
        (<Button
            style={{
                boxShadow: '0 3px 5px 2px rgba(128, 128, 128, .20)',
                variant: "contained",
                borderRadius: '50%',
                backgroundColor: 'rgba(255, 168, 38, 1)',
                color: 'white',
                padding: 0,
                size: 'small',
                ...this.squareStyle(40)
            }}>
            <Radioactive style={{size: '20px'}} onClick={() => this.getRadioactiveInfoDialog(item)}>
            </Radioactive>
        </Button>)

    getPackagedInfoDialog = item => {
        this.props.openConfirmDialog({
            title: "Packaged asset",
            message: `The asset ${item.objCode} is packaged. If the asset is issued, it will be detached from the parent asset.`,
            waitForCompletion: true
        },
        _ => 1
        );
    }

    getRadioactiveInfoDialog = asset => {
        this.props.openConfirmDialog({
            title: "Radioactive asset",
            message: `The asset ${asset.objCode} is radioactive. An RPMeasurement request will automatically be generated.`,
            //cancelButtonLabel: "Ok",
            //confirmButtonLabel: "Yes",
            waitForCompletion: true
        },
        _ => 1
        );
    }

    panelChange = (expanded, listKey) =>  {
        const list = this.state[listKey];
        list.expanded = expanded;
        this.setState({[listKey]: list})
    }

    rejectPickTicket = pickTicketCode => {
        WSFlexBuilding.rejectPickTicket(pickTicketCode)
            .then(resp => { this.props.showSuccess(`Pick Ticket ${pickTicketCode} rejected.`); this.props.history.push(this.props.routes.STORE_MAIN_PAGE + `/outbound/`)})
            .catch(error => error ? this.props.handleError(error) : this.props.showError('Could not execute operation'))
            ;
    }

    convertTransLinesToPickTicket =  (line) => {
        return {
            ...line.other,
            bin: line.bin,
            lot: line.lot,
            qtyRequired: line.quantity
        }
    }

    handleNext = pickTicket => {
        const { handleError, showError, showSuccess } = this.props;
        const { transLines } = this.state;

        const newTransLines = transLines.map(this.convertTransLinesToPickTicket)

        this.setState({loading: true}, _ =>
            WSFlexBuilding.handlePickTicket(pickTicket.code, {...pickTicket, transLines: newTransLines})
            .then(resp => {
                this.setState({loading: false, commited: true, edhDocumentNumbers: resp.body.data.edhDocumentNumbers, rpmRequestCodes: resp.body.data.rpmRequestCodes})
                showSuccess(this.getFormattedMessage(resp.body.data.rpmRequestCodes, resp.body.data.edhDocumentNumbers));
                window.location.reload();
            })
            .catch(error => {
                error ? handleError(error) : showError('Could not execute operation');
                this.setState({loading: false})
            })
        );
    }

    getFormattedMessage = (rpmRequestCodes, edhDocumentNumbers) => 'All operations were successful';

    // If the user collapses the panel and the panel is updated, should it expand? Currently, no
    isPanelExpanded = list => {
        return list && list.length > 0 && (list.expanded === undefined || list.expanded)
    }

    pickUpPickTicket = (pickTicketCode, items) => {
        const { pickTicket } = this.props.outboundProcess;
        this.setState({loading: true});
        WSFlexBuilding.pickupPickTicket(pickTicketCode, pickTicket)
            .then(resp => {
                this.props.showSuccess(`Pick Ticket ${pickTicketCode} assets picked up.`);
                this.props.history.push(this.props.routes.STORE_MAIN_PAGE + `/outbound/`)
            })
            .catch(error => {
                error ? this.props.handleError(error) : this.props.showError('Could not execute operation');
                this.setState({loading: false});
            })
            ;
    }

    handleBackButtonClick = () => {
        this.props.history.goBack()
    };

    validatePartQuantityRequired = () => {
        const { partBeingHandled, newPartLine, availableQuantityForPart } = this.state;
        const qtyAllowed = +newPartLine.qtyRequired <= Math.min(+availableQuantityForPart, +partBeingHandled.qtyRequired);
        this.setState({
            newPartLine: {
                ...newPartLine,
                qtyRequired: qtyAllowed ? +newPartLine.qtyRequired : ""
            }
        })
    }

    getHandledData = (transLines) =>
        transLines.map(line => ({
            ...line,
            //objCode: line.objCode && <a target={'_blank'} href={`${this.props.applicationData.assetUrl}${line.objCode}`}>{line.objCode}</a>,
            edhDocOutbound: line.edhDocOutbound && <a target={'_blank'} href={`${this.props.applicationData.edhDocumentLink}${line.edhDocOutbound}`}>{line.edhDocOutbound}</a>,
            rpMeasurement: line.rpMeasurement && <a target={'_blank'} href={`${this.props.applicationData.trecRpmUrl}${line.rpMeasurement}`}>{line.rpMeasurement}</a>
        }))

    render() {
        //Validate that there is a current store
        if (!this.props.currentStore || !this.state.pickTicket) return <div />;

        const { canAccess, currentStore, handleError, awaitingForRequest } = this.props;
        const {
            pickTicket,
            loading,
            selectedItemCode,
            selectedItemDesc,
            selectedItemQtyRequired,
            selectedItemData,
            transLines
        } = this.state;

        const allHandled = pickTicket && pickTicket.lines && pickTicket.lines.every(line => this.isItemHandled(line, transLines));

        let requiredtime = null;
        try {
            requiredtime = pickTicket?.requiredDate ?
                format(new Date(pickTicket.requiredDate), "dd-MMM-yyyy")
                : null
                ;
        } catch (err) {
            console.error(err);
        }

        return (
            <BlockUi blocking={loading || awaitingForRequest} tag="div" style={{margin: 0, height: '100%', overflowX: 'hidden', overflowY: 'auto'}}>
                <div style={{display: 'flex', paddingTop: '22px'}}>
                    {/* Button area */}
                    <div style={{ flex: 1, maxHeight: '70px', overflow: 'auto', }}></div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection:'row', flexWrap: 'wrap' }}>
                            <KioskButton onClick={this.handleBackButtonClick} style={{marginLeft: 'auto', marginRight: '15px', padding: 0}}>
                                Back
                            </KioskButton>
                            {pickTicket.systemStatus === 'R' && canAccess &&
                                <KioskButton onClick={_ => this.rejectPickTicket(pickTicket.code)} style={{backgroundColor: 'rgba(230, 63, 43, 0.8)', marginLeft: 'auto', marginRight: '15px', padding: 0}}>
                                    Reject
                                </KioskButton>
                            }
                            {pickTicket.systemStatus === 'R' && canAccess &&
                                <KioskButton onClick={() => this.handleNext(pickTicket)} disabled={!allHandled} style={{marginLeft: 'auto', marginRight: '15px', padding: 0}}>
                                    Accept
                                </KioskButton>
                            }
                            {pickTicket.systemStatus === 'A' && canAccess &&
                            <KioskButton onClick={_ => this.pickUpPickTicket(pickTicket.code, pickTicket.transLines)} style={{backgroundColor: 'rgba(230, 63, 43, 0.8)', marginLeft: 'auto', marginRight: '15px', padding: 0}}>
                                Pick Up
                            </KioskButton>
                            }
                        </div>
                    </div>

                    {/* Button area */}
                    {pickTicket &&
                        <EISPanel heading={"PICK TICKET"} style={{border: '2px', marginTop: '5px'}}>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <div><b>Pick Ticket: </b>{pickTicket.code} <b>Status: </b>
                                    {
                                        pickTicket.status === 'R' ? <span style={{color: 'orange'}}>{'Pending Approval'}</span>
                                        : pickTicket.status === 'C' ? <span style={{color: 'green'}}>{'Closed'}</span>
                                        : pickTicket.status === 'J' ? <span style={{color: 'red'}}>{'Rejected'}</span>
                                        : pickTicket.status === 'A' ? <span style={{color: 'blue'}}>{'Accepted'}</span>
                                        : pickTicket.status === 'AP' ? <span style={{color: 'blue'}}>{'Awaiting Pick up'}</span>
                                        : pickTicket.status === 'AR' ? <span style={{color: 'blue'}}>{'Awaiting RP Measurement'}</span>
                                        : pickTicket.status
                                    }
                                </div>
                                <div><b>Destination:</b> {pickTicket.destCode} <b>Requested Date: </b> {requiredtime}{' '}
                                <b>EDH Document: </b>
                                    <Checkbox
                                        style={{align: 'center', padding: 0, margin: 0}}
                                        checked={pickTicket.classCode === this.props.applicationData.pickTicketTransportReqClasCode}
                                        disabled
                                    />
                                    {pickTicket.classCode === this.props.applicationData.pickTicketTransportReqClasCode
                                     && pickTicket.edhDocumentType ? <><b>Document type:</b> {pickTicket.edhDocumentType}</> : null}
                                    </div>
                                <div></div>
                                {pickTicket.assets && <div><b>Assets: </b>{pickTicket.assets} <b>({pickTicket.assets_count})</b></div>}
                                {pickTicket.parts && <div><b>Parts:</b>{pickTicket.pickTicketTransportReqClasCode}</div>}
                                <div><b>Description: </b>{pickTicket.desc}</div>
                            </div>
                        </EISPanel>
                    }

                    {/* Button area */}
                    { pickTicket.lines && <EISPanel
                                heading={"REQUIRED"}
                                style={{color: 'red', backgroundColor: 'yellow', marginTop: '5px'}}
                                panelExpanded={!selectedItemCode}
                                //onPanelChange={open => this.panelChange(open, 'toHandle')}
                            >
                            <EISTable
                                    data={this.getRequiredData(pickTicket.lines?.map(it => pickTicket.systemStatus === 'R' ? this.selectItem(it, transLines) : it).map(this.requestRPMeasurement).map(this.addHazardsList))}
                                    headers={['', 'Asset', 'Part', 'Desc', 'Bin', 'Lot', 'Qty Required', 'Qty Available', 'RP Classification', 'Hazards', 'Actions']}
                                    propCodes={['handle', 'objCode', 'parCode', 'itemDesc', 'bin', 'lot', 'qtyRequired', 'qtyAvailable', 'rpClassification', 'hazardsList', 'action']}
                            />
                        </EISPanel>
                    }

                    {selectedItemCode &&
                        <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between", width: "100%" }}>
                            <p><b>Selected Code: </b>{selectedItemCode}</p>
                        </div>
                    }
                    {pickTicket.systemStatus === 'R' && <EISPanel
                        heading={"HANDLING"}
                    >
                        <BinLotQty
                            code={selectedItemCode}
                            codeDesc={selectedItemDesc}
                            selectedItemData={selectedItemData}
                            quantityRequired={selectedItemQtyRequired}
                            storeCode={currentStore.code}
                            transLines={transLines}
                            updateTransLines={transLines => this.setState.bind(this)({transLines})}
                            clearCode={() => this.setState.bind(this)({selectedItemCode: '', selectedItemDesc: ''})}
                            handleError={handleError}
                        />
                    </EISPanel>
                    }

                    {/* Handled lines */}
                    { pickTicket.transLines && pickTicket.transLines.length > 0 && pickTicket.status !== 'J' && <EISPanel
                                heading={"HANDLED"}
                                panelExpanded={pickTicket.transLines && pickTicket.transLines.length }
                                //onPanelChange={open => this.panelChange(open, 'handled')}
                            >
                            <EISTable
                                    data={this.getHandledData(pickTicket.transLines.map(this.requestRPMeasurement)?.map(this.addHazardsList))}
                                    headers={['', 'Asset', 'Part', 'Desc', 'Bin', 'Lot', 'Qty', 'EDH', 'RPM', 'Hazards', 'Actions']}
                                    propCodes={['remove', 'objCode', 'parCode', 'itemDesc', 'bin', 'lot', 'qtyRequired', 'edhDocOutbound', 'rpMeasurement','hazardsList', 'action' ]}
                            />
                        </EISPanel>
                    }
            </BlockUi>
        );
    }
}

export default OutboundHandlePickTicket;
