import {connect} from 'react-redux';
import {setPage} from "../../../../actions/layoutActions";
import {handleError, showWarning} from '../../../../react-eis-components/actions/uiActions';
import SelectionPartsDetails from "./SelectionPartsDetails";
import {updateApplication} from "../../../../actions/applicationActions";
import { withStyles } from '@mui/styles';

const mapStateToProps = (state, ownProps) => {
    return {
        currentStore: state.application.currentStore,
        currentTransaction: state.currentTransaction,
        isSimple: state.application.currentStore && state.application.storesConfiguration[state.application.currentStore.code] ?
            (state.application.storesConfiguration[state.application.currentStore.code].simple === '+') : false,
        departmentalSecurity: state.application.userData
            && state.application.userData.eamAccount
            && state.application.userData.eamAccount.departmentalSecurity
    }
};

const SelectionPartsDetailsContainer = connect(
    mapStateToProps, {
        setPage,
        updateApplication,
        handleError,
        showWarning
    }
)(SelectionPartsDetails);

export default SelectionPartsDetailsContainer;
