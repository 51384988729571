import axios from 'axios';
import eamComponentsClient from 'eam-components/dist/tools/ajax';
import ErrorTypes from '../../enums/ErrorTypes';

import { keycloak, tokenWrapper } from '../../AuthWrapper';

const DEFAULT_TIMEOUT = 300000;

const client = axios.create({
    timeout: DEFAULT_TIMEOUT,
    validateStatus: (status) => status < 400,
});

const convertResponse = (response) => ({
    status: response.status,
    body: response.data,
});

const convertError = (error) => {
    if (!error) throw {};

    if (axios.isCancel(error)) {
        throw {
            type: ErrorTypes.REQUEST_CANCELLED,
        };
    }

    if (error.response) {
        throw {
            type: ErrorTypes.SERVER_ERROR,
            response: {
                status: error.response.status,
                body: error.response.data,
            },
        };
    }
    if (error.code === 'ECONNABORTED') {
        throw {
            type: ErrorTypes.CONNECTION_ABORDED,
        };
    }
    // Because we are behind IT-DB proxy this will be only reached when a redirect was sent (i.e. SSO session has expired)
    // TODO: should be carefully studied
    window.location.reload(true);
    throw {};
};

const onRequestFulfilledIntereceptor = (oldConfig) => {
    if (process.env.REACT_APP_LOGIN_METHOD === 'OPENID') {
        // updateToken if it will last less than 5 minutes
        return keycloak.updateToken(300).then(() => {
            const newConfig = oldConfig;
            if (tokenWrapper && tokenWrapper.tokens && tokenWrapper.tokens.token) {
                newConfig.headers.Authorization = `Bearer ${tokenWrapper.tokens.token}`;
            }
            return newConfig;
        });
    }
    return oldConfig;
};

const onRequestRejectedInterceptor = (error) => Promise.reject(error);

eamComponentsClient
    .getAxiosInstance()
    .interceptors.request.use(onRequestFulfilledIntereceptor, onRequestRejectedInterceptor);

client.interceptors.request.use(onRequestFulfilledIntereceptor, onRequestRejectedInterceptor);

client.interceptors.response.use(convertResponse, convertError);

export default client;
