import { LinearProgress } from '@mui/material';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import Keycloak from 'keycloak-js';
import qs from 'query-string';

const keycloak = Keycloak({
    url: process.env.REACT_APP_KEYCLOAK_URL,
    realm: process.env.REACT_APP_KEYCLOAK_REALM,
    clientId: process.env.REACT_APP_KEYCLOAK_CLIENTID,
});

const tokenWrapper = {
    tokens: null,
};

const handleTokens = (freshTokens) => {
    tokenWrapper.tokens = freshTokens;
};

const AuthWrapper = ({ children }) => {
    switch (process.env.REACT_APP_LOGIN_METHOD) {
        case 'OPENID':
            const { idpHint } = qs.parse(window.location.search);
            const createLoginUrl = keycloak.createLoginUrl;
            keycloak.createLoginUrl = (options) => createLoginUrl({...options, idpHint});
            return (
                <ReactKeycloakProvider
                    authClient={keycloak}
                    onTokens={handleTokens}
                    initOptions={{ onLoad: 'login-required' }}
                    LoadingComponent={<LinearProgress />}
                >
                    {children}
                </ReactKeycloakProvider>
            );
        case 'CERNSSO':
        case 'LOCAL':
            return children;
        default:
            return <div>No authentication flow declared.</div>;
    }
};

const logout = () => {
    switch (process.env.REACT_APP_LOGIN_METHOD) {
        case 'OPENID':
            keycloak.logout();
            break;
        case 'CERNSSO':
            window.location.href = 'https://espace.cern.ch/authentication/_layouts/15/SignOut.aspx';
            break;
        case 'LOCAL':
            console.warn('Cannot logout if the login method is LOCAL');
            break;
        default:
            break;
    }
};

export default AuthWrapper;
export { tokenWrapper, keycloak, logout };
