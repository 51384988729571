import React from 'react';
import WSFlexBuilding from '../../../../tools/rest/WSFlexBuilding';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import {Barcode} from 'mdi-material-ui';
import KioskButton from '../../../components/buttons/KioskButton';
import {GROUP_METHODS} from './SITSelectionAssets';
import BlockUi from 'react-block-ui';
import AMMGrid from '../../../components/AMMGrid';

//TODO SHOULD COME FROM APP DATA
export const MAX_WEIGHT_ALIAS = 'bna_weightlim';
export const BIN_CODE_ALIAS = 'bin_code';
export const BIN_STORE_ALIAS = 'bin_store';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

export const COLORS = {
    red: {
        backgroundColor: 'rgba(251,233,231,0.7)',
        color: '#bf360C'
    }
}

export const KeyCode = {
    DOWN: 40,
    UP: 38,
    ENTER: 13,
    F7: "F7",
    F8: "F8"
}

class SITSelectionBins extends React.Component {

    state = {
        open: false,
        kitcode: '',
        group: {},
        keyword: ''
    };

    componentWillMount () {
        const { assetsForKit, groupCode, part } = this.props;
        if (!assetsForKit) return;

        let group = assetsForKit.groups.find(g => g.item && g.item.code ===  groupCode)
        // if (!group) {
        //     group =
        //         assetsForKit.groups
        //             .reduce((acc, g) =>
        //                 g.method === GROUP_METHODS.DIRECTLY ? acc.concat(g.items) : acc
        //                 , [])
        //             .filter(itemCode => itemCode === groupCode)
        //             .map(itemCode => assetsForKit.items.find(item => item.code === itemCode))
        //             .map(item => new Group('', GROUP_METHODS.DIRECTLY, item))
        //             .find(g => g.item.code === groupCode)
        //             ;
        // }
        if (group && group.method === GROUP_METHODS.PACKAGE) {
            this.printBarcode(group.item);
        }
        this.setState(({
            group,
            item: (group && group.item) || {code: part.parCode, desc: part.desc },
            isPart: !group
        }))
    }

    openDialog = code => {
        this.setState({
            open: true,
            kitcode: code
        });
    };

    handleClose = () => {
        let {currentStore, history} = this.props;
        history.push( process.env.REACT_APP_FRONTEND + `${currentStore.code}/inbound`);
    };

    onSelect = bin => () => {
        this.setState(prevState => ({
                ...prevState,
                loading: true
            }), _ =>
                this.placePackage(bin)
            )
    };

    placePackage = bin => {
        const { assetsForKit, updateAssetsForKit, currentStore, placePartOnBin } = this.props;
        const { isPart } = this.state;
        const { part } = this.props;
        if (isPart) {
            placePartOnBin({
                part: part.parCode,
                bin: bin.value
            })
            this.props.history.push(process.env.REACT_APP_FRONTEND + `${currentStore.code}/inbound/${this.props.match.params.sitCode}/items/bins`)
        } else {
            let {item} = this.state.group;
            updateAssetsForKit({
                ...assetsForKit,
            items: assetsForKit.items.map(
                it =>
                ({
                    ...it,
                    bin: it.code === item.code ? bin.value : it.bin
                })
                ),
                groups: assetsForKit.groups.map(
                    g => ({
                        ...g,
                        item: {
                            ...g.item,
                            bin: g.item && g.item.code === item.code ?
                            bin.value : g.item.bin
                        }
                    })
                    )
                })
                this.props.history.push(process.env.REACT_APP_FRONTEND + `${currentStore.code}/inbound/${this.props.match.params.sitCode}/items/bins`)
        }
    }

    createKit = bin => {
        const {assetsForKit, currentStore, kitPartCode} = this.props;
        let buildKitInfo = {
            ...assetsForKit,
            binCode: bin.value
        };

        console.log('SENDING BUILD KIT ', buildKitInfo);
        let first = this.props.packagingMethod === "KIT" ?
            WSFlexBuilding.buildKit(buildKitInfo) :
            WSFlexBuilding.placePackage(buildKitInfo);

        first
            .then(response => {
                console.log('buildKit', response);
                this.openDialog(response.body.data.code ?  response.body.data.code : response.body.data);
            }).catch((error) => {
                this.setState({loading: false})
                this.props.handleError(error);
            });
    };

    cellRenderer = ({ column, value, row }) => {
        if (column.id === 'selectbin') {
            return <Button variant="contained" color="primary" onClick={this.onSelect(({value: row.values[BIN_CODE_ALIAS]}))}>
                Select
            </Button>
        }
        return value;
    };

    rowStyler = row => {
        return (row && row.cell && row.cell.length > 0 && row.cell.find && row.cell.find(cell => cell.t === MAX_WEIGHT_ALIAS).value &&
            +row.cell.find(cell => cell.t === MAX_WEIGHT_ALIAS).value < this.state.group &&  this.state.group &&  this.state.group.item ? this.state.group.item.weight : 0) ?
        COLORS.red :
            {};
    }

    printBarcode = (code, desc, serialno) => () => {
        let barcodeInput = {
            type: 'E', // Set print for EQUIPMENT
            variables: [{
                code: code, // Send info for main code to print,
                fields: {equipmentdesc: desc, serialnumber: serialno}
            }]
        };
        WSFlexBuilding.printBarcode.bind(WSFlexBuilding)(barcodeInput)
            .then(response => {
                console.log(response);
                this.props.showSuccess(response.body.data);
            })
            .catch(error => {
                if (error && error.response && error.response.body) {
                    this.props.showError('ERROR '+error.response.body.data);
                }
                this.props.handleError(error);
            });
    }

    gridRequestAdapter = gridRequest => {
        let filterStore = {operator: "EQUALS", fieldValue: this.props.currentStore.code, joiner: "AND", fieldName: BIN_STORE_ALIAS };

        return {
            ...gridRequest,
            gridFilter: [...gridRequest.gridFilter, filterStore],
            useNative: false
            //params: {wspf_10_bna_weigthlim: this.props.currentStore.code}
        }
    }

    componentDidMount(){
        if (this.searchInput) this.searchInput.focus();
    }

    onKeyDown(event) {
        //TODO VALIDATE THROUGH WEBSERVICE FIRST IF BIN EXISTS
        switch (event.keyCode) {
            case KeyCode.ENTER: {
                this.onSelect({value: this.state.keyword})();
                break;
            }
            default:
                break;
        }
    }

    handleSearchInput = input => {
        this.setState({keyword: input.target.value})
    }

    render() {
        const { loading, group } = this.state;
        const { groupCode, part } = this.props;
        const { applicationData, assetsForKit } = this.props;
        let { item } = this.state;

        //item =
        //Validate that there is a current store
        // if (!this.props.currentStore || !item) {
        //     return <div/>
        // }
        return (
            <BlockUi tag="div" blocking={loading} style={{height: '100%', overflowY: 'auto'}}>
                {item &&
                    <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', fontFamily: 'Trebuchet MS', marginTop: '10', fontSize: '20px', color: '#2196F3'}}>
                    <div style={{ marginTop: '8px', marginLeft: '8px', fontSize: '20px', verticalAlign: 'middle', color: 'rgb(21, 65, 145)', fontWeight: 'bold'}}>{`${groupCode} `}</div>
                    <div style={{marginTop: '8px', marginLeft: '8px', verticalAlign: 'middle', color: 'black'}}>{` ${item.desc}`}</div>
                    <Button
                            style={{
                                marginTop: '0px',
                                verticalAlign: 'middle',
                                marginLeft: 15,
                                boxSizing: 'border-box'
                            }}
                            variant="contained" color="primary" onClick={this.printBarcode(item.code, item.desc, item.serialno)}>
                        <Barcode style={{...this.iconStyle, marginRight: 4}}/>Print
                    </Button>
                        <input
                                onInput={this.handleSearchInput}
                                id="searchInputText"
                                onKeyDown={this.onKeyDown.bind(this)}
                                value={this.state.keyword}
                                style={{textTransform: "uppercase", opacity: 0}}
                                ref={input => { this.searchInput = input; }}
                                position='absolute'
                                autoComplete='off'
                        />
                    </div>
                }

                <div style={{height: 'calc(100% - 40px)'}}>
                    <AMMGrid
                        gridRequestAdapter={this.gridRequestAdapter}
                        onRef={ref => this.grid = ref}
                        screenCode={applicationData.storeBinsGridName}
                        cellRenderer={this.cellRenderer}
                        rowStyler={(row) => this.rowStyler(row, assetsForKit)}
                        extraColumns={[{
                            width: '105px',
                            headerLabel: '',
                            t: 'selectbin'
                        }]}
                    />
                </div>
                <Dialog
                    open={this.state.open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.handleClose}
                    disableBackdropClick={true}
                    disableEscapeKeyDown={true}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">
                        {this.state.kitcode} Built Successfully
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            The transaction was correctly executed
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

            </BlockUi>
        );
    }
}

export default SITSelectionBins;