import React from 'react';
import EAMAutocomplete from 'eam-components/dist/ui/components/inputs-ng/EAMAutocomplete'
import WS from "../../../../../tools/rest/WS";
import { createOnChangeHandlerObjectUpdate } from 'eam-components/dist/ui/components/inputs-ng/tools/input-tools'

const CODE_KEY = 'equipmentCode';
const DESC_KEY = 'equipmentDesc';

class KITContextSelectionEquipment extends React.Component {

    render() {
        const {updateTransaction, currentTransaction} = this.props
        const {equipmentCode, equipmentDesc} = currentTransaction

        return (
        <div>
            <EAMAutocomplete
                label="Equipment"
                value={equipmentCode}
                desc={equipmentDesc}
                onChange={createOnChangeHandlerObjectUpdate(
                    CODE_KEY, DESC_KEY, null, updateTransaction, null
                )}
                autocompleteHandler={(key) => WS.autocompleteEquipment(key)}
                // autoFocus // TODO: needs update of inputs-ng
                // autoSelectSingleElement // TODO: needs update of inputs-ng
                // creatable // TODO: needs update of inputs-ng
                barcodeScanner
            />
        </div>
    )}
}

export default KITContextSelectionEquipment;