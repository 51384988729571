import EAMCheckbox from 'eam-components/dist/ui/components/inputs-ng/EAMCheckbox';
import { format } from 'date-fns';
import EAMDatePicker from 'eam-components/dist/ui/components/inputs-ng/EAMDatePicker';
import EAMAutocomplete from 'eam-components/dist/ui/components/inputs-ng/EAMAutocomplete';
import EAMFormLabel from 'eam-components/dist/ui/components/inputs/EAMFormLabel';
import EAMSelect from 'eam-components/dist/ui/components/inputs-ng/EAMSelect';
import EAMTextField from 'eam-components/dist/ui/components/inputs-ng/EAMTextField';
import BlockUi from 'react-block-ui';
import EISTable from 'react-eis-components/ui/components/table';
import { generatePath } from 'react-router';
import { ROUTES } from 'Routes';
import WS from 'tools/rest/WS';
import WSFlexBuilding from 'tools/rest/WSFlexBuilding';
import KioskAccess from 'ui/pages/KioskAccess';
import KioskButton from '../../../components/buttons/KioskButton';
import { CELL_TYPES } from './OutboundRequestSelectItems';
import { createOnChangeHandler, createOnChangeHandlerObjectUpdate } from 'eam-components/dist/ui/components/inputs-ng/tools/input-tools';

const singleFieldStyle = {
    justifyContent: 'center',
    align: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '400px',
    minWidth: '150px',
    marginRight: 'auto',
    marginLeft: 'auto',
};

class OutboundRequestDestination extends KioskAccess {
    
    transactionOptions = {
        WORKORDER: {
            title: 'Work Order/Activity',
            text: 'Work Order/Activity',
            code: 'WORKORDER',
            activityCode: '',
            autocompleteWS: key => WS.autocompleteWorkorder(this.props.storeCode, 'issue', key)
        },
        EMPLOYEE: {
            title: 'Employee',
            text: 'Employee',
            code: 'EMPLOYEE',
            autocompleteWS:  key => WS.autocompleteEmployee(key)
        },
        EQUIPMENT: {
            title: 'Equipment',
            text: 'Equipment',
            code: 'EQUIPMENT',
            autocompleteWS: key => WS.autocompleteEquipment(key)
        },
        LOCATION: {
            title: this.props.translations.DESTINATION,
            text: this.props.translations.DESTINATION_LOC,
            code: 'LOCATION',
            autocompleteWS: key => WS.autocompleteLocation(key)
        }
    }

    availableTransactionOptions = [ this.transactionOptions.LOCATION];
    
    state = {
        transactionOnCode: this.availableTransactionOptions.length ? this.availableTransactionOptions[0].code : null,
        transactionOnDestination: {
            ...Object.keys(this.transactionOptions)
                .reduce((obj, str) =>
                    Object.defineProperty(obj, str, {enumerable: true , value: {on: str, code: '', desc: ''}}), {})
            },
        requestProcess: { items: this.props.requestProcess.items },
        transactionReqDate: addWorkingDays(new Date(), 5),
        transactionTransportReq: true,
        activities: [],
        loading: false,
        edhDocumentType: this.props.applicationData.edhdocumentTypes?.[0]?.code,
        description: ''
    }

    updateDestination = (key, value, transactionOnCode) => {
        this.setState(prevState => ({
                ...prevState,
                transactionOnDestination: {
                    ...prevState.transactionOnDestination,
                    [transactionOnCode]: {
                        ...prevState.transactionOnDestination[transactionOnCode],
                        [key]: value
                    }
                }
            }),
            () => {
                if (key === 'code' && transactionOnCode === this.transactionOptions.WORKORDER.code) {
                    WS.readWorkOrderActivities(value)
                        .then(response => {
                            let activities = response.body.data;
                            // If the WO has only one activity set it
                            this.setState(
                                {activities:
                                    activities.map(activity => ({
                                            code: activity.activityCode,
                                            desc: activity.activityCode + ': ' + activity.tradeCode
                                        }))
                                },
                                () => {
                                    if (activities.length === 1) {
                                        this.updateDestination('activityCode', activities[0].activityCode, transactionOnCode)
                                    }
                                }
                            );
                        })
                }
            }
        );

    }

    formatDate = (dateStr, origFormat, intendedFormat) =>
        format(new Date(this.state.transactionReqDate), "dd-MMM-yyyy")

    handleRequest = () => {
        const createPickTicket = _ => this.createPickTicket(
            Object.values(this.props.requestProcess?.items || {}).map(item => ({assetCode: item.obj_code, partCode: item.obj_part, quantity: item.quantity, lot: item.lot, store: item[CELL_TYPES.OBJ_STORE]})),
            this.state.transactionOnCode,
            this.state.transactionOnDestination[this.state.transactionOnCode],
            this.state.transactionReqDate,
            this.state.transactionTransportReq,
            this.state.edhDocumentType,
            this.state.description
        )

        if (Object.values(this.props.requestProcess?.items || {}).some(item => item.obj_udfchar04 === "Radioactive")) {
            this.props.openConfirmDialog({
                title: "Warning dialog",
                message: `You requested radioactive assets. An RP Measurement must be performed before the assets leave the store. Are you sure you want to request ${this.getRequestedCodes()} for ${this.formatDate()}?`,
                cancelButtonLabel: "No",
                confirmButtonLabel: "Yes",
                waitForCompletion: true
            },
            createPickTicket);
        } else {
            createPickTicket();
        }
    }

    updateState = (stateCode, entryCode, key, value) => {
        this.setState((prevState) =>({
                requestProcess: {
                    [stateCode]: {
                        ...prevState.requestProcess.items,
                        [entryCode]: {
                            ...prevState.requestProcess[stateCode][entryCode],
                            [key]: value
                        }
                    }
                }
        }))
    }

    createPickTicket = (itemsList, transactionOnCode, transactionOnDestination, transactionReqDate, transactionTransportReq, edhDocumentType, description) => {
        const { showSuccess, showError, handleError, history, match } = this.props;
        const createPickTicketBean = {
            destinationType: transactionOnCode,
            destinationCode: transactionOnDestination.code,
            activityCode: transactionOnDestination.activityCode,
            requestedDate: transactionReqDate,
            transportRequired: transactionTransportReq,
            description: description,
            edhDocumentType,
        }

        const groupedItems = itemsList.reduce((acc, item) => ({ ...acc, [item.store]: [...(acc[item.store] ?? []), item] }) , {});

        const promises = Promise.all(
            Object.values(groupedItems).map(
                (items) => WSFlexBuilding.createPickTicket({
                    ...createPickTicketBean,
                    storeCode: items[0].store,
                    transactions: items.map(({store, ...other}) => other)})
            )
        );

        this.setState({ loading: true });
        promises
            .then(response => {
                response.length && showSuccess(`Pick tickets created: ${response.map(resp => resp.body.data).join(', ')} for ${this.getRequestedCodes()} for ${this.formatDate()}`)
                history.push(generatePath(ROUTES.STORE_MENU, match.params));
            })
            .catch(error => {
                console.error(error);
                error ? handleError(error) : showError('Unspecified error. Please contact CMMS support.')
            })
            .finally(() => {
                this.setState({ loading: false })
            });
    }

    getRequestedCodes = () => this.props.requestProcess.items && Object.values(this.props.requestProcess.items).map(obj=>`${obj.obj_code || obj.obj_part}`).join(', ')

    getRequestedList = () =>
        this.props.requestProcess.items && Object.entries(this.props.requestProcess.items).map(ac => `"${ac[0]}: ${ac[1].obj_desc}"`).join(', ')

    renderPage() {
        const { loading, transactionOnDestination = {} } = this.state;
        const transactionOn = this.transactionOptions[this.state.transactionOnCode];
        const transactionDest = transactionOnDestination[transactionOn.code] || {};

        const advanceDays = 3;
        const minDate = addWorkingDays(new Date(), advanceDays);

        return (
            <BlockUi tag="div" blocking={loading} style={{margin: 0, height: '100%', width: '100%', overflow: 'auto'}}>
                <div style={{height: '100%', overflowX: 'hidden'}}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        flexGrow: '1'
                    }}>

                        <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center'}}>
                            {
                                this.availableTransactionOptions.map( transaction =>
                                    <KioskButton
                                        key={transaction.code}
                                        color={this.state.transactionOnCode === transaction.code ? 'primary' : 'info'}
                                        onClick={() => {this.setState({transactionOnCode: transaction.code})}}
                                    >
                                        {transaction.title}
                                    </KioskButton>
                                )
                            }
                        </div>
                        {transactionOn &&
                            (<div style={{display: 'flex', flexDirection: 'column', flexWrap:'wrap', flex: '1 0 auto'}}>
                                <div style={singleFieldStyle}>
                                    <EAMAutocomplete
                                        label={transactionOn.text}
                                        value={transactionDest.code}
                                        desc={transactionDest.desc}
                                        onChange={createOnChangeHandler(
                                            'code', 'desc', null, this.updateDestination, null, [transactionOn.code]
                                        )}
                                        autocompleteHandler={transactionOn.autocompleteWS}
                                        // autoFocus // TODO: needs update of inputs-ng
                                        // creatable // TODO: needs update of inputs-ng
                                        barcodeScanner
                                    />
                                </div>
                                {transactionOn.code === this.transactionOptions.WORKORDER.code &&
                                    <div  style={singleFieldStyle}>
                                        <EAMSelect
                                            label="Activity"
                                            value={transactionDest.activityCode}
                                            renderValue={(value) => value.desc || value.code}
                                            desc={transactionDest.activityDesc}
                                            onChange={createOnChangeHandler(
                                                'activityCode', 'activityDesc', null, this.updateDestination, null, [transactionOn.code]
                                            )}
                                            options={this.state.activities}
                                        />
                                    </div>
                                }
                                <div style={{ display: 'flex', flexDirection: 'row', marginRight: 'auto', marginLeft: 'auto', maxWidth: '400px', minWidth: '150px', justifyContent: 'space-between'}}>
                                    <div style={{ align: 'center', display: 'flex', flexDirection: 'column', width: '50%', minWidth: '150px'}}>
                                        <EAMDatePicker
                                                label={'Requested Date'}
                                                key={'requestedDate'}
                                                elementInfo={{
                                                    attribute: 'R',
                                                }}
                                                value={this.state.transactionReqDate}

                                                onChange={(val) => {
                                                    this.setState({transactionReqDate: val})
                                                }}
                                                minDate={minDate}
                                        />
                                    </div>
                                    <div style={{ justifyContent: 'flex-end', align: 'center', display: 'flex', flexDirection: 'row', marginTop: '17px'}}>
                                        <EAMCheckbox
                                            label={'EDH Document'}
                                            value={this.state.transactionTransportReq}
                                            onChange={() =>
                                                this.setState((prevState) => ({
                                                    transactionTransportReq:
                                                        !prevState.transactionTransportReq,
                                                }))
                                            }
                                        />
                                    </div>
                                </div>

                                {this.state.transactionTransportReq ? <div  style={singleFieldStyle}>
                                    <EAMSelect
                                        label="EDH Document Type"
                                        value={this.state.edhDocumentType}
                                        options={this.props.applicationData.edhdocumentTypes}
                                        onChange={createOnChangeHandlerObjectUpdate(
                                            'edhDocumentType', null, null, this.setState.bind(this), null
                                        )}
                                        disabled={!this.state.transactionTransportReq}
                                    />
                                </div> : null}

                                <div style={singleFieldStyle}>
                                    <EAMTextField
                                        label={'Description'}
                                        value={this.state.description}
                                        // autoFocus={false} // TODO: needs update of inputs-ng
                                        onChange={createOnChangeHandlerObjectUpdate(
                                            'description', null, null, this.setState.bind(this), null
                                        )}
                                    />
                                </div>
                                <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center'}}>
                                    <KioskButton
                                        onClick={this.handleRequest}
                                        disabled={!(
                                            this.state.transactionReqDate
                                            && this.state.transactionOnCode
                                            && this.state.transactionOnDestination[this.state.transactionOnCode].code
                                            && (!this.state.transactionTransportReq || this.state.edhDocumentType)
                                            && this.state.description.length > 0
                                        )}>
                                        Request
                                    </KioskButton>
                                </div>
                            </div>)
                        }
                        <EISTable
                            data={Object.values(this.props.requestProcess?.items || {})}
                            headers={['Asset', 'Part', 'Desc', 'Lot', 'Qty', 'Store']}
                            propCodes={[CELL_TYPES.OBJ_CODE, CELL_TYPES.OBJ_PART, CELL_TYPES.OBJ_DESC, CELL_TYPES.LOT, CELL_TYPES.QUANTITY, CELL_TYPES.OBJ_STORE]}
                            hideSort
                    />
                    </div>
                </div>
            </BlockUi>
        );
    }
}

const addWorkingDays = (date, ndays) => {
    for ( ; ndays > 0; --ndays) {
        const daysToAdd = date.getDay() > 5 ? 8 - date.getDay() : 1;
        date = new Date(date.getTime() + 1000 * 60 * 60 * 24 * daysToAdd);
    }
    return date;
}

export default OutboundRequestDestination;