import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

class KioskDialog extends React.Component {

    goToHomePage = () => {
        this.props.resetTransaction();
        if (this.props.kioskMode) {
            this.props.logout();
        }
        this.props.history.push(process.env.REACT_APP_FRONTEND + `${this.props.storeCode}/`)
    };

    render() {
        const {transactionNumber, storeCode, kioskMode, logout, resetTransaction, history, ...other} = this.props;
        return (
            <Dialog onClose={this.goToHomePage} aria-labelledby="simple-dialog-title" {...other}>
                <DialogTitle id="simple-dialog-title">Transaction Completed</DialogTitle>
                <DialogContent>
                    The transaction {transactionNumber} was submitted successfully.
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={this.goToHomePage} style={{marginTop: '10px'}}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default KioskDialog;