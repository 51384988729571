import EAMAutocompleteWIP from 'eam-components/dist/ui/components/inputs-ng/wip/EAMAutocomplete';
import EAMFormLabel from 'eam-components/dist/ui/components/inputs/EAMFormLabel';
import EAMTextField from 'eam-components/dist/ui/components/inputs-ng/EAMTextField';
import React from 'react';
import BlockUi from 'react-block-ui';
import WS from '../../../tools/rest/WSPartSelection';
import KioskButton from '../../components/buttons/KioskButton';
import Containerize from './Containerize';
import WSInventory from '../../../tools/rest/WSInventory';
import { Button, Container } from '@mui/material';
import InfoIcon from '@material-ui/icons/Info';
import { createOnChangeHandlerObjectUpdate } from 'eam-components/dist/ui/components/inputs-ng/tools/input-tools';

const labelStyle = { fontSize: 18, minWidth: 150 };

export const loadBins = (storeCode) => (hint) =>
    WS.getBinsList(storeCode, hint)
        .then(resp =>{
            resp.body.data = resp.body.data
                && resp.body.data.map(bin => ({...bin, code: bin.bin_code, desc: `${bin.bin_code} - ${bin.bin_desc}`}))
            return resp;
        })

const createOption = ({ code, desc, Content = () => null, canContinue = () => true, infoMessage = () => null }) => ({code, desc, Content, canContinue, infoMessage})

const updateBinList = (updateModeData, binList, selectedBins, setFieldSelectedBins, tagLabelKey) => {

    let updatedBinList;

    if (Array.isArray(selectedBins)) {
        setFieldSelectedBins(selectedBins);
        updatedBinList = selectedBins?.map((binData) => binData[tagLabelKey]);

    } else {
        const append = true;
        let binCode;

        // Case of manual input
        if (typeof selectedBins === 'object' && selectedBins.code) {
            binCode = selectedBins.code;
            updatedBinList = [ ...(binList || []), ...binCode ];
        }
        // Case of barcode scan
        if (typeof selectedBins === 'string') {
            binCode = selectedBins;
            updatedBinList = [ ...(binList || []), binCode ];
        }

        if (binCode) {
            setFieldSelectedBins({ [tagLabelKey]: binCode }, append);
        }
    }

    if (updatedBinList) {
        updateModeData({ binList: updatedBinList });
    }
};

const binListContent = (modeData, updateModeData, storeCode, fieldSelectedBins, setFieldSelectedBins, tagLabelKey) => {
    const { binList } = modeData;

    return <React.Fragment>
            <EAMAutocompleteWIP
                label="Bins"
                value={fieldSelectedBins}
                renderValue={value => value.desc || value.code}
                autocompleteHandler={loadBins(storeCode)}
                multiple
                tagLabelKey={tagLabelKey}
                // autoFocus // TODO: needs update of inputs-ng
                // autoSelectSingleElement // TODO: needs update of inputs-ng
                creatable // TODO: Supported in EAMAutocomplete WIP version
                onChange={(selectedBins) =>
                    updateBinList(updateModeData, binList, selectedBins, setFieldSelectedBins, tagLabelKey)
                }
                barcodeScanner
            />
        </React.Fragment>
}

const binPrefix = (modeData, updateModeData) => {
    const { binPrefix } = modeData;

    const updateModeDataBinUpperCase = (value) => {
        updateModeData({ binPrefix: value?.toUpperCase() });
    };

    return <div>
            <EAMTextField
                label="Bin Prefix"
                value={binPrefix || ''}
                onChangeInput={updateModeDataBinUpperCase}
            />
        </div>
}

const createInventory = (data, setLoading, handleError, goTo) => {
    setLoading(true);
    WSInventory.createInventory(data)
        .then(resp => {
            goTo(resp.body.data)
        })
        .catch(err => handleError(err))
        .finally(resp => setLoading(false))
}

const INVENTORY_MODES = {
    BIN_LIST: 'BIN_LIST',
    BIN_PREFIX: 'BIN_PREFIX',
    ALL: 'ALL',
    FREE: 'FREE'
}

class InventoryModeSelector extends React.Component {

    state = {
        fieldSelectedBins: [],
    };

    setFieldSelectedBins = (selectedBins, append = false) => {
        append
            ? this.setState((prevState) => ({ fieldSelectedBins: [...prevState.fieldSelectedBins, selectedBins] }))
            : this.setState({ fieldSelectedBins: selectedBins });
    }

    render() {
        const { loading, fieldSelectedBins } = this.state;
        const { storeCode, handleError, getModeInfo, updateModeInfo, history } = this.props;

        const modeInfo = getModeInfo();
        const { mode, modeData } = modeInfo;
        const updateModeData = (modeData) => updateModeInfo({modeData})

        const getLinkToInventory = invCode => history.push(`${process.env.REACT_APP_FRONTEND}${storeCode}/inventory/${invCode}`);

        // const list = ;
        const optionList = [
            createOption({
                code: INVENTORY_MODES.ALL,
                desc: 'All',
                infoMessage: () => (
                    <React.Fragment>
                        <span>In this mode, every bin in the store must be scanned, as well as its content.</span>
                        <br></br>
                        <span>Press 'Done' to finalize each bin. Every part not found will have its quantity updated to 0.</span>
                    </React.Fragment>
                )
            }),
            createOption({
                code: INVENTORY_MODES.BIN_PREFIX,
                desc: 'Bin Prefix',
                infoMessage: () => (
                    <React.Fragment>
                        <span>The operator will have to perform the inventory on ALL bins that start with a given prefix.</span>
                        <br></br>
                        <span>Press 'Done' to finalize each bin. Every part not found will have its quantity updated to 0.</span>
                    </React.Fragment>
                ),
                canContinue: () => modeData.binPrefix,
                Content: () => binPrefix(modeData, updateModeData),
            }),
            createOption({
                code: INVENTORY_MODES.BIN_LIST,
                desc: 'Bin List',
                infoMessage: () => (
                    <React.Fragment>
                        <span>In this mode, you can input the list of bins for which the inventory must be done.</span>
                    </React.Fragment>
                ),
                canContinue: () => modeData.binList,
                Content: () => binListContent(modeData, updateModeData, storeCode, fieldSelectedBins, this.setFieldSelectedBins, 'code'),
            })
            // createOption(INVENTORY_MODES.FREE, 'Free Mode', _ => <span>
            //         In this mode, you can scan the bins as you go, and the report will only consider those.<br></br>
            //     </span>),
            ]

        const selected = mode ?
            optionList.filter(option => mode === option.code)[0]
            : null
            ;

        const inventoryModeMessage = selected ? selected.infoMessage() : null;

        const ModeContent = selected ? selected.Content : () => null;

        const select = optionList.map(pair =>
                <Button
                        key={pair.code}
                        color='primary'
                        onClick={_ => updateModeInfo({mode: pair.code, modeData: {}})}
                        variant={mode === pair.code ? 'contained' : 'outlined'}
                        size="large"
                        disableElevation
                    >
                        {pair.desc}
                </Button>
        )

        return (
            <Container fixed>
                <BlockUi blocking={loading} style={{height: '100%'}}>
                    <EAMFormLabel label="Inventory Mode" labelStyle={labelStyle}/>
                    <div style={{display: 'flex', flexDirection: 'column', flexWrap: 'wrap', alignItems: 'flex-start', marginBottom: 40}}>
                        <div style={{ margin: "10px 0" }}>{select}</div>
                        {inventoryModeMessage &&
                            <div style={{
                                    backgroundColor: '#f1f1f1',
                                    borderRadius: '4px',
                                    padding: '8px',
                                    color: '#006598',
                                    display: 'flex',
                                    alignItems: 'center',
                            }}>
                                <React.Fragment>
                                    <InfoIcon />
                                    <span style={{ paddingLeft: 12 }}>{inventoryModeMessage}</span>
                                </React.Fragment>
                            </div>
                        }
                    </div>
                    { selected &&
                        <div style={{ marginBottom: 20 }}>
                            <EAMTextField
                                label="Description"
                                value={modeInfo.desc || ''}
                                // autoFocus={!modeInfo.desc} // TODO: needs update of inputs-ng
                                onChange={createOnChangeHandlerObjectUpdate(
                                    'desc', null, null, updateModeInfo, null
                                )}
                            />
                        </div>
                    }

                    { /* Using JSX below causes de whole parent to update}*/}
                    {ModeContent()}
                    <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', marginTop: 20}}>
                        <KioskButton
                                disabled={!selected || !modeInfo.desc || !selected.canContinue()}
                                color={'primary'}
                                onClick={_ => createInventory({storeCode, desc: modeInfo.desc, mode, ...modeData}, loading => this.setState.bind(this)({loading}), handleError, getLinkToInventory)}
                            >
                            CREATE
                        </KioskButton>
                    </div>
                </BlockUi>
            </Container>
        )
    }
}

export default Containerize(InventoryModeSelector);
