import EAMTextField from 'eam-components/dist/ui/components/inputs-ng/EAMTextField';
import { createOnChangeHandler } from 'eam-components/dist/ui/components/inputs-ng/tools/input-tools';

const labelStyle = {
    width: 120,
    minWidth: 120,
    maxWidth: 120,
    textAlign: 'right',
    flexGrow: 1,
};

export const validateNumber = (value) =>
    !isNaN(value) &&
    !isNaN(parseFloat(value)) &&
    value >= 0 &&
    value < 999999999999;

export const getEditableMeasurement = (
    arrayField,
    item,
    key,
    callback,
    number = true
) => (
    <EAMTextField
        style={{
            ...labelStyle,
            ...(number ? {} : { width: undefined, maxWidth: undefined }),
        }}
        value={item[key]}
        key={item.code}
        onChange={createOnChangeHandler(
            key, null, null, callback, null, [arrayField, item.code]
        )}
        validator={number ? validateNumber : undefined}
    />
);
