import React from 'react';
import TransactionTypes from "../../../enums/TransactionTypes";
import AMMGrid from '../../components/AMMGrid';
import KioskAccess from '../KioskAccess';
import { Button } from '@mui/material';
import {withStyles} from "@mui/styles";

const styles = theme => ({
    frame: {
        height: 'calc(100% - 80px)'
    },
    buttonsContainerStyle: {
        height: "80px",
        width: "100%",
        minHeight: 80,
        display: "flex",
        justifyContent: "center"
    },
    buttonsInnerContainerStyle: {
        display: "flex",
        width: "40%",
        justifyContent: "space-around"
    },
    buttonStyle: {
        height: "100%",
        maxHeight: 80,
        minWidth: 150,
        fontSize: 20,
        padding: '6px',
        boxSizing: 'border-box'
    },
    '@media ( max-width : 400px ) or ( max-height : 700px )': {
        buttonsContainerStyle: {
            height: "40px",
            minHeight: 40,
        },
        buttonStyle: {
            height: "100%",
            maxHeight: 40,
            fontSize: 16,
            minWidth: 120,
        },
        frame: {
            height: 'calc(100% - 40px)'
        }
    },
});

class SearchEquipmentPage extends KioskAccess {

    state = {};

    _setFilter() {
        if (this.grid && this.props.currentStore) {
            this.grid.setFilter()
        }
    }

    componentDidMount() {
        this.props.updateTransactionType(null);
    }

    gridRequestAdapter = gridRequest => {
        let filterStore =  {
            fieldName: "kis_store",
            fieldValue: this.props.currentStore.code,
            operator: 'EQUALS',
            joiner: "AND",
        };

        return {
            ...gridRequest,
            gridFilter: [...gridRequest.gridFilter, filterStore],
            useNative: false,
        }
    }

    addLinesToTransaction = transactionType => {
        const { selectedRows } = this.state;
        if (!selectedRows?.length > 0) {
            this.props.showWarning("No parts are selected");
            return;
        }
        const linesToSubmit = selectedRows.map(obj => ({
            partCode: obj.kis_part,
            partDesc: obj.par_desc,
            partOrg: '*',
            assetIDCode: obj.kis_object || obj.equipment,
            bin: obj.kis_bin,
            lot: obj.kis_lot,
            transactionQty: 1,
            uom: obj.par_uom,
        }));

        this.props.addLinesToCurrentTransaction(linesToSubmit);
        this.props.history.push(process.env.REACT_APP_FRONTEND + `${this.props.currentStore.code}/` + transactionType.toString().toLowerCase());
    };

    onSelectRow = (rows) => {
        this.setState({selectedRows: rows.map(r => r.values)});
    }

    renderPage () {
        const { selectedRows } = this.state;
        const { classes } = this.props;
        const showReturn = (selectedRows || []).every(s => !s.kis_object || s.kis_qty === "0");
        return (
            <React.Fragment>
                <div className={classes.frame}>
                    <AMMGrid
                        screenCode={this.props.gridName}
                        allowRowSelection
                        onSelectRow={this.onSelectRow}
                        gridRequestAdapter={this.gridRequestAdapter}
                        useNative={false}
                    />
                    <div className={classes.buttonsContainerStyle}>
                        <div className={classes.buttonsInnerContainerStyle}>
                            <div className={classes.buttonStyle}><Button variant="contained" size="small"
                                                                         className={classes.buttonStyle}
                                                                         onClick={() => this.addLinesToTransaction(TransactionTypes.ISSUE)}>Issue</Button>
                            </div>
                            <div className={classes.buttonStyle}>{showReturn &&
                                <Button variant="contained" size="small" className={classes.buttonStyle}
                                        onClick={() => this.addLinesToTransaction(TransactionTypes.RETURN)}>Return</Button>}</div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(SearchEquipmentPage);