import Button from '@mui/material/Button';
import EAMAutocomplete from 'eam-components/dist/ui/components/inputs-ng/EAMAutocomplete';
import EAMSelect from 'eam-components/dist/ui/components/inputs-ng/EAMSelect';
import { Barcode, CloseCircle } from 'mdi-material-ui';
import React from 'react';
import BlockUi from 'react-block-ui';
import EISTable from '../../../../react-eis-components/ui/components/table';
import WS from '../../../../tools/rest/WS';
import WSFlexBuilding from '../../../../tools/rest/WSFlexBuilding';
import KioskButton from '../../../components/buttons/KioskButton';
import Checkbox from '@mui/material/Checkbox';
import Select from 'react-select';
import { createOnChangeHandler } from 'eam-components/dist/ui/components/inputs-ng/tools/input-tools';
import { getEditableMeasurement } from './SITTools';

const DEBUG = false;

export const GROUP_METHODS = {
    DIRECTLY: "DIRECTLY",
    PACKAGE: "PACKAGE",
    KIT: "KIT"
}

const TRANSLATIONS = {
    [GROUP_METHODS.DIRECTLY]: "Store individually"
}

const headerStyle = {
    width: '100%',
    height: '56px',
    borderRadius: '4px',
    position: 'relative',
    backgroundColor: 'rgba(255,255,255,0.3)',
    transition: '0.3s all',
}


export function Group (name, method, item = null, items = []) {
    this.name = name;
    this.method = method;
    this.item = item;
    this.items = items
}

class SITSelectionAssets extends React.Component {

    iconStyle = {
        width: 20,
        height: 20
    };

    state = {
        items: [],
        groups: [
            new Group(GROUP_METHODS.DIRECTLY, GROUP_METHODS.DIRECTLY)
        ],
        printers: [],
        printer: {},
    }

    handleItemsHeaders = ['Code', 'Description', 'Length/cm', 'Width/cm', 'Height/cm', 'Weight/kg', 'Responsible', 'Org Unit', 'Stackable', 'Part', 'Print label'];
    handleItemsPropCodes = ['code', 'desc', 'length', 'width', 'height', 'weight', 'responsible', 'orgUnit', 'stackable', 'part', 'barcodeprint'];

    componentWillMount () {
        const { assetsForKit, canSkipPackaging, hasArticles, currentStore } = this.props;
        if (canSkipPackaging) {
            this.handleNextButtonClick()
        }
        if (DEBUG) {
            this.setState(
            { loading: false,
                items: [{"code":"CR-084477","desc":"Bac informatique pour recyclage","length":"120.0","width":"80.0","height":"100.0","weight":"500.0","systemStatus":"I","total":-1}, {"code":"CR-084478","desc":"Bac informatique pour recyclage","length":"120.0","width":"80.0","height":"100.0","weight":"500.0","systemStatus":"I","total":-1}]
            }, () => {
                if (DEBUG) {
                    this.addGroup(GROUP_METHODS.PACKAGE)
                    this.setState( prevState => ({
                            ...prevState,
                            groups: prevState.groups.map(
                                g=> ({
                                    ...g,
                                    items: g.method === GROUP_METHODS.PACKAGE ? prevState.items.map(item => item.code) : []
                                })
                            )
                    }))
                }
            })
            return;
        }

        WSFlexBuilding.getPrinters()
            .then((response) => {
                const printers = response.body.data.map((printer) => ({ label: printer[0], value: printer[0] }));
                this.setState({ printers, printer: printers.find(p => p.value === this.props.storeConfiguration?.printerCode) });
            });

        if (hasArticles) {
            this.setState({
                loading: false,
                items: assetsForKit.items,
                groups: this.state.groups.map(group => ({
                    ...group,
                    items: assetsForKit.items.map(item => item.code)
                }))
            }, () => {
                const { groups } = this.state;
                groups.forEach(group => {
                    this.props.addPackage({ id: group.name });
                    group.items.forEach(code => {
                        this.props.addItemToPackage({ packageId: group.name, itemId: code })
                    })
                })
            });
            return;
        } else {
            this.props.history.push(process.env.REACT_APP_FRONTEND + `${this.props.match.params.storeCode}/inbound/${this.props.match.params.sitCode}`)
        }
    }

    createKit = () => {
        let sitDoc =  {
            sitCode: this.props.match.params.sitCode,
            storeCode: this.props.currentStore.code
        };
        this.setState({ loading: true, isKitBuilt: false}, () => {
            let first = this.props.packagingMethod === "KIT" ?
                    WSFlexBuilding.createPartsAndAssets(sitDoc) :
                    WSFlexBuilding.createAssetsAndPackage(sitDoc);
            first
                .then(result => {
                    this.setState(prevState => ({
                        loading: false,
                        kitPartCode: result.body.data.partCode,
                        articles: result.body.data.assets,
                        //TODO will be multiple packages
                        packageAsset: result.body.data.packageAsset,
                        isKitBuilt: true
                    }));
                }).catch((error) => this.setState({loading: false}, () => this.props.handleError(error ? error : {type: "SERVER_ERROR", response: { body: { errors: [{code: "404 Not Found"}]}}}))
            )});
    }

    printBarcode = (asset) => () => {
        let barcodeInput = {
            type: 'E', // Set print for EQUIPMENT
            variables: [{
                code: asset.code, // Send info for main code to print,
                fields: {equipmentdesc: asset.desc, serialnumber: asset.serialno},
            }]
        };
        WSFlexBuilding.printBarcode(barcodeInput, this.state.printer?.value)
            .then(response => {
                console.log(response);
                this.props.showSuccess(response.body.data);
            })
            .catch(error => {
                if (error && error.response && error.response.body) {
                    this.props.showError('ERROR '+error.response.body.data);
                }
                this.props.handleError(error);
            });
    }

    validateFields = () =>
        this.mapItems('items', this.state.items).reduce(
            (acc, row) =>
                Object.values(row)
                    .filter(elem => elem && elem.props && elem.props.validate)
                    .reduce((acc2, field) =>
                        field.props.validate(field.props.value) && acc2
                , true) && acc
            , true);

    handleNextButtonClick = () => {
        if (!this.validateFields()) {
            this.props.showError('Invalid measurements', '', null);
            return;
        }

        let {currentStore} = this.props;
        let {items, packageAsset} = this.state;
        let sitAssetsInfo = {
            assets: items,
            storeCode: currentStore.code,
            packageAsset
        };

        //this.props.updateAssetsForKit(sitAssetsInfo);

        this.setState(prevState => ({
            ...prevState,
            loading: true
        }), _ =>



        this.conditionalUpdateEquipment(sitAssetsInfo)
            //TODO for KITS
            .then(this.props.packagingMethod === "KIT" ? () => WSFlexBuilding.createKitTemplate(sitAssetsInfo) : this.emptyPromise())
            .then( _ => this.createPackages() )
            .catch((error) => {
                this.setState({loading: false})
                console.log(error);
                this.props.handleError(error);
            })
        )
    };

    conditionalUpdateEquipment (sitAssetsInfo) {
        const itemsToUpdate = sitAssetsInfo.assets.filter(item => item.updated);
        //let packages = sitAssetsInfo.packageAsset ? [sitAssetsInfo.packageAsset] : [];
        //toUpdate = [...toUpdate, ...packages.filter(article => article.updated)]
        return itemsToUpdate.length > 0 ?
            WSFlexBuilding.updateArticles({
                ...sitAssetsInfo,
                assets: itemsToUpdate
            }) :
            this.emptyPromise();
    }

    compareArrays = (arr1, arr2) =>
    (
        (result => Array.isArray(result) && !result.length)
        (Array.isArray(arr1) && Array.isArray(arr2) && arr1.slice().reduce(
            (acc, el, index, originalArray) => {
                let i = acc.findIndex(elem => elem === el);
                if (i < 0){
                    // Return from reduce
                    originalArray.splice(1);
                    return false;
                }
                let b =  [].concat(acc.slice(0, i), acc.slice(i+1, acc.length))
                return b;
            }, arr2)
        )
    )

    waitOnAllPromises = promiseArray =>
        promiseArray.reduce((promiseChain, currentTask) =>
                promiseChain
                    .then(chainResults => currentTask()
                        .then(currentResult => [...chainResults, currentResult])
                    )
            ,
            Promise.resolve([]))


    createPackages = () => {
        let {currentStore, match} = this.props;
        let sitAssetsInfo = {
            assets: this.state.items,
            storeCode: currentStore.code,
            sitCode: match.params.sitCode
        };
        let promiseArray = this.state.groups
            .filter(g => g.method === GROUP_METHODS.PACKAGE)
            //.filter(g => g.items && g.items.length > 0)
            .map(g => ({
                ...g,
                items: g.items.map( itemCode => this.getItem(itemCode))
            }))
            .map(g => async () => WSFlexBuilding.createPackage({...sitAssetsInfo, assets: g.items, packageKey: g.name}))

        this.waitOnAllPromises(promiseArray)

        .then( responses => {
            let packages = responses.map(response => response.body.data);
            this.setState(
                prevState => ({
                    ...prevState,
                    groups: prevState.groups.map(
                        g => {
                            let newPackage = packages.find( pack => pack.packageKey === g.name);
                            return newPackage ?
                                ({
                                    ...g,
                                    item: newPackage.packageAsset
                                }) :
                                g
                        }
                    )
                }), () => {
                    let items = this.state.items;
                    let groups = this.state.groups;
                    let storeDirectly = groups
                        .filter(g => g.name === GROUP_METHODS.DIRECTLY)
                        .map(g => g.items.map(item => items.find(it => it.code === item)))
                        .reduce((acc, assetList) => acc.concat(assetList), [])
                        .map(item => new Group('', GROUP_METHODS.DIRECTLY, item))
                    groups = [].concat(storeDirectly, groups.filter(g => g.method !== GROUP_METHODS.DIRECTLY))

                    this.props.updateAssetsForKit({
                        ...this.props.assetsForKit,
                        items,
                        groups
                    })
                    this.props.history.push(process.env.REACT_APP_FRONTEND + `${currentStore.code}/inbound/${this.props.match.params.sitCode}/items/bins`)
            });
        })
        .catch((error) => {
            console.log(error);
            this.props.handleError(error);
            this.setState({loading: false})
        })
    }

    emptyPromise = () => new Promise(resolve => resolve());


    handleBackButtonClick = () => {
        this.props.history.push(process.env.REACT_APP_FRONTEND + `${this.props.currentStore.code}/inbound/`)
    };

    changeFieldInArray = (key, value, arrayField, code) => {
        let index = this.state[arrayField].findIndex(it => it.code === code);
        this.setState(
            prevState =>
            ({
                ...prevState,
                [arrayField]: Object.assign([...prevState[arrayField]], {[index]: {
                    ...prevState[arrayField][index],
                    updated: true,
                    [key]: value
                }})
            })
        )
    }

    addGroup = method => {
        let name = `${method} ${this.state.groups.filter(g => g.method == method).length + 1}`;
        this.props.addPackage({ id: name });
        this.setState(prevState => ({
            ...prevState,
            groups: [...prevState.groups, new Group(name, method)]
        }))
    }

    changeGroup = (key, value, item) => {
        const groupName = value;
        this.props.addItemToPackage({ packageId: groupName, itemId: item.code });
        this.setState(prevState => ({
            ...prevState,
            groups: prevState.groups
            .map( g => ({
                ...g,
                        items: [
                            ...g.items.filter(it => it !== item.code),
                            ...(g.name === value ? [item.code] : [])
                        ]
                    }))
                }))
    }
    // Flattens the items inside the groups
    isInGroup = (item, groups) => [].concat.apply([], [...groups.map(g => g.items)]).includes(item.code);

    findGroup = (item, groups) => {
        let group = groups.find(g => g.items.includes(item.code))
        return group ? group.name : undefined;
    }

    releaseGroup = groupName  => {
        this.props.removePackage({ id: groupName });
        this.setState(prevState => ({
            ...prevState,
            groups: prevState.groups.filter(g => g.name != groupName)
                // prevState.groups
                //     .map( g => g.name === groupName ? ({
                //         ...g,
                //         items: []
                //     }) : g.items)
        }), () => {
            if (!this.state.groups.find(g => g.name === GROUP_METHODS.DIRECTLY)) {
                this.setState(prevState =>
                    ({
                         ...prevState,
                         groups: [...prevState.groups, new Group(GROUP_METHODS.DIRECTLY, GROUP_METHODS.DIRECTLY)]
                     }))
            }}
        )
    }

    getItem = itemCode => this.state.items.find( item => item.code === itemCode )

    removeItem = (group, item) => {
        this.props.removeItemFromPackage({ packageId: group.name, itemId: item.code });
        this.setState(prevState => ({
            ...prevState,
            groups: prevState.groups
                    .map( g => ({
                        ...g,
                        items: g.items.filter( it => it !== item.code)
                    }))
        }))
    }

    addRemoveIcon = (group, item) => {
        return ({
            remove:
                (<CloseCircle
                    onClick={ _ => this.removeItem(group, item)}
                    style={{color: 'rgba(230, 63, 43, 0.9)'}}>
                </CloseCircle>),
            ...item
        })
    }

    addSelectGroup = item => ({
        group:
        (<EAMSelect
            value={this.findGroup(item, this.state.groups)}
            onChange={createOnChangeHandler(
                'group', null, null, this.changeGroup, null, [item]
            )}
            style={{ width: 150 }}
            options={this.state.groups.map( group => ({code: group.name, desc: group.name}))}
            />),
        ...item
    })

    mapItems = (arrayField, articles, callback) =>
        articles.map( (item, index) => {
                return ({
                    code: item.code,
                    desc: getEditableMeasurement(arrayField, item, 'desc', callback, false),
                    orgUnit: <EAMSelect
                            value={item.orgUnit}
                            onChange={createOnChangeHandler(
                                'orgUnit', null, null, callback, null, [arrayField, item.code]
                            )}
                            style={{ width: 110 }}
                            options={this.props.applicationData.orgUnits}
                        />,
                    stackable:  <Checkbox
                        style={{align: 'center', padding: 0, margin: 0}}
                        checked={item.stackable === '+'}
                        onChange={(event) => callback('stackable', event.target.checked ? '+' : '-', arrayField, item.code)}
                    />,
                    material: item.material,
                    weight: getEditableMeasurement(arrayField, item, 'weight', callback),
                    length: getEditableMeasurement(arrayField, item, 'length', callback),
                    width: getEditableMeasurement(arrayField, item, 'width', callback),
                    height: getEditableMeasurement(arrayField, item, 'height', callback),
                    part: item.part,
                    location: item.locationCode,
                    responsible:  <EAMAutocomplete
                        value={item['responsible'] || ''}
                        onChange={createOnChangeHandler(
                            'responsible', null, null, callback, null, [arrayField, item.code]
                        )}
                        autocompleteHandler={key => WS.autocompleteEmployee(key)}
                    />,
                    barcodeprint:
                        (<Button variant="contained" color="primary" onClick={this.printBarcode(item)}>
                            Print <Barcode style={{...this.iconStyle, marginLeft: 4}}/>
                        </Button>),
                    needingCompletion: !(item.weight && item.length && item.width && item.height && item.responsible),
            })});

    render () {
        //Validate that there is a current store
        if (!this.props.currentStore || !this.state.items) return null;
        const {loading, items, groups} = this.state;
        const unsortedItems = items.filter(item => !this.isInGroup(item, groups));
        const isFinished = !unsortedItems || unsortedItems.length === 0;
        return (
            <BlockUi tag="div" blocking={loading} style={{margin: 0, height: '100%', overflowX: 'auto', overflowY: 'auto'}}>
                {
                    items &&
                    <React.Fragment>
                         <div style={{display: 'flex', justifyContent: 'center', paddingTop: 10}}>
                            <KioskButton
                                disabled={isFinished}
                                onClick={ _ => this.addGroup(GROUP_METHODS.PACKAGE) }
                                style={{padding: 2, marginRight: 15, marginLeft: 'auto'}}
                            >
                                Add Package
                            </KioskButton>
                            <KioskButton
                                disabled={!isFinished}
                                onClick={ this.handleNextButtonClick }
                                style={{padding: 2, marginRight: 'auto'}}
                            >
                                Next
                            </KioskButton>
                            <Select
                                options={this.state.printers}
                                onChange={(option) => this.setState({ printer: option })}
                                styles={{ marginLeft: 'auto' }}
                                style={{ width: 200 }}
                                value={this.state.printer}
                            />
                        </div>

                        {unsortedItems && unsortedItems.length > 0 &&
                            (<div style={{marginBottom: '20px'}}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flexWrap: 'wrap',
                                    justifyContent: 'center',
                                    width: '100%',
                                    fontFamily: 'Trebuchet MS',
                                    fontSize: '20px',
                                    color: 'rgba(255, 0, 0, 0.7)',
                                    marginBottom: 0,
                                    paddingBottom: 0
                                }}> To be treated:</div>
                                <EISTable
                                    style={{border: '1px'}}
                                    data={this.mapItems('items', unsortedItems, this.changeFieldInArray).map(item => this.addSelectGroup(item))}
                                    //style={{ order: 3, display: 'flex', flexGrow: 1}}
                                    headers={['Group', ...this.handleItemsHeaders]}
                                    propCodes={['group', ...this.handleItemsPropCodes]}
                                    stylesMap={{
                                        needingCompletion: {
                                            backgroundColor: "rgba(255, 255, 204, 0.85)"
                                        }
                                    }}
                                />
                            </div>)
                        }

                        {groups
                            .filter(g => g.items.length > 0 || g.method === GROUP_METHODS.PACKAGE)
                            .map( (group, index) =>
                             (<div
                                    key={`outer_${index}`}
                                    style={{marginTop: '10px', marginBottom: '10px', padding: '5px', border: '3px solid #2196F3', borderRadius: '8px'}}>
                                <div key={index} style={{display:'flex', flexDirection:'row'}} id="wrapper">
                                    <CloseCircle
                                        key={`icon_${index}`}
                                        onClick={ _ => this.releaseGroup(group.name)}
                                        style={{paddingRight: '6px', color: 'rgba(239, 43, 43, 0.9)'}}>
                                    </CloseCircle>
                                    <div
                                        key={`text_${index}`}
                                        style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        flexWrap: 'wrap',
                                        justifyContent: 'center',
                                        width: '100%',
                                        fontFamily: 'Trebuchet MS',
                                        fontSize: '22px',
                                        color: '#2196F3',
                                        marginBottom: 0,
                                        paddingBottom: 0
                                    }}>{TRANSLATIONS[group.name] || group.name }</div>
                                </div>
                                <EISTable   data={this.mapItems('items', group.items.map(it => items.find(el => el.code === it)), this.changeFieldInArray).map(item => this.addRemoveIcon(group, item))}
                                            //style={{ order: 3, display: 'flex', flexGrow: 1}}
                                            headers={['', ...this.handleItemsHeaders]}
                                            propCodes={['remove', ...this.handleItemsPropCodes]}
                                            stylesMap={{
                                                needingCompletion: {
                                                    backgroundColor: "rgba(255, 255, 204, 0.85)"
                                                }
                                            }}
                                />
                            </div>)
                        )
                        }
                    </React.Fragment>
                }
            </BlockUi>
        );
    }
}

export default SITSelectionAssets;