import { handleError, openConfirmDialog, showSuccess, showError } from '../../../react-eis-components/actions/uiActions';
import { connect } from 'react-redux';
import { logout } from "../../../actions/applicationActions";
import { getRoutes } from "../../../tools/routes";
import Bin2BinPage from "./Bin2BinPage";

const mapStateToProps = (state, ownProps) => {
    const language = state.layout.lang;
    const store = state.application.currentStore;
    const storeCode = ownProps.match.params.storeCode;
    const storeConfiguration = state.application.storesConfiguration[storeCode];

    return {
        kioskMode: state.application.kioskMode,
        applicationData: state.application.applicationData,
        storeConfiguration,
        stores: state.application.storeData,
        translations: state.application.translations[language]['KIOSK_' + storeCode],
        storeCode,
        requestProcess: state.requestProcess,
        routes: getRoutes(process.env.REACT_APP_FRONTEND, storeCode),
        storeAccess: state.application.storeAccess,
        currentStore: state.application.currentStore,
    }
};

const Bin2BinPageContainer = connect(
    mapStateToProps, {
        logout,
        handleError,
        showSuccess,
        showError,
        openConfirmDialog
    }
)(Bin2BinPage);

export default Bin2BinPageContainer;