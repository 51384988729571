import React from 'react';
import EAMAutocomplete from 'eam-components/dist/ui/components/inputs-ng/EAMAutocomplete';
import EAMSelect from 'eam-components/dist/ui/components/inputs-ng/EAMSelect';
import WS from '../../../../../tools/rest/WS';
import { createOnChangeHandlerObjectUpdate } from 'eam-components/dist/ui/components/inputs-ng/tools/input-tools';

const CODE_KEY = 'workOrderNumber';
const DESC_KEY = 'workOrderDesc';

class ContextSelectionWorkOrder extends React.Component {

    state = {};

    componentWillMount() {
        let wo = this.props.currentTransaction.workOrderNumber;
        if (wo) {
            this.readActivities(wo)
        }
    }

    componentWillReceiveProps(newProps) {
        let newwo = newProps.currentTransaction.workOrderNumber;
        let oldwo = this.props.currentTransaction.workOrderNumber;
        // Clean up activities when new wo is not there
        if (!newwo) {
            this.setState({activities: null})
        }

        // Read activities only when the WO has changed and is defined
        if (newwo && (newwo !== oldwo)) {
            this.readActivities(newwo)
        }
    }

    getActivitySelectValues = () => {
        return this.state.activities.map(activity => ({
                    code: activity.activityCode,
                    desc: activity.tradeCode
                }))
    };

    readActivities = (workorder) => {
        WS.readWorkOrderActivities(workorder)
            .then(response => {
                let activities = response.body.data;
                this.setState({activities});
                // If the WO has only one activity set it
                if (activities.length === 1) {
                    this.props.updateTransaction({activityCode: activities[0].activityCode})
                }
            })
    };

    render() {
        const { currentTransaction, updateTransaction } = this.props;
        const { workOrderNumber, workOrderDesc, activityCode, storeCode } = currentTransaction;

        return (
        <div>
            <EAMAutocomplete
                label="Work Order"
                value={workOrderNumber}
                desc={workOrderDesc}
                onChange={createOnChangeHandlerObjectUpdate(
                    CODE_KEY, DESC_KEY, null, updateTransaction, null
                )}
                autocompleteHandler={(key) => WS.autocompleteWorkorder(storeCode, 'issue', key)}
                // autoFocus // TODO: needs update of inputs-ng
                // autoSelectSingleElement // TODO: needs update of inputs-ng
                // creatable // TODO: needs update of inputs-ng
                barcodeScanner
            />

            {this.state.activities && this.state.activities.length > 0 &&
                <React.Fragment>
                    <EAMSelect
                        label="Activity"
                        value={activityCode}
                        renderValue={(value) => value.desc || value.code}
                        onChange={createOnChangeHandlerObjectUpdate(
                            'activityCode', null, null, updateTransaction, null
                        )}
                        options={this.getActivitySelectValues()}
                    />
                </React.Fragment>
            }
        </div>
    )}
}

export default ContextSelectionWorkOrder;