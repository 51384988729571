import { handleError, showError, showSuccess } from '../../../../react-eis-components/actions/uiActions';
import { connect } from 'react-redux';
import { logout } from "../../../../actions/applicationActions";
import { updateAssetsForKit } from "../../../../actions/flexBuildingActions";
import { placePartOnBin } from "../../../../actions/inboundProcessActions";
import { setPage } from "../../../../actions/layoutActions";
import { getParts } from ".././../../../reducers/inboundProcessReducer";
import SITSelectionBins from "./SITSelectionBins";


const mapStateToProps = (state, ownProps) => {
    const currentTransaction= state.currentTransaction;
    const language = state.layout.lang;
    const store = state.application.currentStore;
    const storeConfiguration = store ? state.application.storesConfiguration[store.code] : {};
    const groupCode = ownProps.match.params.item;
    const part = getParts(state)[groupCode];

    return {
        kioskMode: state.application.kioskMode,
        applicationData: state.application.applicationData,
        currentTransaction: currentTransaction,
        currentStore: store,
        storeConfiguration: storeConfiguration,
        translations: store ? state.application.translations[language]['KIOSK_' + store.code] : {},
        assetsForKit: state.flexBuilding.assetsForKit,
        groupCode,
        part,
    }
};

const SITSelectionBinsContainer = connect(
    mapStateToProps, {
        setPage,
        logout,
        handleError,
        showSuccess,
        showError,
        updateAssetsForKit,
        placePartOnBin
    }
)(SITSelectionBins);

export default SITSelectionBinsContainer;