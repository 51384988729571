import React, { Component } from "react";
import OutboundItemListContainer from "./OutboundItemListContainer";
import WSFlexBuilding from '../../../../tools/rest/WSFlexBuilding';

export class OutboundItemListLoader extends Component {
    state = {
        awaitingForRequest: false
    }
    componentDidMount() {
        this.load();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.pickTicketCode !== this.props.pickTicketCode) {
            this.load();
        }
    }

    load() {
        const { setOutboundPickTicket, setOutboundItems, match, handleError } = this.props;
        const pickTicketCode = match.params.id;
        if (!this.state.awaitingForRequest) {
            this.setState({ awaitingForRequest: true })
            WSFlexBuilding.getPickTicketItems(pickTicketCode)
                .then(resp => {
                    setOutboundPickTicket(resp.body.data);
                    setOutboundItems(resp.body.data.lines);
                })
                .catch(handleError)
                .finally(() => {
                    this.setState({ awaitingForRequest: false })
                })
        }
    }

    render() {
        const { awaitingForRequest } = this.state;
        return <OutboundItemListContainer {...this.props} awaitingForRequest={awaitingForRequest} />;
    }
}

export default OutboundItemListLoader;
