import React from 'react';
import SelectionPartsDetailsContainer from "./SelectionPartsDetailsContainer";
import WS from '../../../../tools/rest/WSPartSelection';
import TransactionTypes from "../../../../enums/TransactionTypes";
import withStyles from '@mui/styles/withStyles';
import BlockUi from 'react-block-ui';
import ErrorTypes from 'enums/ErrorTypes'; // TODO: keep?

const classes = {
    papercontainer: {
        marginTop: '10px',
        marginBottom: '10px',
        paddingTop: '10px',
        paddingLeft: '5px'
    },
    '@media (min-width: 400px)': {
        papercontainer: {
            padding: '30px',
            margin: '10px',
            border: '1px solid lightgray'
        }
    }
};

class SelectionParts extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            bin: this.props.bin || '',
            lot: this.props.lot || '',
            assetCode: '',
            quantity: 1,
            partCode: this.props.partCode || '',
            partOrAssetData: {},
            loading: false
        };
    }

    validateAllRequiredData = () => {
        if (!this.state.partCode) {
            this.props.showWarning("No part selected.");
            return false;
        }

        if (!this.state.bin) {
            this.props.showWarning("No bin is selected.");
            return false;
        }

        if (!this.state.lot) {
            this.props.showWarning("No lot is selected.");
            return false;
        }

        if (+this.state.quantity <= 0 && !this.state.partOrAssetData.trackedByAsset) {
            this.props.showWarning("Quantity must be greater than 0.");
            return false;
        }

        if (this.state.availableQuantity && +this.state.quantity > +this.state.availableQuantity) {
            this.props.showWarning(`Quantity must be less than or equal to ${this.state.availableQuantity}.`);
            return false;
        }

        if (this.state.partOrAssetData.isTrackedByAsset && !this.state.assetCode) {
            this.props.showWarning("No asset for part tracked by asset.");
            return false;
        }

        return true;
    };

    addToList = () => {
        if (!this.validateAllRequiredData()) {
            return;
        }

        let isThereADuplicate = false;
        const isAssetBeingAdded = ((this.state.entityType === "ASSET") || (this.state.partOrAssetData.trackedByAsset));

        const isPartSelected = this.state.partCode && this.state.entityType !== 'ASSET'
        const isTrackedByAsset = this.state.partOrAssetData && this.state.partOrAssetData.trackedByAsset;
        const { assetCode } =  this.state;

        if (isPartSelected && isTrackedByAsset && !assetCode) {
            this.props.showWarning("You must select an asset for this part.");
            isThereADuplicate = true;
        }

        this.props.currentTransaction.transactionlines.forEach(line => {
            // if the code input into the first autocomplete was an asset
            // or the part is tracked by asset
            if (isAssetBeingAdded) {
                if (this.state.entityType === "ASSET" && line.assetIDCode === this.state.partCode) {
                    this.props.showWarning("Asset " + this.state.partCode + " already added.");
                    isThereADuplicate = true;
                }

                if (this.state.entityType === "PART" && line.assetIDCode === this.state.assetCode) {
                    this.props.showWarning("Asset " + this.state.assetCode + " already added.");
                    isThereADuplicate = true;
                }

            } else {
                // if it's part tracked by lot
                if (line.partCode === this.state.partCode &&
                    line.bin === this.state.bin &&
                    line.lot === this.state.lot) {
                    this.props.showWarning("Part " + this.state.partCode + " already added from bin " + this.state.bin + " and lot " + this.state.lot + ".");
                    isThereADuplicate = true;
                }
            }
        });

        if (isThereADuplicate) {
            return;
        }

        const quantity = this.state.entityType === "ASSET" || this.state.partOrAssetData.trackedByAsset ? 1 : this.state.quantity;

        if (this.state.entityType === "PART") {
            this.props.addLineToTransaction({
                partCode: this.state.partCode,
                partDesc: this.state.partDesc,
                partOrg: '*',
                assetIDCode: this.state.assetCode,
                assetIDDesc: null,
                bin: this.state.bin,
                lot: this.state.lot,
                transactionQty: quantity,
                uom: this.state.partOrAssetData.uom,
            })
        }

        if (this.state.entityType === "ASSET") {
            this.props.addLineToTransaction({
                partCode: this.state.partOrAssetData.part,
                partDesc: this.state.partOrAssetData.partDescription,
                partOrg: '*',
                assetIDCode: this.state.partCode,
                assetIDDesc: null,
                bin: this.state.bin,
                lot: this.state.lot,
                transactionQty: 1,
                uom: this.state.partOrAssetData.uom,
            })
        }

        this.resetValues();
    };

    updateItem = () => {
        if (!this.validateAllRequiredData()) {
            return;
        }

        this.props.removeLineFromTransaction(this.props.selectedRowIndexes[0]);

        this.props.addLineToTransaction({
            partCode: this.state.partCode,
            partDesc: this.state.partDesc,
            partOrg: '*',
            assetIDCode: null,
            assetIDDesc: null,
            bin: this.state.bin,
            lot: this.state.lot,
            transactionQty: this.state.quantity
        });

        this.resetValues();

        this.setState(() => ({
            partCode: ''
        }));

        this.props.updateProperty("selectedRowIndexes", []);
    };

    handleBinMapping = (value) => {
        const {configuration} = this.props;
        if (configuration) {
            this.setState({loading: true})
            WS.getEquipmentStatus(value)
                .then(response => {
                    const data = response.body.data;
                    const binMapping = configuration['binmapping'];
                    if (data && binMapping) {
                        const val = binMapping[data];
                        if (val) {
                            this.setState(() => ({
                                bin: val
                            }), () => {
                                return this.fetchLotValues(val);
                            });
                        }
                    }
                    this.setState({loading: false})
                })
                .catch(_ => this.setState({loading: false}));
        }
    };

    fetchPartOrAssetData = (partCode) => {
        this.setState({loading: true});
        if (this.state.entityType === "PART") {
            WS.getPartData(partCode, this.props.currentStore.code, this.props.currentTransaction.transactionType)
                .then(response => {
                    let data = response.body.data;

                    //     EAM-2233
                    if (data.bins.length === 1 && data.bins[0].code === '') {
                        throw {
                            type: 'SERVER_ERROR',
                            response: {
                                body: {
                                    errors: [{detail: `The part ${partCode} has no available stock in this store.`, title: `Could not fetch bins`}],
                                },
                                status: 400,
                            }
                        };
                    }
                    // filter out duplicate bins
                    let bins = data.bins.filter((item, index) => {
                        let foundIndex = -1;

                        for (let i = 0; i < data.bins.length; i++) {
                            if (item.code === data.bins[i].code && item.desc === data.bins[i].desc) {
                                foundIndex = i;
                            }
                        }

                        return (foundIndex === index)
                    });

                    bins.sort((a,b) => a.code.length - b.code.length)

                    this.setState(() => ({
                        partOrAssetData: {
                            ...data,
                            allBinsAvailableForPart: bins,
                            bins: bins
                        },
                    }));

                    if (response.body.data.bins.length > 0) {
                        let defaultBin = null;
                        let bins = response.body.data.bins;

                        // if only one bin, it's the default bin
                        if (bins.length === 1) {
                            defaultBin = bins[0];
                        }

                        // check if some bin is defined as default
                        let definedDefaultBin = bins.filter(bin => bin.default);
                        if (definedDefaultBin.length === 1) {
                            defaultBin = definedDefaultBin[0];
                        }

                        if (defaultBin) {
                            this.fetchLotValues(defaultBin.code);
                        }

                        this.setState(() => ({
                            bin: defaultBin ? defaultBin.code : null
                        }));

                        if (!defaultBin) {
                            this.setState({loading: false});
                        }

                    } else {
                        this.props.showWarning("No bins are available for this part.")
                    }

                })
                .catch(error => {
                    this.resetValues();
                    this.props.handleError(error);
                })
        }

        if (this.state.entityType === "ASSET") {
            WS.getAssetData(partCode, this.props.currentStore.code, this.props.currentTransaction.transactionType)
                .then(response => {
                    const data = response.body.data;

                    if (this.props.currentTransaction.transactionType === TransactionTypes.ISSUE) {
                        this.setState({
                            lot: data.lot,
                            bin: data.bin,
                            partOrAssetData: {
                                // when issuing an asset, there is only one bin and lot possible
                                lots: [{code: data.lot, desc: data.lot}],
                                bins: [{code: data.bin, desc: data.bin}],
                                part: data.part,
                                partDescription: data.partDescription
                            },
                            assetDepartment: data.department,
                            assetGroupResponsibleEmails: data.groupResponsibleEmails,
                            loading: false
                        });
                    }

                    if (this.props.currentTransaction.transactionType === TransactionTypes.RETURN) {

                        if (response.body.data.bins.length > 0) {
                            let defaultBin = null;
                            let bins = response.body.data.bins;

                            // if only one bin, it's the default bin
                            if (bins.length === 1) {
                                defaultBin = bins[0];
                            }

                            // check if some bin is defined as default
                            let definedDefaultBin = bins.filter(bin => bin.default);
                            if (definedDefaultBin.length === 1) {
                                defaultBin = definedDefaultBin[0];
                            }

                            if (defaultBin) {
                                this.fetchLotValues(defaultBin.code);
                            }

                            // filter out duplicate bins
                            bins = bins.filter((item, index) => {
                                let foundIndex = -1;

                                for (let i = 0; i < bins.length; i++) {
                                    if (item.code === bins[i].code && item.desc === bins[i].desc) {
                                        foundIndex = i;
                                    }
                                }

                                return (foundIndex === index);
                            });

                            this.setState((prevState) => ({
                                partOrAssetData: {
                                    ...data,
                                    bins: bins
                                },
                                lot: data.lot,
                                bin: defaultBin ? defaultBin.code : prevState.bin,
                                assetDepartment: data.department,
                                assetGroupResponsibleEmails: data.groupResponsibleEmails,
                                loading: false
                            }), () => {
                                this.handleBinMapping(partCode);
                            });

                        } else {
                            this.props.showWarning("No bins are available for this asset.")
                        }

                    }
                })
                .catch(err => {
                    this.resetValues();
                    this.props.handleError(err);
                })
        }
    };

    fetchAssetDataWhenPartSelected = assetCode => {
        this.setState({loading: true})
        WS.getAssetData(assetCode, this.props.currentStore.code, this.props.currentTransaction.transactionType)
            .then(response => {
                const data = response.body.data;

                this.setState((prevState) => ({
                    bin: data.bin,
                    lot: data.lot,
                    partOrAssetData: {
                        ...prevState.partOrAssetData,
                        lots: [{code: data.lot, desc: data.lot}],
                        bins: [{code: data.bin, desc: data.bin}],
                    },
                    assetDepartment: data.department,
                    assetDepartmentResponsibleEmails: data.departmentResponsibleEmails,
                    quantity: 1,
                    loading: false
                }), () => {
                    this.handleBinMapping(assetCode);
                });
            })
            .catch(_ => this.setState({loading: false}))
    };

    fetchLotValues = (bin) => {
        if (!this.state.partCode || !bin) return;
        this.setState({loading: true}); // TODO: causes re-opening of bin's dropdown list (w/ inputs-ng)
        WS.getLots(this.state.partCode, this.props.currentStore.code, this.props.currentTransaction.transactionType, bin, null)
            .then(response => {
                if (response.body.data.length > 0) {
                    const defaultLot = response.body.data[0];

                    this.setState((prevState) => ({
                        partOrAssetData: {
                            ...prevState.partOrAssetData,
                            lots: response.body.data,
                        },
                        lot: defaultLot.code || null,
                        loading: false
                    }));

                    this.fetchAvailableQuantity(defaultLot.code);

                } else {
                    this.props.showWarning("No lots are available for this bin and part.");
                }

            }).catch(error => {
                this.props.handleError(error);
                this.resetValues();
                this.setState({ partCode: ''});
            })
    };

    // TODO: check error handling on the else branch
    fetchAvailableQuantity = lot => {
        if (this.props.currentTransaction.transactionType !== TransactionTypes.ISSUE) {
            return;
        }
        if (!this.state.partCode || !this.state.bin) return;
        this.setState({loading: true}) // TODO: causes re-opening of bin's dropdown list (w/ inputs-ng)
        WS.getAvailableQuantity(this.props.currentStore.code, this.state.bin, lot, this.state.partCode)
            .then(response => {
                this.setState(() => ({
                    availableQuantity: response.body.data,
                    loading: false
                }));
            })
            .catch(error => {
                if (error.response && error.response.body && error.response.body.errors && error.response.body.errors.length) {
                    this.props.handleError(error);
                } else {
                    this.props.handleError({
                        type: ErrorTypes.SERVER_ERROR,
                        response: {
                            body: {
                                errors: [{detail: error.response.body.message, title: `Could not fetch available quantity`}],
                            },
                            status: 400,
                        }
                    });
                }
                this.resetValues();
                this.setState({ partCode: ''});
            })
    };

    resetValues = () => {
        this.setState((prevState) => ({
            partCode: prevState.assetCode ? prevState.partCode : null,
            bin: '',
            lot: '',
            bins: prevState.partOrAssetData.allBinsAvailableForPart,
            lots: '',
            assetCode: '',
            assetDesc: '',
            quantity: 1,
            availableQuantity: undefined,
            entityType: prevState.partOrAssetData && prevState.partCode === prevState.partOrAssetData.part && prevState.partOrAssetData.trackedByAsset ? prevState.entityType : '',
            loading: false
        }));
    };

    updateProperty = (key, value, extra = {}) => {
        this.setState(() => ({
            [key]: value,
            ...extra
        }), () => {
        // when a part is selected
        if (key === "partCode" && value) {
            this.fetchPartOrAssetData(value);
            if (this.state.entityType === 'ASSET') {
                this.setState(() => ({
                    quantity: 1
                }));
            }
        }

        // when an asset is selected after previously selecting part
        if (key === "assetCode" && value && this.props.currentTransaction.transactionType === TransactionTypes.ISSUE) {
            this.fetchAssetDataWhenPartSelected(value);
        }

        // if part is removed
        if (key === "partCode" && !value) {
            this.resetValues();
        }

        // if bin is selected by user
        if (key === "bin" && value) {
            this.fetchLotValues(value);

            this.setState(() => ({
                binSelectedByUser: true
            }));
        }

        // if lot is selected
        if (key === "lot" && value) {
            this.fetchAvailableQuantity(value);
        }

        // if bin is removed by user
        if (key === "bin" && !value) {
            this.setState((prevState) => ({
                binSelectedByUser: false,
                partOrAssetData: {
                    ...prevState.partOrAssetData,
                    lots: null
                },
                lot: null
            }));
        }

        /// if removing bin or lot, remove the available quantity information
        if ((key === "bin" || key === "lot") && !value) {
            this.setState(() => ({
                availableQuantity: undefined
            }));
        }

        // when the bin was automatically prefilled and an asset is selected or asset is removed
        if (!this.state.binSelectedByUser && key === "assetCode" && !value) {
            this.setState((prevState) => ({
                bin: null,
                partOrAssetData: {
                    ...prevState.partOrAssetData,
                    bins: prevState.partOrAssetData.allBinsAvailableForPart,
                    lots: null
                },
                lot: null
            }));
        }
    });
    };

    render() {
        const isEditMode = (this.props.selectedRowIndexes.length === 1) && this.props.currentTransaction.transactionlines[this.props.selectedRowIndexes[0]]
            && !this.props.currentTransaction.transactionlines[this.props.selectedRowIndexes[0]].assetIDCode;

        const {classes} = this.props;

        return (
            <div className={classes.papercontainer}>
                <BlockUi blocking={this.state.loading}>
                    <SelectionPartsDetailsContainer
                        updateProperty={this.updateProperty}
                        setRef={this.setRef}
                        partCode={this.state.partCode}
                        partDesc={this.state.partDesc}
                        bin={this.state.bin}
                        lot={this.state.lot}
                        bins={this.state.partOrAssetData.bins?.map(({code, desc}) => ({code, desc: `${code} - ${desc}`}))}
                        lots={this.state.partOrAssetData.lots?.map(({code, desc}) => ({code, desc: `${code}${desc !== '-'? ` - ${desc}` : ''}`}))}
                        quantity={this.state.quantity}
                        assetCode={this.state.assetCode}
                        assetDesc={this.state.assetDesc}
                        assetDepartment={this.state.assetDepartment}
                        assetGroupResponsibleEmails={this.state.assetGroupResponsibleEmails}
                        isTrackedByAsset={this.state.partOrAssetData && this.state.partOrAssetData.trackedByAsset}
                        isAssetSelected={this.state.partCode && this.state.entityType === 'ASSET'}
                        isPartSelected={this.state.partCode && this.state.entityType !== 'ASSET'}
                        availableQuantity={this.state.availableQuantity}
                        entityType={this.state.entityType}
                        addToList={this.addToList}
                        updateItem={this.updateItem}
                        isEditMode={isEditMode}
                        selectedRowIndexes={this.props.selectedRowIndexes}
                        setLoading={loading => this.setState({loading})}
                        partUom={this.state.partOrAssetData.uom}
                    />
                </BlockUi>
            </div>
        )
    }
}

export default withStyles(classes)(SelectionParts);