import {connect} from 'react-redux';
import {setPage} from "../../../../actions/layoutActions";
import {handleError} from '../../../../react-eis-components/actions/uiActions';
import KITContextSelectionPage from "./KITContextSelectionPage";
import {updateApplication, logout} from "../../../../actions/applicationActions";
import {
    updateTransaction,
    updateTransactionType,
    setTransactionOnWorkorder,
    setTransactionOnProject,
    setTransactionOnEquipment,
    setTransactionOnEmployee
} from "../../../../actions/currentTransactionActions";

const mapStateToProps = (state, ownProps) => {

    const transactionType = ownProps.match.params.transactiontype;
    const kitcode = ownProps.match.params.partcode;
    const store = state.application.currentStore;
    const storeConfiguration = store ? state.application.storesConfiguration[store.code] : {};
    return {
        kioskMode: state.application.kioskMode,
        currentStore: store,
        currentTransaction: state.currentTransaction,
        transactionType: transactionType,
        storeConfiguration: storeConfiguration,
        kitcode: kitcode
    }
};

const KITContextSelectionPageContainer = connect(
    mapStateToProps, {
        setPage,
        updateApplication,
        updateTransaction,
        logout,
        updateTransactionType,
        setTransactionOnWorkorder,
        setTransactionOnProject,
        setTransactionOnEquipment,
        setTransactionOnEmployee,
        handleError
    }
)(KITContextSelectionPage);

export default KITContextSelectionPageContainer;
