import actionTypes from "../actionTypes/inboundProcessActionTypes";

const initialState = {
    sitDoc: {},
    articleLines: {},
    assets: {},
    parts: {},
    relations: {},
    transactionLines: {},
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.RESET: {
            return { ...initialState };
        }
        case actionTypes.UPDATE_PARTS: {
            const { parts } = action.payload;
            return {
                ...state,
                parts: parts
            }
        }
        case actionTypes.UPDATE_ASSETS: {
            const { assets } = action.payload;
            return {
                ...state,
                assets: assets
            }
        }
        case actionTypes.UPDATE_TRANSACTION_LINES: {
            const { transactionLines } = action.payload;
            return {
                ...state,
                transactionLines: transactionLines
            }
        }
        case actionTypes.UPDATE_SIT_DOC: {
            const { sitDoc } = action.payload;
            return {
                ...state,
                sitDoc: sitDoc
            }
        }
        case actionTypes.UPDATE_ARTICLE_LINES: {
            const { articleLines } = action.payload;
            return {
                ...state,
                articleLines: articleLines
            }
        }
        case actionTypes.UPDATE_RELATIONS: {
            const { relations } = action.payload;
            return {
                ...state,
                relations: relations
            }
        }
        case actionTypes.ADD_ITEM_TO_PACKAGE: {
            const { packageId, itemId } = action.payload;
            const packageItems = state.relations[packageId] || [];
            return {
                ...state,
                relations: {
                    ...state.relations,
                    [packageId]: [...packageItems, itemId]
                }
            }
        }
        case actionTypes.REMOVE_ITEM_FROM_PACKAGE: {
            const { packageId, itemId } = action.payload;
            const packageItems = state.relations[packageId] || [];
            return {
                ...state,
                relations: {
                    ...state.relations,
                    [packageId]: packageItems.filter(e => e !== itemId)
                }
            }
        }
        case actionTypes.ADD_PACKAGE: {
            const { id } = action.payload;
            return {
                ...state,
                relations: {
                    ...state.relations,
                    [id]: []
                }
            }
        }
        case actionTypes.REMOVE_PACKAGE: {
            const { id } = action.payload;
            const { [`${id}`]: _, ...rest } = state.relations;
            return {
                ...state,
                relations: rest
            }
        }

        case actionTypes.PLACE_PART_BIN: {
            const { part } = action.payload;
            const { bin } = action.payload;
            return {
                ...state,
                transactionLines: {
                    ...state.transactionLines,
                    [part]: bin
                }
            }
        }

        default:
            return state;
    }
}

const localState = state => state.inboundProcess;

export const getAssets = state => localState(state).assets;

export const getParts = state => localState(state).parts;

export const getTransactionLines = state => localState(state).transactionLines;

export const getSitDoc = state => localState(state).sitDoc;

export const getArticleLines = state => localState(state).articleLines;

export const getRelations = state => localState(state).relations;

export const canSkipPackaging = state => (
    !Object.keys(localState(state).assets).length
    && Object.keys(localState(state).parts).length)

export const hasArticles = state =>
    [Object.keys(localState(state).assets), Object.keys(localState(state).parts)]
    .some(e => e.length)
