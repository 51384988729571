import withStyles from '@mui/styles/withStyles';
import { Account, Home, Logout, MapMarker } from 'mdi-material-ui';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { STORE_TYPES, ROUTES } from 'Routes';

const styles = {
    '@media (max-width: 600px)': {
        storeDesc: {
            display: "none"
        },
        storeCode: {
            fontSize: 12
        },
        storeLocation: {
            display: "none",
            margin: "0px !important"
        }
    },
    '@media (min-width: 601px)' : {
        storeCode: {
            fontSize: 16
        }
    }
};

class KioskAppBar extends Component {

    appBarStyle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        height: '44px'
    };

    userInfoStyle = {
        display: "flex",
        alignItems: "center",
        justifyContent: 'flex-end'
    };

    logoutIcon = {
        color: "rgba(255, 255, 255, 0.8)",
        height: 22,
        paddingLeft: 9,
        paddingRight: 9,
        borderLeft: "1px solid rgba(255, 255, 255, 0.8)",
        marginLeft: 14,
        fontSize: 18,
        lineHeight: '22px',
        cursor: 'pointer'
    };

    storeInfoStyle = {
        margin: 5,
        display: "flex",
        flexDirection: "column"
    }

    render() {
        return (
            <div style={this.appBarStyle} onClick={this.clickHome}>
                {this.props.storeCode && (
                    <>
                    <Link to={process.env.REACT_APP_FRONTEND + `${this.props.groupStore ? STORE_TYPES.GENERAL : STORE_TYPES.STORE}/${this.props.storeCode}`} style={{ textDecoration: 'none' }}>
                        <div style={{alignItems: 'center', color: '#FFF', fontWeight: "bold", fontSize: 18, display:'flex'}}>
                            <Home style={{fontSize: 25}}/>
                            <div style={this.storeInfoStyle}>
                                <div style={{fontWeight: "bold"}} className={this.props.classes.storeCode}>{this.props.storeCode}</div>
                                <div style={{fontSize: 12, fontWeight: "normal"}}
                                    className={this.props.classes.storeDesc}>{this.props.storeDesc}</div>
                            </div>
                        </div>
                    </Link>
                    {this.props.location && (
                    <a href={`${this.props.gisBaseUrl}?n=['${this.props.location}']`} target="_blank" style={{ textDecoration: 'none' }}>
                        <div style={{alignItems: 'center', color: '#FFF', fontWeight: "bold", fontSize: 18, display:'flex'}}>
                            <MapMarker style={{fontSize: 25}}/>
                            <div style={this.storeInfoStyle} className={this.props.classes.storeLocation}>
                                <div style={{fontSize: 16, fontWeight: "bold"}} className={this.props.classes.storeLocation}>Location</div>
                                <div style={{fontSize: 12, fontWeight: "normal"}} className={this.props.classes.storeLocation}>{this.props.location}</div>
                            </div>
                        </div>
                    </a>
                    )}
                    </>
                )}
                <div style={this.userInfoStyle}>
                    {this.props.userData.eamAccount.userCode && (
                        <React.Fragment>
                            <Link to={ROUTES.USER_SETTINGS} style={{ textDecoration: 'none' }}>
                                <div style={{alignItems: 'center', color: '#FFF', fontWeight: "bold", fontSize: 18, display:'flex'}}>
                                    <Account style={{fontSize: 20, margin: 5}}/>
                                    <div style={{fontSize: 16, fontWeight: "normal"}}>
                                        {this.props.userData.eamAccount.userCode}
                                    </div>
                                </div>
                            </Link>
                            <a style={this.logoutIcon} onClick={this.props.logout}>
                                <Logout style={{fontSize: 20}}/>
                            </a>
                        </React.Fragment>
                    )}
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(KioskAppBar);