import {SET_LOGIN_TOKEN, UPDATE_APPLICATION} from '../actions/applicationActions'
import {UPDATE_TRANSACTION_ENTITY, UPDATE_TRANSACTION_TYPE} from '../actions/applicationActions'

export default function application(state = buildApplicationObject(), action) {
    switch (action.type) {
        case UPDATE_APPLICATION:
            return {
                ...state,
                ...action.value
            };
        case SET_LOGIN_TOKEN: {
            if (action.value) {
                return {
                    ...state,
                    loginToken: action.value
                }
            } else {
                return {
                    ...state,
                    loginToken: null
                }
            }
        }
        default:
            return state
    }
}

function buildApplicationObject() {
    return {
    }
}
