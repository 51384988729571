import withStyles from '@mui/styles/withStyles';
import ArchiveIcon from '@material-ui/icons/Archive';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Link from '@material-ui/icons/Link';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import RemoveRedEye from '@material-ui/icons/RemoveRedEye';
import Search from '@material-ui/icons/Search';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import { Cart, Home } from 'mdi-material-ui';
import React from 'react';
import KioskButton from '../../components/buttons/KioskButton';
import StoreInfo from "../../components/elements/StoreInfo";
import WS from '../../../tools/rest/WS';
import KioskAccess from '../KioskAccess';
import { RowButtons, classes } from '../GroupStoreMenu/GroupStoreMenu';
import { ROUTES, STORE_TYPES } from 'Routes';

class IssueReturnSelectionPage extends KioskAccess {

    state = {};

    componentDidMount() {
        this.init();
        this.props.updateTransactionType(null);
    }

    componentDidUpdate() {
        this.init();
    }

    init() {
        const { configuration, currentStore } = this.props;
        const { loadingOutbound, outboundByStatus } = this.state;

        if (configuration && configuration['outboundRequestButton'] === '+'
                && currentStore && !loadingOutbound && !outboundByStatus) {
            this.setState({loadingOutbound: true});
            WS.getPickTicketsByStatus(currentStore.code)
                .then(resp => this.setState({outboundByStatus: resp.body.data}, () => this.setState({loadingOutbound: false})))
        }
    }

    gotToFlexMain = () => () => {
        this.props.history.push(process.env.REACT_APP_FRONTEND + `${this.props.currentStore.code}/`)
    }

    goToContextSelection = transactiontype => () => {
        this.props.history.push(process.env.REACT_APP_FRONTEND + `${this.props.currentStore.code}/${transactiontype}`)
    };

    goToSearch = () => {
        this.props.history.push(process.env.REACT_APP_FRONTEND + `${this.props.currentStore.code}/search`)
    };

    goToOutstanding = () => {
        this.props.history.push(process.env.REACT_APP_FRONTEND + `${this.props.currentStore.code}/outstanding`)
    };

    goToEAMLight = () => {
        window.open(window.location.origin.replace('eam','').replace('kiosk', 'eamlight'), '_blank');
    };

    goToOutbound = () => () => {
        this.props.history.push(process.env.REACT_APP_FRONTEND + `${this.props.currentStore.code}/outbound`)
    };

    goToInbound = () => () => {
        this.props.history.push(process.env.REACT_APP_FRONTEND + `${this.props.currentStore.code}/inbound`)
    };

    goToRequest = () => () => {
        this.props.history.push(process.env.REACT_APP_FRONTEND + `${this.props.currentStore.code}/request`)
    };

    goToBin2Bin = () => () => {
        this.props.history.push(process.env.REACT_APP_FRONTEND + `${this.props.currentStore.code}/bin2bin`)
    };

    goToInventory = () => () => {
        this.props.history.push(process.env.REACT_APP_FRONTEND + `${this.props.currentStore.code}/inventory`)
    };

    getOutboundByStatusComponent = () => {
        const { outboundByStatus } = this.state;
        if (!outboundByStatus) return null;

        const pending = outboundByStatus.R || 0;
        const waiting = outboundByStatus.A || 0;
        const spanStyle = {color: '#C0C0C0'};


        return <div style={{position: 'absolute', top: '4px', right: '4px', fontSize: '10px', textAlign: 'right'}}>
                <div>Pending: <span style={spanStyle}><b>{pending}</b></span></div>
                <div>Pickup: <span style={spanStyle}><b>{waiting}</b></span></div>
            </div>;
    }


    renderPage() {

        const { currentStore, configuration, history, match, classes } = this.props;

        if (!currentStore) {
            return <div></div>
        }

        const { translations = {}, canAccess } = this.props;
        const createButton = createButtonTranslations(translations);

        return (
            <div style={{height: '100%', overflowY: 'auto'}}>

                <StoreInfo currentStore={currentStore} history={this.props.history} kioskMode={this.props.kioskMode} logout={this.props.logout}/>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    flexGrow: '1',
                    marginTop: '20px'
                }}>
                {canAccess &&
                    <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center'}}>
                        {
                            createButton({
                                Icon: props => <React.Fragment><Home {...props}/><ArrowForward {...props}/></React.Fragment>,
                                key: 'ISSUE',
                                onClick: this.goToContextSelection('issue').bind(this)
                            })
                        }
                        {
                            createButton({
                                Icon: props => <React.Fragment><Home {...props}/><ArrowBack {...props}/></React.Fragment>,
                                key: 'RETURN',
                                onClick: this.goToContextSelection('return').bind(this)
                            })
                        }

                    </div>
                }
                </div>

                {
                    canAccess
                        && <div>
                            <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center'}}>
                                {createButton({Icon: Search, key: 'SEARCH', onClick: this.goToSearch.bind(this)})}
                                {createButton({Icon: RemoveRedEye, key: 'OUTSTANDING', onClick: this.goToOutstanding.bind(this)})}
                            </div>
                        </div>
                }

                {canAccess && configuration && configuration['linktoeamlight'] &&
                    <div style={{marginTop:'5px', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center'}}>
                        <KioskButton onClick={this.goToEAMLight}>
                            <div>
                                <Link style={{fontSize: '40px'}}/>
                            </div>
                            {translations['LINKTOEAMLIGHT'] || 'Go to EAMLight'}
                        </KioskButton>
                        <div style={{width:'310px'}}>
                        </div>
                    </div>
                }

                {canAccess &&
                    <div style={{marginTop:'5px', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center'}}>
                        {
                            configuration && configuration['sitInbound'] === '+'
                                && createButton({
                                    Icon: props => <React.Fragment><Home {...props}/><ArrowBack {...props}/></React.Fragment>,
                                    key: 'INBOUND',
                                    onClick: this.goToInbound().bind(this)
                                })
                        }
                        {
                            configuration && configuration['sitOutbound'] === '+'
                                && createButton({
                                Icon: props => <React.Fragment>
                                        <Home {...props}/>
                                        <ArrowForward {...props}/>
                                        {this.getOutboundByStatusComponent()}
                                    </React.Fragment>,
                                    key: 'OUTBOUND',
                                    onClick: this.goToOutbound().bind(this)
                                })
                        }
                    </div>
                }

                <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center'}}>
                    {
                        canAccess
                            && createButton({Icon: props => <React.Fragment><ArchiveIcon {...props}/><UnarchiveIcon {...props}/></React.Fragment>, key: 'BIN2BIN', onClick: this.goToBin2Bin().bind(this)})
                    }
                    {
                        configuration && configuration['outboundRequestButton'] === '+'
                            && createButton({Icon: Cart, key: 'REQUEST', onClick: this.goToRequest().bind(this)})
                    }
                </div>
                <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center'}}>
                    {
                        canAccess && configuration && configuration['inventoryButton'] === '+'
                            && createButton({Icon: PlaylistAddCheckIcon, key: 'INVENTORY', onClick: this.goToInventory().bind(this)})
                    }
                </div>

                {canAccess && configuration?.extraConfiguration && <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center'}}>
                        {createButton({
                            Icon: AssignmentIcon,
                            key: 'REPORTS',
                            onClick: _ => window.open(`https://eam-reports.web.cern.ch/${currentStore.code}.html`, '_blank')
                        })}
                </div>}

                { canAccess && configuration?.extraConfiguration?.transaction2 && <RowButtons
                    classes={classes}
                    history={history}
                    match={match}
                    rowButtons={
                        [
                            {
                                Icon: props => <><Home {...props}/><ArrowForward {...props}/></>,
                                path: ROUTES.TRANSACTION_ISSUE,
                                storeType: STORE_TYPES.GENERAL,
                                key: 'ISSUE 2.0',
                            },
                            {
                                Icon: props => <><Home {...props}/><ArrowBack {...props}/></>,
                                path: ROUTES.TRANSACTION_RETURN,
                                storeType: STORE_TYPES.GENERAL,
                                key: 'RETURN 2.0',
                            },
                        ]
                    }
                />}

            </div>
        );
    }
}

export const createButtonTranslations = translations => ({Icon, key, onClick}) => {
    return <KioskButton key={key} onClick={onClick}>
        <div>
            <Icon style={{fontSize: '40px'}}/>
        </div>
        {translations[key] || key}
    </KioskButton>
}

export default withStyles(classes)(IssueReturnSelectionPage);