import { handleError } from '../../../../react-eis-components/actions/uiActions';
import { connect } from 'react-redux';
import { logout, updateApplication } from "../../../../actions/applicationActions";
import { updateAssetsForKit } from "../../../../actions/flexBuildingActions";
import { setPage } from "../../../../actions/layoutActions";
import SITSelectionPage from "./SITSelectionPage";

const mapStateToProps = (state, ownProps) => {
    const currentTransaction = state.currentTransaction;
    const language = state.layout.lang;
    const store = state.application.currentStore;
    const storeCode = ownProps.match.params.storeCode;
    const storeConfiguration = store ? state.application.storesConfiguration[store.code] : {};
    const groupStore = state.application.groupStore;


    return {
        kioskMode: state.application.kioskMode,
        applicationData: state.application.applicationData,
        currentTransaction: currentTransaction,
        currentStore: store,
        storeCode,
        storeConfiguration: storeConfiguration,
        translations: store ? state.application.translations[language]['KIOSK_' + store.code] : {},
        storeAccess: state.application && state.application.storeAccess,
        groupStore,
        stores: groupStore?.storeList ?? [storeConfiguration],
        storeData: state.application.storeData,
    }
};


const SITSelectionPageContainer = connect(
    mapStateToProps, {
        setPage,
        logout,
        handleError,
        updateAssetsForKit,
        updateApplication
    }
)(SITSelectionPage);

export default SITSelectionPageContainer;