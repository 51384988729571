import ui from '../react-eis-components/reducers/uiReducers';
import { combineReducers } from 'redux';
import application from './applicationReducers';
import currentTransaction from './currentTransactionReducer';
import flexBuilding from './flexBuildingReducer';
import layout from './layoutReducer';
import requestProcess from './requestProcessReducer';
import outboundProcess from './outboundProcessReducer';
import inboundProcess from './inboundProcessReducer';
import inventory, { INV_STATE_KEY } from './inventoryReducer';

export default combineReducers({
    application,
    layout,
    ui,
    currentTransaction,
    flexBuilding,
    requestProcess,
    outboundProcess,
    inboundProcess,
    [INV_STATE_KEY]: inventory
})
