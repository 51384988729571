import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router";
import { ROUTES } from "Routes";
import KioskButton from "ui/components/buttons/KioskButton";
import { withStyles } from "@mui/styles";
import { generatePath } from "react-router";
import { useEffect, useState, useMemo } from "react";
import EAMAutocomplete from "eam-components/dist/ui/components/inputs-ng/EAMAutocomplete";
import WS from "tools/rest/WS";
import WSTransaction from "tools/rest/WSTransaction";
import { Typography } from "@mui/material";

const classes = {
    outerContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        flexGrow: '1',
        marginTop: '10px',
        boxSizing: 'border-box',
        padding: '10px',
    },
    optionContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        boxSizing: 'border-box',
    }
}

const TRANSACTION_ON_TYPES = {
    EQUIPMENT: 'equipment',
    LOCATION: 'location',
    WORKORDER: 'workorder',
    PICKTICKET: 'pickticket',
    PROJECT: 'project',
    EMPLOYEE: 'employee',
}

const Transaction = ({ classes, history, match, transactionType }) => {
    const dispatch = useDispatch();
    const { storesConfiguration, userData, storeData, storeDataGeneral } = useSelector(state => state.application);
    const { storeCode } = match.params || {};

    const storeConfiguration = useMemo(() => storesConfiguration?.[storeCode], [storeCode]);
    const options = useMemo(() => getOptions({ storeConfiguration}), [storeConfiguration]);

    const [transactionOnType, setTransactionOnType] = useState('');
    const [transaction, setTransaction] = useState({
        [TRANSACTION_ON_TYPES.EMPLOYEE]: {
            code: userData.eamAccount.employeeCode,
            desc: userData.eamAccount.employeeDesc
        }
    });

    const transactionOnTypeSelected = TRANSACTION_ON_OPTIONS[transactionOnType];

    // The Workorder autocomplete takes extra parameters besides the hint
    let autocompleteParameters =
        transactionOnTypeSelected?.transactionOnTypeValue ===
        TRANSACTION_ON_TYPES.WORKORDER
            ? [storeCode, transactionType]
            : [];

    return <div style={{height: '100%', overflowY: 'auto'}}>
        <div className={classes.outerContainer}>

                <Typography style={{width: '100%', textAlign: 'center', fontSize: '20px'}} variant="body2" component="span">
                        {transactionType === 'issue' ? 'To be used for:' : 'Return from:'}
                </Typography>

                <div className={classes.optionContainer}>
                    {renderOptions({ options, transactionOnType, setTransactionOnType })}
                </div>

                {transactionOnTypeSelected &&
                    <EAMAutocomplete
                        key={transactionOnTypeSelected.key}
                        required
                        clearable={true}
                        autoFocus
                        autoSelectSingleElement
                        label={transactionOnTypeSelected.label}
                        value={transaction?.[transactionOnType]?.code}
                        desc={transaction?.[transactionOnType]?.desc}
                        onChange={(obj) => setTransaction((tr) => ({...tr, [transactionOnType]: obj?.desc ? obj : {}}))}
                        autocompleteHandler={transactionOnTypeSelected.autocompleteHandler}
                        autocompleteHandlerParams={autocompleteParameters}
                    />
                }

                <div style={{display: 'flex', justifyContent: 'center', flexGrow: 1}}>
                    <KioskButton style={{margin:'15px'}}
                        disabled={!transaction?.[transactionOnType]?.code || !transactionOnTypeSelected?.canContinue(transaction)}
                        onClick={() => history.push(
                            generatePath(ROUTES.TRANSACTION_ON,
                                {
                                    ...match.params, transactionType, transactionOnType,
                                }))
                        }
                    >
                        Next
                    </KioskButton>
                </div>
        </div>
    </div>;
}

const renderOptions = ({ options, setTransactionOnType, transactionOnType }) => {

    return options.map((opt) => {
        const { label, transactionOnTypeValue, key } = opt || {};
        return <KioskButton
                key={key}
                color={transactionOnType === transactionOnTypeValue ? 'primary' : 'info'}
                // onClick={() => history.push(generatePath(ROUTES.TRANSACTION_ON, {...match.params, transactionType, transactionOnType: transactionOnTypeValue}))}
                onClick={() => setTransactionOnType(transactionOnTypeValue)}
            >
                {label}
        </KioskButton>
    })
}

const getOptions = ({ storeConfiguration }) => {
    const options = storeConfiguration.visibleFunctions?.split(',')
        .map(s => [...s])
        .reduce((acc, s) => [...acc, ...s], [])
        ?? ['W', 'P', 'O', 'L', 'E'];
    return options.map(key => Object.values(TRANSACTION_ON_OPTIONS).find(opt => opt.key === key)).filter(e => e);
}

const TRANSACTION_ON_OPTIONS = {
    [TRANSACTION_ON_TYPES.WORKORDER]: {
        key: 'W',
        label: 'WorkOrder',
        transactionOnTypeValue: TRANSACTION_ON_TYPES.WORKORDER,
        autocompleteHandler: WS.autocompleteWorkorder.bind(WS),
        canContinue: () => true,
    },
    [TRANSACTION_ON_TYPES.PROJECT]: {
        key: 'P',
        label: 'Project',
        transactionOnTypeValue: TRANSACTION_ON_TYPES.PROJECT,
        autocompleteHandler: WS.autocompleteProject.bind(WS),
        canContinue: () => true,
    },
    [TRANSACTION_ON_TYPES.EQUIPMENT]: {
        key: 'O',
        label: 'Equipment',
        transactionOnTypeValue: TRANSACTION_ON_TYPES.EQUIPMENT,
        autocompleteHandler: WSTransaction.getTransactionOnEquipmentAutocomplete,
        canContinue: () => true,
    },
    [TRANSACTION_ON_TYPES.EMPLOYEE]: {
        key: 'E',
        label: 'Employee',
        transactionOnTypeValue: TRANSACTION_ON_TYPES.EMPLOYEE,
        autocompleteHandler: WS.autocompleteEmployee.bind(WS),
        canContinue: () => true,
    },
    [TRANSACTION_ON_TYPES.LOCATION]: {
        key: 'L',
        label: 'Location',
        transactionOnTypeValue: TRANSACTION_ON_TYPES.LOCATION,
        autocompleteHandler: WSTransaction.getTransactionOnLocationAutocomplete,
        canContinue: () => true,
    },
    [TRANSACTION_ON_TYPES.PICKTICKET]: {
        key: 'K',
        label: 'Pick ticket',
        transactionOnTypeValue: TRANSACTION_ON_TYPES.PICKTICKET,
        autocompleteHandler: async () => [],
        canContinue: () => true,
    },
}

export default withStyles(classes)(Transaction);
