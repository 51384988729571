import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class KITSelectionPage extends React.Component {

    state = {
        loading: false,
        open: true
    };

    handleClose = () => {
        let {currentStore, history} = this.props;
        history.push(process.env.REACT_APP_FRONTEND + `${currentStore.code}/outbound`);
    };

    render() {
        const {partcode, workOrderNumber, activityCode} = this.props;
        return (
            <div>
                <Dialog
                    open={this.state.open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.handleClose}
                    disableBackdropClick={true}
                    disableEscapeKeyDown={true}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">
                        {partcode} added to workorder {workOrderNumber} and activity {activityCode}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            The transaction was correctly executed
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default KITSelectionPage;