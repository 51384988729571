import Button from '@mui/material/Button';
import React from 'react';
import { generatePath } from 'react-router';
import { ROUTES } from 'Routes';
import AMMGrid from 'ui/components/AMMGrid';
import KioskAccess from 'ui/pages/KioskAccess';

export const CHECKBOX = 'checkbox';
export const SELECT = 'select';

const GRID_KUPICK_FIELDALIAS = {
    STORE: 'pic_store',
    PICKTICKET: 'pic_code',
}

class OutboundPickTicketSearch extends KioskAccess {

    componentDidMount() {
        this.props.resetOutboundInfo();
    }

    getExtraColumns = [
        {
            width: '105px',
            t: SELECT
        }
    ];

    onSelect = (row, params) => {
        const { history, routes } = this.props;
        const path = generatePath(ROUTES.OUTBOUND_PICKTICKET, { storeCode: row[GRID_KUPICK_FIELDALIAS.STORE], id: row[GRID_KUPICK_FIELDALIAS.PICKTICKET]});
        history.push(path);
    }

    cellRenderer = ({ column, value, row }) => {
        switch(column.id) {
            case SELECT:
                return (<Button variant="contained" color="primary" onClick={evt => this.onSelect(row.values, this.props.match.params)}>
                    Select
                </Button>)
            default:
                return value;
        }
    };

    gridRequestAdapter = gridRequest => {
        const { currentStore, groupStore } = this.props;

        const filtersStore = groupStore ?
            [
                // {operator: "EQUALS", fieldValue: groupStore.code, joiner: "AND", fieldName: 'str_pricecode', leftParenthesis: false,  rightParenthesis: false },
                ...groupStore.storeList.map((el, i) =>
                ({
                    operator: "EQUALS",
                    fieldValue: el.store,
                    joiner: i == groupStore.storeList.length - 1 ? "AND" : "OR",
                    fieldName: GRID_KUPICK_FIELDALIAS.STORE,
                    leftParenthesis: i === 0,
                    rightParenthesis: i === groupStore.storeList.length - 1
                }))
            ]
            : [{operator: "EQUALS", fieldValue: currentStore.code, joiner: "AND", fieldName: GRID_KUPICK_FIELDALIAS.STORE, leftParenthesis: false, rightParenthesis: false }];
        return {
            ...gridRequest,
            gridFilter: [...gridRequest.gridFilter, ...filtersStore]
        }
    }


    removeKey = (key, { [key]: _, ...rest }) => rest;

    renderPage () {
        return (
            <React.Fragment>
                <AMMGrid
                    gridRequestAdapter={this.gridRequestAdapter}
                    screenCode={this.props.applicationData.pickTicketsGridName}
                    cellRenderer={this.cellRenderer}
                    extraColumns={this.getExtraColumns}
                />
            </React.Fragment>
        );
    }
}

export default OutboundPickTicketSearch;