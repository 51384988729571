
import jssPreset from '@mui/styles/jssPreset';
import StylesProvider from '@mui/styles/StylesProvider';
import 'core-js';
import { polyfill } from 'es6-promise';
import { create } from 'jss';
import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import 'regenerator-runtime';
import './index.css';
import rootReducer from './reducers';
import { unregister } from './registerServiceWorker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { enGB } from "date-fns/locale";
import KioskContainer from './KioskContainer';
import AuthWrapper from './AuthWrapper';

const jss = create(jssPreset());

unregister();
polyfill();

const composeEnhancers = window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk))
);

ReactDOMClient.createRoot(document.getElementById('root')).render(
        <AuthWrapper>
            <StylesProvider jss={jss}>
                <Provider store={store}>
                    <LocalizationProvider  dateAdapter={AdapterDateFns} locale={enGB}>
                        <div style={{width: "100%", height: "100%"}}>
                            <KioskContainer />
                        </div>
                    </LocalizationProvider>
                </Provider>
            </StylesProvider>
        </AuthWrapper>
);
