import {
    REMOVE_LINE,
    ADD_LINE,
    ADD_TRANSACTION,
    RESET_TRANSACTION,
    UPDATE_WORKORDER,
    UPDATE_ACTIVITY,
    UPDATE_PROJECT,
    UPDATE_BUDGET,
    UPDATE_EQUIPMENT,
    UPDATE_EMPLOYEE,
    UPDATE_STORE,
    UPDATE_TRANSACTION_TYPE,
    UPDATE_DEPARTMENT_CODE,
    SET_TRANSACTION_ON_WORKORDER,
    SET_TRANSACTION_ON_PROJECT,
    SET_TRANSACTION_ON_EQUIPMENT,
    SET_TRANSACTION_ON_EMPLOYEE,
    RESET_TRANSACTION_ON, UPDATE_LINE
} from "../actions/currentTransactionActions";
import {UPDATE_TRANSACTION} from "../actions/currentTransactionActions";
import TransactionOn from '../enums/TransactionOn';

const emptyTransactionLine = {
    partCode: '',
    partDesc: '',
    partOrg: '*',
    bin: '',
    lot: '',
    transactionQty: '1',
    assetIDCode: null,
    assetIDDesc: null
};

const emptyTransaction = {
    transactionOn: '', //WORKORDER, EQUIPMENT, PROJECT, EMPLOYEE
    transactionlines: [],
    workOrderNumber: '',
    workOrderDesc: '',
    activityCode: '',
    activityDesc: '',
    projectCode: '',
    projectDesc: '',
    budgetCode: '',
    budgetDesc: '',
    equipmentCode: '',
    equipmentDesc: '',
    employeeCode: '',
    employeeDesc: '',
    storeCode: '',
    storeDesc: '',
    departmentCode: '*',
    transactionType: '', // ISSUE, RETURN
};

export default function currentTransaction(state = emptyTransaction, action) {

    switch (action.type) {
        case UPDATE_WORKORDER: {
            const {code, desc} = action.pair;
            return {
                ...state,
                workOrderNumber: code,
                workOrderDesc: desc ? desc : null
            }
        }
        case UPDATE_ACTIVITY: {
            const {code, desc} = action.pair;
            return {
                ...state,
                activityCode: code,
                activityDesc: desc ? desc : null
            }
        }
        case UPDATE_PROJECT: {
            const {code, desc} = action.pair;
            return {
                ...state,
                projectCode: code,
                projectDesc: desc ? desc : null
            }
        }
        case UPDATE_BUDGET: {
            const {code, desc} = action.pair;
            return {
                ...state,
                budgetCode: code,
                budgetDesc: desc ? desc : null
            }
        }
        case UPDATE_EQUIPMENT: {
            const {code, desc} = action.pair;
            return {
                ...state,
                equipmentCode: code,
                equipmentDesc: desc ? desc : null
            }
        }
        case UPDATE_EMPLOYEE: {
            const {code, desc} = action.pair;
            return {
                ...state,
                employeeCode: code,
                employeeDesc: desc ? desc : null
            }
        }
        case UPDATE_STORE: {
            const {code, desc} = action.pair;
            return {
                ...state,
                storeCode: code,
                storeDesc: desc ? desc : null
            }
        }
        case UPDATE_TRANSACTION_TYPE: {
            return {
                ...state,
                transactionType: action.value,
                transactionlines: []
            }
        }
        case UPDATE_DEPARTMENT_CODE: {
            return {
                ...state,
                departmentCode: action.value
            }
        }
        case SET_TRANSACTION_ON_WORKORDER: {
            return {
                ...state,
                transactionOn: TransactionOn.WORKORDER
            }
        }
        case SET_TRANSACTION_ON_PROJECT: {
            return {
                ...state,
                transactionOn: TransactionOn.PROJECT
            }
        }
        case SET_TRANSACTION_ON_EQUIPMENT: {
            return {
                ...state,
                transactionOn: TransactionOn.EQUIPMENT
            }
        }
        case SET_TRANSACTION_ON_EMPLOYEE: {
            return {
                ...state,
                transactionOn: TransactionOn.EMPLOYEE
            }
        }
        case RESET_TRANSACTION_ON: {
            return {
                ...state,
                transactionOn: undefined
            }
        }
        case ADD_TRANSACTION: {
            return action.value;
        }
        case RESET_TRANSACTION: {
            return {
                ...emptyTransaction,
                storeCode: state.storeCode,
                storeDesc: state.storeDesc,
                departmentCode: state.departmentCode
            };
        }
        case UPDATE_TRANSACTION: {
            return {
                ...state,
                ...action.value
            }
        }
        case ADD_LINE: {
            return {
                ...state,
                transactionlines: [...state.transactionlines, ...action.value]
            }
        }
        case UPDATE_LINE: {
            let lineToUpdate = state.transactionlines.filter((line, index) => index === action.value.index)[0];

            lineToUpdate = {
                ...lineToUpdate,
                ...action.value.line
            };

            const lines = [...state.transactionlines.filter((line, index) => index < action.value.index),
                            lineToUpdate,
                            ...state.transactionlines.filter((line, index) => index > action.value.index)];


            return {
                ...state,
                transactionlines: lines
            }
        }
        case REMOVE_LINE: {
            return {
                ...state,
                transactionlines: state.transactionlines.filter((line, index) => index !== action.value)
            }
        }
        default: {
            return state;
        }

    }
}