import WS from "./WS";

const getTransactionOnEquipmentGrid = async (hint, config) => {
    const request = {
        gridName: 'LVIROBJ',
        rowCount: 10,
        cursorPosition: 1,
        useNative: true,
        gridFilter: [
            {
                fieldName: 'equipmentcode',
                fieldValue: hint,
                joiner: 'AND',
                operator: 'BEGINS'
            },
            {
                fieldName: 'type',
                fieldValue: 'A',
                joiner: 'OR',
                operator: 'EQUALS',
                leftParenthesis: true,
                rightParenthesis: false,
            },
            {
                fieldName: 'type',
                fieldValue: 'P',
                joiner: 'OR',
                operator: 'EQUALS',
                leftParenthesis: false,
                rightParenthesis: false,
            },
            {
                fieldName: 'type',
                fieldValue: 'S',
                joiner: 'AND',
                operator: 'EQUALS',
                leftParenthesis: false,
                rightParenthesis: true,
            },

        ],
        params: {
            loantodept: true,
            "control.org": '*', // TODO organization
        },
    }
    try {
        return (await WS.requestGridData(request, config))?.body.data;
    } catch (error) {
        console.error(error);
        return [];
    }

}

const WSTransaction = {
    getTransactionOnEquipmentAutocomplete: async (hint, config) =>
        WS._get(`/transaction/autocomplete/equipment?hint=${encodeURIComponent(hint)}`, config),
    getTransactionOnLocationAutocomplete: async (hint, config) =>
        WS._get(`/transaction/autocomplete/location?hint=${encodeURIComponent(hint)}`, config),
    getTransactionOnEquipmentGrid,
    autocompleteEquipment(key, config = {}) {
        return WS._get(`/autocomplete/equipment/${encodeURIComponent(key)}`, config);
    }
}

export default WSTransaction;
