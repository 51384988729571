import { handleError, openConfirmDialog, showSuccess, showError } from '../../../../react-eis-components/actions/uiActions';
import { connect } from 'react-redux';
import { logout } from "../../../../actions/applicationActions";
import { setRequestProcessItems } from "../../../../actions/requestProcessActions";
import { getRoutes } from "../../../../tools/routes";
import OutboundRequestDestination from "./OutboundRequestDestination";

const mapStateToProps = (state, ownProps) => {
    const language = state.layout.lang;
    const store = state.application.currentStore;
    const storeConfiguration = store ? state.application.storesConfiguration[store.code] : {};
    const storeCode = ownProps.match.params.storeCode;
    const translations = state.application.translations[language];

    return {
        kioskMode: state.application.kioskMode,
        applicationData: state.application.applicationData,
        storeConfiguration,
        currentStore: store,
        translations: translations['KIOSK_' + storeCode] || translations['KIOSK_*'] || {},
        storeCode,
        requestProcess: state.requestProcess,
        routes: getRoutes(process.env.REACT_APP_FRONTEND, store ? store.code : ''),
        groupStore: state.application.groupStore,
        storeAccess: state.application.storeAccess,
    }
};

const OutboundRequestDestinationContainer = connect(
    mapStateToProps, {
        logout,
        handleError,
        showSuccess,
        showError,
        setRequestProcessItems,
        openConfirmDialog
    }
)(OutboundRequestDestination);

export default OutboundRequestDestinationContainer;