import React from 'react';
import EAMAutocomplete from 'eam-components/dist/ui/components/inputs-ng/EAMAutocomplete';
import WS from "../../../../tools/rest/WS";
import { createOnChangeHandlerObjectUpdate } from 'eam-components/dist/ui/components/inputs-ng/tools/input-tools';

const CODE_KEY = 'employeeCode';
const DESC_KEY = 'employeeDesc';

class ContextSelectionEmployee extends React.Component {

    componentDidMount = () => {
        const prevEmployeeCode = (this.props.currentTransaction || {}).employeeCode;
        const { employeeCode, employeeDesc } = this.props.userData.eamAccount || {};

        if (!prevEmployeeCode) {
            this.props.updateTransaction({employeeCode, employeeDesc})
        }
    };

    state = {};

    render() {
        const { updateTransaction, currentTransaction } = this.props;
        const { employeeCode, employeeDesc } = currentTransaction;

        return (
            <div>
                <EAMAutocomplete
                    label="Employee"
                    value={employeeCode}
                    desc={employeeDesc}
                    onChange={createOnChangeHandlerObjectUpdate(
                        CODE_KEY, DESC_KEY, null, updateTransaction, null
                    )}
                    autocompleteHandler={(key) => WS.autocompleteEmployee(key)}
                    // autoFocus // TODO: needs update of inputs-ng
                    // autoSelectSingleElement // TODO: needs update of inputs-ng
                    barcodeScanner
                />
            </div>
        )}
}

export default ContextSelectionEmployee;