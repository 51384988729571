import {connect} from 'react-redux';
import {setPage} from "../../../../actions/layoutActions";
import {handleError, showSuccess, showError} from '../../../../react-eis-components/actions/uiActions';
import SITSelectionAssets from "./SITSelectionAssets";
import {logout} from "../../../../actions/applicationActions";
import {updateAssetsForKit} from "../../../../actions/flexBuildingActions";
import * as inboundActions from "../../../../actions/inboundProcessActions";
import { getAssets, getParts, canSkipPackaging, hasArticles } from "../../../../reducers/inboundProcessReducer";

const mapStateToProps = (state) => {
    const currentTransaction = state.currentTransaction;
    const language = state.layout.lang;
    const store = state.application.currentStore;
    const storeConfiguration = store ? state.application.storesConfiguration[store.code] : {};

    return {
        kioskMode: state.application.kioskMode,
        applicationData: state.application.applicationData,
        currentTransaction: currentTransaction,
        currentStore: store,
        storeConfiguration: storeConfiguration,
        translations: store ? state.application.translations[language]['KIOSK_' + store.code] : {},
        updateArticles: true,
        assetsForKit: state.flexBuilding.assetsForKit,
        assets: getAssets(state),
        parts: getParts(state),
        canSkipPackaging: canSkipPackaging(state),
        hasArticles: hasArticles(state)
    }
};

const SITSelectionAssetsContainer = connect(
    mapStateToProps, {
        setPage,
        logout,
        handleError,
        showSuccess,
        showError,
        updateAssetsForKit,
        ...inboundActions
    }
)(SITSelectionAssets);

export default SITSelectionAssetsContainer;