import {connect} from 'react-redux';
import {setPage} from "../../../actions/layoutActions";
import { handleError, openConfirmDialog } from '../../../react-eis-components/actions/uiActions';
import {updateApplication, logout} from "../../../actions/applicationActions";
import PartsAssetsSelectionPage from "./PartsAssetsSelectionPage";
import {
    updateTransactionType,
    setTransactionOnWorkorder,
    setTransactionOnProject,
    setTransactionOnEquipment,
    setTransactionOnEmployee,
    resetTransactionOn,
    updateWorkOrder,
    updateActivity,
    updateProject,
    updateBudget,
    updateEquipment,
    updateEmployee,
    updateDepartmentCode,
    addLineToCurrentTransaction,
} from "../../../actions/currentTransactionActions";

import Tools from "../../../tools/Tools";
import { withStyles } from '@mui/styles';

const mapStateToProps = (state, ownProps) => {
    const transactionType = ownProps.match.params.transactiontype;
    const transactionContext = ownProps.match.params.transactioncontext;

    return {
        kioskMode: state.application.kioskMode,
        currentStore: state.application.currentStore,
        currentTransaction: state.application.currentTransaction,
        transactionType,
        transLines: state,
        transactionContext: transactionContext.toUpperCase(),
        workOrderNumber:  Tools.getURLParameterByName("wo"),
        activityCode:  Tools.getURLParameterByName("activity"),
        projectCode:  Tools.getURLParameterByName("project"),
        budgetCode:  Tools.getURLParameterByName("budget"),
        equipmentCode:  Tools.getURLParameterByName("equipment"),
        employeeCode:  Tools.getURLParameterByName("employee"),
        departmentCode:  Tools.getURLParameterByName("department"),
    }
};

const PartsAssetsSelectionPageContainer = connect(
    mapStateToProps, {
        setPage,
        updateApplication,
        logout,
        updateTransactionType,
        setTransactionOnWorkorder,
        setTransactionOnProject,
        setTransactionOnEquipment,
        setTransactionOnEmployee,
        resetTransactionOn,
        handleError,
        updateWorkOrder,
        updateActivity,
        updateProject,
        updateBudget,
        updateEquipment,
        updateEmployee,
        updateDepartmentCode,
        addLineToCurrentTransaction,
        openConfirmDialog,
    }
)(PartsAssetsSelectionPage);

export default PartsAssetsSelectionPageContainer;
