import { connect } from 'react-redux';
import { setPage } from "../../../actions/layoutActions";
import {handleError} from '../../../react-eis-components/actions/uiActions';
import StoreIndexPage from "./StoreIndexPage";
import {updateStore} from "../../../actions/currentTransactionActions";
import {updateApplication} from "../../../actions/applicationActions";

const mapStateToProps = (state, ownProps) => {
  return {
      stores: state.application.storeData,
      publicStores: state.application.storeDataPublic,
      generalStores: state.application.storeDataGeneral,
  }
};

const StoreIndexContainer = connect(
  mapStateToProps,{
        setPage,
        handleError,
        updateStore,
        updateApplication
    }
)(StoreIndexPage);

export default StoreIndexContainer;
