const NS = "INBOUND_PROCESS";

export default {
    RESET: `${NS}/RESET`,
    UPDATE_PARTS: `${NS}/UPDATE_PARTS`,
    UPDATE_ASSETS: `${NS}/UPDATE_ASSETS`,
    UPDATE_TRANSACTION_LINES: `${NS}/UPDATE_TRANSACTION_LINES`,
    UPDATE_SIT_DOC: `${NS}/UPDATE_SIT_DOC`,
    UPDATE_ARTICLE_LINES: `${NS}/UPDATE_ARTICLE_LINES`,
    UPDATE_RELATIONS: `${NS}/UPDATE_RELATIONS`,
    ADD_ITEM_TO_PACKAGE: `${NS}/ADD_ITEM_TO_PACKAGE`,
    REMOVE_ITEM_FROM_PACKAGE: `${NS}/REMOVE_ITEM_FROM_PACKAGE`,
    REMOVE_PACKAGE: `${NS}/REMOVE_PACKAGE`,
    ADD_PACKAGE: `${NS}/ADD_PACKAGE`,

    PLACE_PART_BIN: `${NS}/PLACE_PART_BIN`
}
