import {connect} from 'react-redux';
import {setPage} from "../../../../actions/layoutActions";
import {handleError} from '../../../../react-eis-components/actions/uiActions';
import KITOutboundConfirmationPage from "./KITOutboundConfirmationPage";
import {logout} from "../../../../actions/applicationActions";
import {updateAssetsForKit} from "../../../../actions/flexBuildingActions";
import Tools from "../../../../tools/Tools";

const mapStateToProps = (state, ownProps) => {
    const partcode = ownProps.match.params.partcode;
    const entitycode = ownProps.match.params.entitycode;
    return {
        partcode: partcode,
        entitycode: entitycode,
        currentStore: state.application.currentStore,
        workOrderNumber:  Tools.getURLParameterByName("wo"),
        activityCode:  Tools.getURLParameterByName("activity"),
        projectCode:  Tools.getURLParameterByName("project"),
        budgetCode:  Tools.getURLParameterByName("budget"),
        equipmentCode:  Tools.getURLParameterByName("equipment"),
        employeeCode:  Tools.getURLParameterByName("employee"),
        departmentCode:  Tools.getURLParameterByName("department")
    }
};

const KITOutboundConfirmationPageContainer = connect(
    mapStateToProps, {
        setPage,
        logout,
        handleError,
        updateAssetsForKit
    }
)(KITOutboundConfirmationPage);

export default KITOutboundConfirmationPageContainer;