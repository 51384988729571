import { connect } from 'react-redux';
import OutboundItemListLoader from './OutboundItemListLoader';
import { setOutboundPickTicket, setOutboundItems } from "../../../../actions/outboundProcessActions";


const mapStateToProps = (state, ownProps) => ({
    storeCode: ownProps.match.params.storeCode,
    history: ownProps.history,
    pickTicketCode: ownProps.match.params.id,
})

const actionCreators = {
    setOutboundPickTicket,
    setOutboundItems
}

const OutboundItemListLoaderContainer = connect(
    mapStateToProps,
    actionCreators
)(OutboundItemListLoader);

export default OutboundItemListLoaderContainer;
