import { connect } from 'react-redux';
import UserIdentificationPage from './UserIdentificationPage';
import { handleError, showError } from '../../../react-eis-components/actions/uiActions';
import { login, getInitData } from '../../../actions/applicationActions';
import { updateEmployee } from '../../../actions/currentTransactionActions';

const mapStateToProps = (state, ownProps) => {
    const storeCode = ownProps.match.params.storeCode;
    return {
        store: state.application.currentStore ? state.application.currentStore.code : storeCode
    }
};

const UserIdentificationPageContainer = connect(
    mapStateToProps, {
        handleError, showError, login, getInitData, updateEmployee
    }
)(UserIdentificationPage);

export default UserIdentificationPageContainer;