import DeleteIcon from '@material-ui/icons/Delete';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import EAMTextField from 'eam-components/dist/ui/components/inputs-ng/EAMTextField';
import EAMSelect from 'eam-components/dist/ui/components/inputs-ng/EAMSelect';
import React from 'react';
import { generatePath } from 'react-router-dom';
import { ROUTES } from 'Routes';
import WSPartSelection from 'tools/rest/WSPartSelection';
import AMMGrid from 'ui/components/AMMGrid';
import KioskAccess from 'ui/pages/KioskAccess';
import { createOnChangeHandler } from 'eam-components/dist/ui/components/inputs-ng/tools/input-tools';

const ALLOW_REQUEST = true;

export const CELL_TYPES = {
    OBJ_BIN: "obj_bin",
    OBJ_CATEGORY: "obj_category",
    OBJ_CLASS: "obj_class",
    OBJ_CODE: "obj_code",
    OBJ_CREATED: "obj_created",
    OBJ_DEPEND: "obj_depend",
    OBJ_DESC: "obj_desc",
    OBJ_GROUP: "obj_group",
    OBJ_LOT: "obj_lot",
    OBJ_LTYPE: "obj_ltype",
    OBJ_MRC: "obj_mrc",
    OBJ_OBRTYPE: "obj_obrtype",
    OBJ_OBTYPE: "obj_obtype",
    OBJ_ORIGCONFIGORG: "obj_origconfigorg",
    OBJ_PARENT: "obj_parent",
    OBJ_PART: "obj_part",
    OBJ_PERSON: "obj_person",
    OBJ_STORE: "obj_store",
    OBJ_UDFCHAR04: "obj_udfchar04",
    OBJ_UDFCHAR12: "obj_udfchar12",
    OBJ_UDFCHAR22: "obj_udfchar22",
    OBJ_UDFNUM07: "obj_udfnum07",
    OBJ_UDFNUM08: "obj_udfnum08",
    OBJ_UDFNUM09: "obj_udfnum09",
    OBJ_UDFNUM10: "obj_udfnum10",
    OBJ_UPDATED: "obj_updated",
    OBJ_UPDATEDBY: "obj_updatedby",
    OBJ_VALUE: "obj_value",
    PACKAGING: "packaging",
    PARENT_CLASS: "parent_class",
    QUANTITY: 'quantity',
    LOT: 'lot',
}

const CHECKBOX_T = '__selectitem';

const extraColumns = [
    {
        width: '42px',
        t: CHECKBOX_T
    }
];

const isRowSelectable = obj => ['DIRECTLY', 'PACKAGE', 'PART'].includes(obj[CELL_TYPES.PACKAGING]);

const getRowCode = (obj) => `${obj[CELL_TYPES.OBJ_STORE]}#${obj[CELL_TYPES.OBJ_PART]}#${obj[CELL_TYPES.OBJ_CODE]}`
class OutboundRequestSelectItems extends KioskAccess {

    state = {
        selectedRows: {},
        partQuantities: {},
        dialogOpen: false,
        partLots: {}
    }

    cellRenderer = ({ column, value, row }) => {
        if (column.id === CHECKBOX_T) {
            return this.getCheckbox(row.values);
        } else if (column.id === 'obj_hazards') {
            return value.split(',').filter(s => s)
                .map(haz => this.props.applicationData.hazardsList.find(h => h.code === haz)?.desc ?? haz)
                .join(', ');
        }
        return value;
    };

    getCheckbox = obj => {
        const { selectedRows } = this.state;
        return isRowSelectable(obj) ?
            <Checkbox
                style={{boxSizing: 'border-box', padding: 0}}
                checked={!!selectedRows[getRowCode(obj)]}
                onChange={(event) => this.selectRow(obj, event.target.checked)}
            />
            : ''
    }

    selectRow = (row, checked) => {
        const rowCode = getRowCode(row);
        this.setState((prevState) => {
            const selectedRows = {
                ...prevState.selectedRows,
                [getRowCode(row)]: row,
            };
            if (!checked) {
                delete selectedRows[rowCode];
            }
            return {
                selectedRows,
            }
        }
        );
    }

    removeKey = (key, { [key]: _, ...rest }) => rest;

    removeRow = (code, rowCode) => {
        this.setState((prevState) => ({
            ...prevState,
            selectedRows: this.removeKey(rowCode, prevState.selectedRows),
            partQuantities: this.removeKey(code, prevState.partQuantities),
            partLots: this.removeKey(code, prevState.partLots),
        }));
    };

    componentDidMount() {
        this.props.setRequestProcessItems([]);
    }

    onSelectRow = (row) => {
        this.setState({selectedRows: row.map(row => row.values)})
    }

    gridRequestAdapter = gridRequest => {
        const { currentStore, groupStore } = this.props;

        const filtersStore = groupStore ?
            [
                {operator: "EQUALS", fieldValue: groupStore.code, joiner: "AND", fieldName: 'str_pricecode', leftParenthesis: false,  rightParenthesis: false },
                ...groupStore.storeList.map((el, i) =>
                ({
                    operator: "EQUALS",
                    fieldValue: el.store,
                    joiner: i == groupStore.storeList.length - 1 ? "AND" : "OR",
                    fieldName: 'obj_store',
                    leftParenthesis: i === 0,
                    rightParenthesis: i === groupStore.storeList.length - 1
                }))
            ]
            : [{operator: "EQUALS", fieldValue: currentStore.code, joiner: "AND", fieldName: 'obj_store', leftParenthesis: false, rightParenthesis: false }];
        return {
            ...gridRequest,
            gridFilter: [...gridRequest.gridFilter, ...filtersStore]
        }
    }

    handleRequest = () => {
        const { selectedRows, partQuantities, partLots } = this.state;
        const { setRequestProcessItems, history, storeCode, match } = this.props;
        const requestProcessItems = Object.entries(selectedRows).reduce((acc, [key, value]) => {
            const code = value[CELL_TYPES.OBJ_CODE] || value[CELL_TYPES.OBJ_PART];
            return {
                ...acc,
                [key]: {
                    ...value,
                    [CELL_TYPES.QUANTITY]: partQuantities[code] || "1",
                    [CELL_TYPES.LOT]: partLots[code],
                }
            }
        }, {});
        setRequestProcessItems(requestProcessItems);
        history.push(generatePath(ROUTES.REQUEST_ITEMS_DESTINATION, match.params));
    }

    renderSelected = (entry) => {
        const { partQuantities } = this.state;
        const isAsset = entry[CELL_TYPES.OBJ_CODE]
        const code = isAsset ? entry[CELL_TYPES.OBJ_CODE] : entry[CELL_TYPES.OBJ_PART]
        const lots = entry[CELL_TYPES.OBJ_LOT] && entry[CELL_TYPES.OBJ_LOT].split(",").sort().map(lot => ({code: lot, desc: lot}));
        const selectLots = lots && lots.length > 1
        return (
            <div
                key={`${entry[CELL_TYPES.OBJ_STORE]}${entry[CELL_TYPES.OBJ_CODE]}${entry[CELL_TYPES.OBJ_PART]}`}
                style={{ padding: "8px", margin: "4px 0px", border: "1px solid #EEEEEE" }}>
                <section style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ }}>
                        <div style={{ display: "flex", alignItems: "top"}}>
                            <Typography variant="body1" component="span"><b>{code}</b></Typography>
                            <Typography style={{ marginLeft: "8px" }} variant="caption" component="span">{entry[CELL_TYPES.PACKAGING]}</Typography>
                        </div>
                        <Typography style={{ marginTop: "8px" }} variant="body1" component="span">{entry[CELL_TYPES.OBJ_DESC]}</Typography>
                    </div>
                    <div style={{ display: "flex", alignItems: "center"}}>
                        {entry[CELL_TYPES.PACKAGING] === "PART" && (
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                {selectLots && <EAMSelect
                                    label="Lot"
                                    style={{ width: "170px", maxWidth: "170px", marginTop: 0 }}
                                    value={this.state.partLots[code]}
                                    options={lots}
                                    onChange={createOnChangeHandler(
                                        code, null, null, this.updatePartLot, null
                                    )}
                                    />}
                                <EAMTextField
                                    label="Quantity"
                                    style={{ width: selectLots ? "170px" : "70px", maxWidth: selectLots ? "170px" : "70px", marginTop: 0 }}
                                    value={partQuantities[code]}
                                    onChange={createOnChangeHandler(
                                        code, null, null, this.updateQuantity, null
                                    )}
                                    validator={this.validateQuantity(code)}
                                />
                            </div>
                        )}
                        <DeleteIcon style={{ margin: "8px" }} onClick={e => this.removeRow(code, getRowCode(entry))} />
                    </div>
                </section>
            </div>
        )
    }

    canConfirm = () => {
        const { selectedRows, partQuantities } = this.state;
        const rows = Object.values(selectedRows);
        return rows.length && rows.every((obj) => obj[CELL_TYPES.PACKAGING] !== 'PART' || partQuantities[obj[CELL_TYPES.OBJ_PART]] > 0)
    }

    updateQuantity = (code, quantity) => {
        this.setState((prevState) => ({
            partQuantities: {
                ...prevState.partQuantities,
                [code]: quantity
            }
        }))
    }

    updatePartLot = (code, lot) => {
        WSPartSelection.autocompleteCodeInfo(this.props.currentStore.code, {code, lot})
            .then(s => {
                this.setState((prevState) => ({
                    partLots: {
                        ...prevState.partLots,
                        [code]: lot
                    },
                    partQuantities: {
                        ...prevState.partQuantities,
                        [code]: s.body.data.reduce((acc, el) => acc + el.quantity, 0)
                    },
                }))
            })
            .catch(this.props.handleError)
    }

    validateQuantity = (code) => (value) => {
        if (value <= 0) {
            this.setState((prevState) => ({
                partQuantities: this.removeKey(code, prevState.partQuantities),
            }));
            return false;
        }
        return true;
    }

    dialogOpen = () => {
        this.setState({ dialogOpen: true })
    }

    dialogClose = () => {
        this.setState({ dialogOpen: false })
    }

    renderPage() {
        const { groupStore } = this.props;
        const { selectedRows } = this.state;
        const totalSelectedRows = Object.values(selectedRows).length;

        return (
            <React.Fragment>
                <div style={{display: 'flex', justifyContent: "flex-end", padding: '8px', height: '60px', boxSizing: 'border-box'}}>
                    <Button variant="contained" color="primary" disabled={!ALLOW_REQUEST || totalSelectedRows === 0} onClick={this.dialogOpen}>
                        Request
                    </Button>
                </div>
                <div style={{height: 'calc(100% - 60px)', padding: 0, margin: 0, boxSizing: 'border-box'}}>
                    <AMMGrid
                        gridRequestAdapter={this.gridRequestAdapter}
                        screenCode={this.props.applicationData.storeAssetsGridName}
                        // allowRowSelection={true}
                        // onSelectRow={this.onSelectRow}
                        // isRowSelectable={isRowSelectable}
                        useNative={false}
                        extraColumns={extraColumns}
                        cellRenderer={this.cellRenderer}
                    />
                </div>
                <Dialog
                    fullWidth
                    open={this.state.dialogOpen}
                    onClose={this.dialogClose}
                    PaperProps={{style: { margin: "8px", height: "97%" }}}
                >
                    <DialogTitle>Selection Overview</DialogTitle>
                    <DialogContent>
                        {`${totalSelectedRows} item${totalSelectedRows === 1 ? '' : 's'} selected.`}
                        <div style={{ flex: 1 }}>
                            {Object.values(selectedRows).map(this.renderSelected)}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.dialogClose} color="primary">
                            Cancel
                        </Button>
                        <Button disabled={!this.canConfirm()} variant="contained" onClick={this.handleRequest} color="primary">
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default OutboundRequestSelectItems;