import {connect} from 'react-redux';
import {setPage} from "../../../actions/layoutActions";
import {handleError} from '../../../react-eis-components/actions/uiActions';
import IssueReturnSelectionPage from "./IssueReturnSelectionPage";
import {logout} from "../../../actions/applicationActions";
import { updateTransactionType } from "../../../actions/currentTransactionActions";

const mapStateToProps = (state, ownProps) => {
    const language = state.layout.lang;
    const store = state.application.currentStore;
    const storeCode = ownProps.match.params.storeCode;
    let storeConfiguration = state.application.storesConfiguration[storeCode];

    return {
        kioskMode: state.application.kioskMode,
        translations: store ? state.application.translations[language]['KIOSK_'+store.code] : {},
        configuration: storeConfiguration,
        storeCode,
        storeAccess: state.application.storeAccess,
        currentStore: state.application.currentStore,
        canAccess: state.application.storeData.find(s => s.code === store?.code),
    }
};

const IssueReturnSelectionPageContainer = connect(
    mapStateToProps, {
        setPage,
        handleError,
        logout,
        updateTransactionType,
    }
)(IssueReturnSelectionPage);

export default IssueReturnSelectionPageContainer;
