import WS from "./WS";

class WSPartSelection  {

    autocompleteAvailableCodes = (store, hint = '', config = {}) => {
        hint = encodeURIComponent(hint);
        return WS._get(`/autocomplete/stores/${store}/available/?hint=${hint}`, config);
    };

    autocompleteCodeInfo = (store, {code, bin, lot}, config = {}) => {
        code = encodeURIComponent(code);
        bin = bin && encodeURIComponent(bin);
        const queryParams = [{code: "bin", value: bin}, {code: "lot", value: lot}]
            .filter(s => s.value)
            .map(s => s.code + "=" + encodeURIComponent(s.value))
            .join('&');
        return WS._get(`/autocomplete/stores/${store}/available/${code}/?${queryParams}`, config);
    };

    autocompleteParts = (code, store, transactionType, relatedWONumber, config = {}) => {
        code = encodeURIComponent(code);
        return WS._get(`/autocomplete/part/${store}/${transactionType}/?code=${code}`, config);
    };

    autocompleteAsset = (assetCode, partCode, store, transactionType, bin = null, lot = null, config) => {
        assetCode = encodeURIComponent(assetCode);
        partCode = encodeURIComponent(partCode);
        bin = bin ? encodeURIComponent(bin) : bin;
        return WS._get(`/autocomplete/asset/${store}/${transactionType}/?partCode=${partCode}&assetCode=${assetCode}` + (lot ? `&lot=${lot}` : "") + (bin ? `&bin=${bin}` : ""), config);
    };

    getLots = (part, store, transactionType, bin, lot = null, config = {}) => {
        part = encodeURIComponent(part);
        bin = bin && encodeURIComponent(bin);
        return WS._get(`/partselection/lots/${transactionType}/${store}/?part=${part}&bin=${bin}`, config);
    };

    getPartData = (part, store, transactionType, config) => {
        part = encodeURIComponent(part);
        return WS._get(`/partselection/partdata/${store}/${transactionType}/?code=${part}`, config);
    };

    getAssetData = (code, store, transactionType) => {
        code = encodeURIComponent(code);
        return WS._get(`/partselection/assetdata/${store}/${transactionType}/?code=${code}`)
    };

    getAvailableQuantity = (store, bin, lot, code) => {
        code = encodeURIComponent(code);
        bin = bin && encodeURIComponent(bin);
        lot = lot && encodeURIComponent(lot);
        return WS._get(`/partselection/quantity/${store}/${bin}/${lot}/?code=${code}`);
    };

    getPartsForWO = (store, wonumber, activity) => {
        return WS._get(`/partsforwo/${store}/${wonumber}/${activity}`);
    };

    getEquipmentStatus = (code) => {
        code = encodeURIComponent(code);
        return WS._get(`/partselection/equipmentstatus?code=${code}`);
    };

    getBinsList = (store, hint) => {
        const request = {
            gridName: 'SUBING',
            rowCount: 20,
            cursorPosition: 1,
            useNative: false,
            //dataspyID:"150221",
            gridID:"103446",
            gridFilter: [
                {
                    fieldName: 'bin_store',
                    fieldValue: store,
                    joiner: 'AND',
                    operator: 'EQUALS'
                },
                {
                    fieldName: 'bin_code',
                    fieldValue: hint,
                    joiner: 'AND',
                    operator: 'BEGINS'
                }
            ]

        }
        return WS.requestGridData(request);
    }

    submitBin2Bin = (store, data, config = {}) => WS._put(`/transaction/stores/${store}/bin2bin`, data, config)

    submitStore2Store = (fromStore, toStore, data, config = {}) => WS._put(`/transaction/stores/${fromStore}/store2store/${toStore}`, data, config)
}

export default new WSPartSelection();