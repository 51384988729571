import React from 'react';
import TransactionInfo from "../../components/elements/TransactionInfo";
import KioskButton from '../../components/buttons/KioskButton';
import StoreInfo from "../../components/elements/StoreInfo";
import ContextSelectionPageDetailsContainer from "./ContextSelectionPageDetailsContainer";
import TransactionOn from '../../../enums/TransactionOn';
import KioskAccess from '../KioskAccess';
import { Typography } from '@mui/material';

class ContextSelectionPage extends KioskAccess {

    constructor(props) {
        super(props);

        this.state = {
            [TransactionOn.WORKORDER]: false,
            [TransactionOn.PROJECT]: false,
            [TransactionOn.EQUIPMENT]: false,
            [TransactionOn.EMPLOYEE]: false
        };
    }

    componentDidMount() {
        const {transactionType} = this.props;
        if (transactionType) {
            this.props.updateTransaction({transactionType});
        } else {
            this.props.updateTransaction({transactionType: undefined});
        }

        this.checkForVisibleContexts(this.props);
    }

    componentWillReceiveProps(newProps) {
        if (newProps.storeConfiguration !== this.props.storeConfiguration) {
            this.checkForVisibleContexts(newProps);
        }
    }

    setTransactionOnWorkorder = () => {
        const {setTransactionOnWorkorder} = this.props;
        setTransactionOnWorkorder();
    };

    setTransactionOnProject = () => {
        const {setTransactionOnProject} = this.props;
        setTransactionOnProject();
    };

    setTransactionOnEquipment = () => {
        const {setTransactionOnEquipment} = this.props;
        setTransactionOnEquipment();
    };

    setTransactionOnEmployee = () => {
        const {setTransactionOnEmployee} = this.props;
        setTransactionOnEmployee();
    };

    checkForVisibleContexts = (props) => {
        const {storeConfiguration} = props;

        if (storeConfiguration?.visibleFunctions) {
            const visibleFunctions = storeConfiguration.visibleFunctions?.split(',')
                .map(s => [...s])
                .reduce((acc, s) => [...acc, ...s], []);
            const isJustOneOption = visibleFunctions.length === 1;
            let newState = {};
            visibleFunctions.forEach(fun => {
                if (fun === 'W') {
                    newState = {
                        ...newState,
                        [TransactionOn.WORKORDER]: true
                    };
                    if (isJustOneOption) {
                        this.setTransactionOnWorkorder();
                    }
                }
                if (fun === 'P') {
                    newState = {
                        ...newState,
                        [TransactionOn.PROJECT]: true
                    };
                    if (isJustOneOption) {
                        this.setTransactionOnProject();
                    }
                }
                if (fun === 'O') {
                    newState = {
                        ...newState,
                        [TransactionOn.EQUIPMENT]: true
                    };
                    if (isJustOneOption) {
                        this.setTransactionOnEquipment();
                    }
                }
                if (fun === 'E') {
                    newState = {
                        ...newState,
                        [TransactionOn.EMPLOYEE]: true
                    };
                    if (isJustOneOption) {
                        this.setTransactionOnEmployee();
                    }
                }
            });
            this.setState(() => ({
                ...newState
            }));
        } else {
            this.setState(() => ({
                [TransactionOn.WORKORDER]: true,
                [TransactionOn.PROJECT]: true,
                [TransactionOn.EQUIPMENT]: true,
                [TransactionOn.EMPLOYEE]: true
            }));
        }

    };

    renderPage () {
        if (!this.props.currentStore) {
            return null
        }

        if (this.props.transactionType !== 'issue' && this.props.transactionType !== 'return') {
            return <TransactionInfo transactionType={this.props.transactionType}
                                    storeCode={this.props.currentStore.code} history={this.props.history}/>
        }

        const {currentTransaction} = this.props;

        return (
            <div style={{height: '100%', overflowY: 'auto'}} id="scrollthis">
                <StoreInfo currentStore={this.props.currentStore} history={this.props.history} kioskMode={this.props.kioskMode} logout={this.props.logout}/>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    flexGrow: '1',
                    marginTop: '20px'
                }}>
                    <Typography style={{width: '100%', textAlign: 'center', fontSize: '20px'}} variant="body2" component="span">
                            {this.props.transactionType === 'issue' ? 'To be used for:' : 'Return from:'}
                    </Typography>
                    <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center'}}>
                        {this.state[TransactionOn.WORKORDER] &&
                        <KioskButton
                            color={currentTransaction.transactionOn === TransactionOn.WORKORDER ? 'primary' : 'info'}
                            onClick={this.setTransactionOnWorkorder}>
                            Work Order/Activity
                        </KioskButton>
                        }
                        {this.state[TransactionOn.PROJECT] &&
                        <KioskButton
                            color={currentTransaction.transactionOn === TransactionOn.PROJECT ? 'primary' : 'info'}
                            onClick={this.setTransactionOnProject}>
                            Project/Budget
                        </KioskButton>
                        }
                        {this.state[TransactionOn.EQUIPMENT] &&
                        <KioskButton
                            color={currentTransaction.transactionOn === TransactionOn.EQUIPMENT ? 'primary' : 'info'}
                            onClick={this.setTransactionOnEquipment}>
                            EQUIPMENT/LOCATION
                        </KioskButton>
                        }
                        {this.state[TransactionOn.EMPLOYEE] &&
                        <KioskButton
                            color={currentTransaction.transactionOn === TransactionOn.EMPLOYEE ? 'primary' : 'info'}
                            onClick={this.setTransactionOnEmployee}>
                            Employee
                        </KioskButton>
                        }
                    </div>
                </div>

                <ContextSelectionPageDetailsContainer history={this.props.history}/>

            </div>
        );
    }
}

export default ContextSelectionPage;
