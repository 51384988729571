import { handleError } from '../../../../react-eis-components/actions/uiActions';
import { connect } from 'react-redux';
import { logout } from "../../../../actions/applicationActions";
import { setRequestProcessItems } from "../../../../actions/requestProcessActions";
import OutboundRequestSelectItems from "./OutboundRequestSelectItems";

const mapStateToProps = (state, ownProps) => {
    const language = state.layout.lang;
    const store = state.application.currentStore;
    const storeConfiguration = store ? state.application.storesConfiguration[store.code] : {};

    return {
        kioskMode: state.application.kioskMode,
        applicationData: state.application.applicationData,
        storeConfiguration,
        translations: store ? state.application.translations[language]['KIOSK_' + store.code] : {},
        storeCode: ownProps.match.params.storeCode,
        storeAccess: state.application.storeAccess,
        currentStore: state.application.currentStore,
        groupStore: state.application.groupStore,
    }
};

const OutboundRequestSelectItemsContainer = connect(
    mapStateToProps, {
        logout,
        handleError,
        setRequestProcessItems
    }
)(OutboundRequestSelectItems);

export default OutboundRequestSelectItemsContainer;