const STORE_TYPES = {
    GENERAL: 'general',
    STORE: 'store',
}

const ROUTES_BASE = {
    HOME: '',
    STORE_MENU: ':storeType/:storeCode',
    USER_SETTINGS: 'settings',
    RELEASE_NOTES: 'releasenotes',
    SINGLE_STORE_MENU: `${STORE_TYPES.STORE}/:storeCode`,
    GROUP_STORE_MENU: `${STORE_TYPES.GENERAL}/:storeCode`,
}
ROUTES_BASE.OUTBOUND_PICKTICKET = `${ROUTES_BASE.SINGLE_STORE_MENU}/outbound/picktickets/:id`;
ROUTES_BASE.REQUEST_GRID = `${ROUTES_BASE.STORE_MENU}/request`;
ROUTES_BASE.REQUEST_ITEMS_DESTINATION = `${ROUTES_BASE.STORE_MENU}/request/destination`;
ROUTES_BASE.OUTBOUND_GRID = `${ROUTES_BASE.STORE_MENU}/outbound`;
ROUTES_BASE.INBOUND_GRID = `${ROUTES_BASE.STORE_MENU}/inbound`;
ROUTES_BASE.INBOUND_SIT = `${ROUTES_BASE.SINGLE_STORE_MENU}/inbound/:sitCode`;
ROUTES_BASE.TRANSACTION_ISSUE = `${ROUTES_BASE.SINGLE_STORE_MENU}/transaction/issue/:transactionOnType?`;
ROUTES_BASE.TRANSACTION_RETURN = `${ROUTES_BASE.SINGLE_STORE_MENU}/transaction/return/:transactionOnType?`;
ROUTES_BASE.TRANSACTION_ON = `${ROUTES_BASE.SINGLE_STORE_MENU}/transaction/:transactionType/:transactionOnType?`;

const applyToFields = (obj, func) => Object.entries(obj).map(([key, value]) => [key, func(value)]).reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

const ROUTES = applyToFields(ROUTES_BASE, (route) => `${process.env.REACT_APP_FRONTEND}${route}`);

export {
    ROUTES,
    STORE_TYPES,
}
