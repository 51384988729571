import React from 'react';
import KioskButton from '../../../components/buttons/KioskButton';
import Delete from 'mdi-material-ui/Delete';
import EISTable from '../../../../react-eis-components/ui/components/table';
import WS from '../../../../tools/rest/WS';
import WSPartSelection from '../../../../tools/rest/WSPartSelection';
import KioskDialog from '../KioskDialog';
import ErrorTypes from "../../../../react-eis-components/enums/ErrorTypes";
import { withStyles } from '@mui/styles';
import EAMAutocomplete from 'eam-components/dist/ui/components/inputs-ng/EAMAutocomplete';
import TransactionTypes from '../../../../enums/TransactionTypes';
import InfoIcon from '@material-ui/icons/Info';
import { createOnChangeHandler } from 'eam-components/dist/ui/components/inputs-ng/tools/input-tools';

const classes = {
    papercontainer: {
        marginTop: '10px',
        marginBottom: '10px',
        paddingTop: '10px',
        paddingLeft: '5px',
        paddingRight: '5px'
    },
    '@media (min-width: 400px)': {
        papercontainer: {
            padding: '30px',
            margin: '10px',
            border: '1px solid lightgray'
        }
    },
    mobileBorder: {
        border: '2px solid aliceblue',
        borderRadius: '3px',
    },
    infoText: {
        backgroundColor: '#f1f1f1',
        borderRadius: '4px',
        padding: '8px',
        color: '#006598',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '4px',
    }
};

class TransactionsTable extends React.Component {

    headers = ['Part', 'Part Description', 'Asset ID', 'Bin', 'Lot', 'Qty', 'UOM', 'Delete'];
    propCodes = ['partCode', 'partDesc', 'assetIDCode', 'bin', 'lot', 'transactionQty', 'uom', 'delete'];

    state = {
        openDialog: false,
        transactionNumber: undefined
    };

    submitTransaction = () => {
        let areThereUnifinishedLines = false;
        this.props.currentTransaction.transactionlines.forEach(line => {
            if (line.needingCompletion) {
                areThereUnifinishedLines = true;
            }
        });

        if (areThereUnifinishedLines) {
            this.props.showWarning("Lines marked in yellow must be completed before submitting transaction.");
            return;
        }

        // Remove the attribute 'needingCompletion' from transaction's lines
        // as this is not a proper json property for the IssueReturnPartTransaction class
        const currentTransaction = {
            ...this.props.currentTransaction,
            transactionlines: this.props.currentTransaction.transactionlines.map(({needingCompletion, uom, ...line}) => (line))
        };

        // Submit the transaction
        return WS.submitTransaction(currentTransaction).then((result) => {
            this.setState(() => ({
                transactionNumber: result.body.data,
                openDialog: true
            }));
        }).catch((error) => {
            this._handleError(error);
        });
    };

    _handleError = (error) => {
        const { showError } = this.props;
        switch (error.type) {
            case ErrorTypes.CONNECTION_ABORDED:
                showError("The server did not respond in a timely fashion. Please try again or check your internet connection.");
                break;

            case ErrorTypes.NETWORK_ERROR:
                showError("The server could not be reached.");
                break;

            case ErrorTypes.SERVER_ERROR:
                if (error.response && error.response.body) {
                    const errors = error.response.body.errors;
                    if (errors && errors.length > 0) {
                        showError(errors[0].detail)
                    }
                }
                break;
            default:
                showError('Internal error')
                break;
        }
    };

    confirm = (action) => () => {
        this.props.openConfirmDialog(
            {
                title: "Confirmation dialog",
                message: "Do you want to submit the transaction?",
                cancelButtonLabel: "No",
                confirmButtonLabel: "Yes",
                waitForCompletion: true
            },
            action
        );
    };

    deleteRow = index => {
        if (this.props.selectedRowIndexes.includes(index)) {
            this.props.updateProperty("selectedRowIndexes", [])
        }

        this.props.removeLineFromTransaction(index);
    };

    prepareAutocompleteInputAsset = (code, partCode, config) => {
        return WSPartSelection
            .autocompleteAsset(code, partCode, this.props.currentStore.code, this.props.currentTransaction.transactionType, null, null, config);
    };

    updateProperty = (key, value, index) => {
        let alreadyChosen = false;

        this.props.currentTransaction.transactionlines.forEach(line => {
            if (line.assetIDCode === value) {
                alreadyChosen = true;
            }
        });

        if (alreadyChosen) {
            this.props.showWarning("Asset already selected.");
            return;
        }

        this.props.updateLineInTransaction(index, {
            assetIDCode: value,
            needingCompletion: false
        });

        WSPartSelection.getAssetData(value, this.props.currentStore.code, this.props.currentTransaction.transactionType)
            .then(response => {
                this.props.updateLineInTransaction(index, {
                    bin: response.body.data.bin,
                    lot: response.body.data.lot
                })
            });

        this.props.updateProperty("selectedRowIndexes", []);
    };

    prepareDataForTable = () => {
        return this.props.currentTransaction.transactionlines.map((line, index) => {
            let lineToTable = {
                ...line,
                "delete":
                    <Delete
                        onClick={() => this.deleteRow(index)}
                        style={{cursor: "pointer"}}
                    />
            };

            if (line.needingCompletion) {
                lineToTable = {
                    ...lineToTable,
                    assetIDCode: <EAMAutocomplete
                        value={line.assetIDCode}
                        onChange={createOnChangeHandler(
                            "assetCode", null, null, this.updateProperty, null, [index]
                        )}
                        autocompleteHandler={(code, config) =>
                            this.prepareAutocompleteInputAsset(
                                code, line.partCode, config
                            )
                        }
                    />
                }
            }

            return lineToTable;
        })
    };

    onRowClick = (row, index) => {
        if (row.needingCompletion) {
            return;
        }

        if (this.props.selectedRowIndexes.includes(index)) {
            this.props.updateProperty("selectedRowIndexes", []);
        }

        else {
            this.props.updateProperty('selectedRowIndexes', [index]);
        }
    };

    render() {
        const {classes, translations} = this.props;
        const data = this.prepareDataForTable();

        return (
            !data || data.length === 0 ? null :
            <div elevation={4} className={classes.papercontainer}>
                <div className={classes.infoText}>
                    <React.Fragment>
                        <InfoIcon />
                        <span style={{ paddingLeft: 12 }}>{"Please note that the transaction is only registered after the operation is confirmed."}</span>
                    </React.Fragment>
                </div>
                <KioskDialog open={this.state.openDialog}
                             transactionNumber={this.state.transactionNumber}
                             history={this.props.history}
                             storeCode={this.props.currentTransaction.storeCode}
                             resetTransaction={this.props.resetTransaction}
                             kioskMode={this.props.kioskMode}
                             logout={this.props.logout}
                />
                <div className={classes.mobileBorder}>
                    <EISTable
                        headers={this.headers}
                        propCodes={this.propCodes}
                        data={data}
                        selectedRowIndexes={this.props.selectedRowIndexes}
                        onRowClick={this.onRowClick}
                        stylesMap={{
                            needingCompletion: {
                                backgroundColor: "yellow"
                            }
                        }}
                        hideSort
                    />

                    <div style={{display: 'flex', justifyContent: 'center', flexGrow: 1}}>
                        {this.props.currentTransaction.transactionlines.length > 0 &&
                        <KioskButton style={{margin: '15px'}} onClick={this.confirm(this.submitTransaction)}>
                            {
                                this.props.currentTransaction.transactionType === TransactionTypes.ISSUE ?
                                    (translations['ISSUE'] || 'Issue') :
                                    (translations['RETURN'] || 'Return')
                            }
                        </KioskButton>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default withStyles(classes)(TransactionsTable);