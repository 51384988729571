import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import { Component } from 'react';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { BrowserRouter as Router, Link, Redirect, Route, Switch, generatePath } from 'react-router-dom';
import config from "./config";
import { version } from '../package.json';
import './Kiosk.css';
import KioskAppBarContainer from './KioskAppBarContainer';
import './react-eis-components/ui/layout/CERNToolbar.css';
import EISLayout from './react-eis-components/ui/layout/EISLayout';
import Themes from "./theme";
import KioskLoader from './tools/KioskLoader';
import InfoPage from './ui/components/infopage/InfoPage';
import Bin2Bin from './ui/pages/Bin2Bin';
import ContextSelectionPageContainer from "./ui/pages/ContextSelection/ContextSelectionPageContainer";
import SITDocArticlesContainer from "./ui/pages/FlexBuilding/Inbound/SITDocArticlesContainer";
import SITPlaceItemsOnBinContainer from "./ui/pages/FlexBuilding/Inbound/SITPlaceItemsOnBinContainer";
import SITSelectionAssetsContainer from "./ui/pages/FlexBuilding/Inbound/SITSelectionAssetsContainer";
import SITSelectionBinsContainer from "./ui/pages/FlexBuilding/Inbound/SITSelectionBinsContainer";
import SITSelectionPageContainer from "./ui/pages/FlexBuilding/Inbound/SITSelectionPageContainer";
import KITContextSelectionPageContainer from "./ui/pages/FlexBuilding/Outbound/KITContextSelectionPageContainer";
import KITOutboundConfirmationPageContainer from "./ui/pages/FlexBuilding/Outbound/KITOutboundConfirmationPageContainer";
import OutboundItemListLoaderContainer from './ui/pages/FlexBuilding/Outbound/OutboundItemListLoaderContainer';
import OutboundPickTicketSearchContainer from './ui/pages/FlexBuilding/Outbound/OutboundPickTicketSearchContainer';
import OutboundRequestDestinationContainer from "./ui/pages/FlexBuilding/Request/OutboundRequestDestinationContainer";
import OutboundRequestSelectItemsContainer from "./ui/pages/FlexBuilding/Request/OutboundRequestSelectItemsContainer";
import SITFlexMainPageContainer from './ui/pages/FlexBuilding/SITFlexMainPageContainer';
import Inventory from './ui/pages/Inventory';
import InventoryModeSelector from './ui/pages/Inventory/InventoryModeSelector';
import InventoryPerform from './ui/pages/Inventory/InventoryPerform';
import IssueReturnSelectionPageContainer from "./ui/pages/IssueReturnSelection/IssueReturnSelectionPageContainer";
import UserIdentificationPageContainer from "./ui/pages/KioskMode/UserIdentificationPageContainer";
import OutstandingIssuesPageContainer from "./ui/pages/OutstandingIssues/OutstandingIssuesPageContainer";
import PartsAssetsSelectionPageContainer from "./ui/pages/PartsAsstesSelection/PartsAssetsSelectionPageContainer";
import PickTicketPage from "./ui/pages/PickTicket/PickTicketPage";
import SearchEquipmentPageContainer from "./ui/pages/SearchEquipment/SearchEquipmentPageContainer";
import StoreIndexContainer from "./ui/pages/StoreMenu/StoreIndexContainer";
import Footer from 'eam-components/dist/ui/components/footer/Footer';
import GroupStoreMenu from 'ui/pages/GroupStoreMenu';
import { ROUTES, STORE_TYPES } from './Routes';
import UserSettings from 'ui/pages/UserSettings';
import Transaction, { TRANSACTION_TYPE } from 'ui/pages/Transaction';
import ReleaseNotesPage from 'ui/pages/ReleaseNotes/ReleaseNotesPage';

const classes = {
    '@media (max-width: 400px)': {
        hideOnMobile: {
            display: "none"
        }
    }
};
class Kiosk extends Component {

    blockUiStyle = {
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    };

    blockUiStyleDiv = {
        display: "flex",
        height: 60,
        alignItems: "flex-end"
    };

    componentDidMount() {
        const { initializeApplication } = this.props;
        console.info('Initializing');
        initializeApplication();
    }

    render() {
        const { applicationData } = this.props;

        // User data still not there, display loading page
        if (!this.props.userData) {
            return (
                <BlockUi tag="div" blocking={true} style={this.blockUiStyle}>
                    <div style={this.blockUiStyleDiv}>Loading KIOSK ...</div>
                </BlockUi>
            )
        }

        // Error when initializing application
        if (this.props.userData.invalidAccount) {
            return <InfoPage title="Error"
                             message="Application couldn't be initialized. Plase contact CMMS Support"/>
        }

        const loginRequired = this.props.kioskMode;
        const shouldUserLogin = loginRequired && !this.props.loginToken;
        const selectedTheme = Themes[config.theme[applicationData.environment] || config.theme.DEFAULT] || Themes.DANGER;

        return (
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={selectedTheme}>
                        <Router basename={process.env.PUBLIC_URL}>
                            <EISLayout
                                userDesc={this.props.userData.eamAccount.userDesc}
                                userCode={this.props.userData.eamAccount.userCode}
                                appName={
                                    <div style={{marginLeft: 10, display: 'flex', alignItems: 'center', height: '100%'}}>
                                        <Link to={process.env.REACT_APP_FRONTEND} style={{ textDecoration: 'none' }}>
                                            <div style={{color: '#FFF', fontWeight: "bold", fontSize: 18, display:'flex'}}>
                                                <div>EAM</div>
                                                <div style={{marginLeft:5}} >Store Kiosk</div>
                                            </div>
                                        </Link>
                                    </div>
                                }
                                userInstitution="CERN"
                                signOutFromApplication={() => (new Promise(() => this.props.logout()))}
                                signOutFromCERN={() => {
                                }}
                                appBarContent={<KioskAppBarContainer history={this.props.history}/>}
                                showCERNToolbar={false}
                                footerContent={
                                    <Footer
                                        appName={"EAM Kiosk"}
                                        version={version}
                                        supportEmail={applicationData.supportContact}
                                        releaseNotesPath={ROUTES.RELEASE_NOTES}
                                    />
                                }
                            >
                                <KioskLoader />
                                <div style={{height: "100%", padding: '2px', boxSizing: 'border-box', overflow: 'auto'}}>
                                    <Switch>
                                        {/* <Redirect
                                            exact={true}
                                            from={process.env.REACT_APP_FRONTEND}
                                            to={process.env.REACT_APP_FRONTEND + `store`}
                                        /> */}
                                        <Route
                                            exact={true}
                                            path={ROUTES.HOME}
                                            component={StoreIndexContainer}
                                        />
                                        <Route
                                            exact={true}
                                            path={ROUTES.USER_SETTINGS}
                                            component={UserSettings}
                                        />
                                        <Route
                                            exact={true}
                                            path={ROUTES.RELEASE_NOTES}
                                            component={ReleaseNotesPage}
                                        />
                                        {shouldUserLogin &&
                                            <Route
                                                path={process.env.REACT_APP_FRONTEND + "store/:storeCode"}
                                                component={UserIdentificationPageContainer}
                                            />
                                        }
                                        <Route
                                            exact={true}
                                            path={ROUTES.GROUP_STORE_MENU}
                                            component={GroupStoreMenu}/>
                                        <Route
                                            exact={true}
                                            path={ROUTES.REQUEST_GRID}
                                            component={OutboundRequestSelectItemsContainer}
                                        />
                                        <Route
                                            exact={true}
                                            path={ROUTES.REQUEST_ITEMS_DESTINATION}
                                            component={OutboundRequestDestinationContainer}
                                        />
                                        <Route
                                            exact={true}
                                            path={ROUTES.OUTBOUND_GRID}
                                            component={OutboundPickTicketSearchContainer}
                                        />
                                        <Route
                                            exact={true}
                                            path={ROUTES.INBOUND_GRID}
                                            component={SITSelectionPageContainer}
                                        />
                                        <Route
                                            exact={true}
                                            path={ROUTES.TRANSACTION_ISSUE}
                                            component={(props) => <Transaction {...props} transactionType={TRANSACTION_TYPE.ISSUE} />}
                                        />
                                        <Route
                                            exact={true}
                                            path={ROUTES.TRANSACTION_RETURN}
                                            component={(props) => <Transaction {...props} transactionType={TRANSACTION_TYPE.RETURN} />}
                                        />

                                        <Route
                                            exact={true}
                                            path={process.env.REACT_APP_FRONTEND + "store/:storeCode"}
                                            component={IssueReturnSelectionPageContainer}/>
                                        <Route exact={true}
                                            path={process.env.REACT_APP_FRONTEND + "store/:storeCode/inventory/"}
                                            component={Inventory}/>
                                        <Route exact={true}
                                            path={process.env.REACT_APP_FRONTEND + "store/:storeCode/inventory/create"}
                                            component={InventoryModeSelector}/>
                                        <Route exact={true}
                                            path={process.env.REACT_APP_FRONTEND + "store/:storeCode/inventory/:inventory"}
                                            component={InventoryPerform}/>
                                        <Route exact={true}
                                            path={process.env.REACT_APP_FRONTEND + "store/:storeCode/bin2bin/"}
                                            component={Bin2Bin}/>

                                        <Route exact={true}
                                            path={process.env.REACT_APP_FRONTEND + "store/:storeCode/inbound/articles/:sitCode"}
                                            component={SITSelectionPageContainer}/>

                                        <Route
                                            exact={true}
                                            path={ROUTES.INBOUND_SIT}
                                            component={SITDocArticlesContainer}
                                        />

                                        <Route exact={true}
                                            path={process.env.REACT_APP_FRONTEND + "store/:storeCode/inbound/:sitCode/items"}
                                            component={SITSelectionAssetsContainer}/>

                                        <Route exact={true}
                                            path={process.env.REACT_APP_FRONTEND + "store/:storeCode/inbound/:sitCode/items/bins"}
                                            component={SITPlaceItemsOnBinContainer}/>

                                        <Route exact={true}
                                            path={process.env.REACT_APP_FRONTEND + "store/:storeCode/inbound/:sitCode/items/:item/bin"}
                                            component={SITSelectionBinsContainer}/>

                                        <Route exact={true}
                                            path={process.env.REACT_APP_FRONTEND + "store/:storeCode/outbound/picktickets/:id"}
                                            component={OutboundItemListLoaderContainer}/>



                                        <Route exact={true}
                                            path={process.env.REACT_APP_FRONTEND + "store/:storeCode/outbound/:partcode"}
                                            component={KITContextSelectionPageContainer}/>
                                        <Route exact={true}
                                            path={process.env.REACT_APP_FRONTEND + "store/:storeCode/outbound/:partcode/:entitycode"}
                                            component={KITOutboundConfirmationPageContainer}/>

                                        <Route exact={true}
                                            path={process.env.REACT_APP_FRONTEND + "store/:storeCode/flex/"}
                                            component={SITFlexMainPageContainer}/>
                                        <Route exact={true}
                                            path={process.env.REACT_APP_FRONTEND + "store/:storeCode/outstanding"}
                                            component={OutstandingIssuesPageContainer}/>
                                        <Route exact={true}
                                            path={process.env.REACT_APP_FRONTEND + "store/:storeCode/search"}
                                            component={SearchEquipmentPageContainer}/>
                                        <Route exact={true}
                                            path={process.env.REACT_APP_FRONTEND + "store/:storeCode/pickticket"}
                                            component={PickTicketPage}/>
                                        <Route exact={true}
                                            path={process.env.REACT_APP_FRONTEND + "store/:storeCode/:transactiontype"}
                                            component={ContextSelectionPageContainer}/>
                                        <Route exact={true}
                                            path={process.env.REACT_APP_FRONTEND + "store/:storeCode/:transactiontype/:transactioncontext"}
                                            component={PartsAssetsSelectionPageContainer}/>
                                        {/* <Redirect
                                            from={process.env.REACT_APP_FRONTEND + '*'}
                                            to={process.env.REACT_APP_FRONTEND + `store/*`}
                                        /> */}
                                        <Route
                                            path={process.env.REACT_APP_FRONTEND + '*'}
                                            exact
                                            component={({match}) => {
                                                const first = match.params?.[0];
                                                if (!first.startsWith('store/')) {
                                                    const to = generatePath(process.env.REACT_APP_FRONTEND + `store/*` + location.search, match.params);
                                                    console.log(`Redirecting from ${location.pathname} to ${to}`);
                                                    return <Redirect to={to}/>
                                                }
                                                return  <div>404 - Path not found</div>;
                                            }}
                                        />
                                    </Switch>
                                </div>
                            </EISLayout>
                        </Router>
                    </ThemeProvider>
                </StyledEngineProvider>
        );
    }
}

export default withStyles(classes)(Kiosk);
