import React, {Component} from 'react';
import PropTypes from 'prop-types';

/**
 * CERN Official toolbar
 */
export default class CERNToolbar extends Component {

  /**
   * Click on sign out button
   * There are two steps in signing out:
   *  1. sign out from application. Application specific so should be implemented by the user of this component
   *  2. sign out from login.cern.ch
   */
    signOut(event) {
      event.preventDefault();
      return this._signOutFromApplication()
                 .then(this._signOutFromCERN.bind(this));
    }

    _signOutFromApplication() {
        if (this.props.signOutFromApplication) {
            return this.props.signOutFromApplication();
        } else {
            return Promise.resolve();
        }
    }

    _signOutFromCERN() {
        if (this.props.signOutFromCERN) {
            return this.props.signOutFromCERN();
        } else {
            document.location.href='https://login.cern.ch/adfs/ls/?wa=wsignout1.0';
        }
    }


    render() {
        return (
            <div id="cern-toolbar">

                <h1><a href="https://home.cern" title="CERN">CERN <span>Accelerating science</span></a></h1>

                <ul className="cern-signedin">
                    <li className="cern-accountlinks">
                      <span>Signed in as:
                        <a className="account" href="https://cern.ch/account" title={`Signed in as ${this.props.userDesc} (${this.props.userCode})`}>{this.props.userDesc} {this.props.userInstitution && `(${this.props.userInstitution})`}</a>
                      </span>

                      <a className="cern-signout" onClick={this.signOut.bind(this)} href="#" title="Sign out of your account">Sign out</a>
                    </li>

                  <li>
                    <a className="cern-directory" href="https://cern.ch/directory" title="Search CERN resources and browse the directory">Directory</a>
                  </li>
                </ul>

            </div>
        )
    }
}

CERNToolbar.propTypes = {
    signOutFromApplication: PropTypes.func,
    signOutFromCERN: PropTypes.func,
    userDesc: PropTypes.string,
    userCode: PropTypes.string,
    userInstitution: PropTypes.string
};