import { connect } from 'react-redux';
import ConfirmDialog from './ConfirmDialog';
import {closeConfirmDialog} from '../../../actions/uiActions';

const mapStateToProps = (state) => {
  return {
    ...state.ui.confirmDialog
  }
};

const ConfirmDialogContainer = connect(mapStateToProps,
    {closeConfirmDialog}
)(ConfirmDialog);

export default ConfirmDialogContainer