import React from 'react';
import SelectionPartsContainer from "./SelectionPanels/SelectionPartsContainer"
import StoreInfo from "../../components/elements/StoreInfo";
import TransactionsTableContainer from "./SelectionPanels/TransactionsTableContainer";
import TransactionInfo from "../../components/elements/TransactionInfo";
import EntityContextInfo from "../../components/elements/EntityContextInfo";
import TransactionOn from "../../../enums/TransactionOn";
import WS from '../../../tools/rest/WSPartSelection'
import ErrorTypes from "../../../react-eis-components/enums/ErrorTypes";

class PartsAssetsSelectionPage extends React.Component {

    state = {
        selectedRowIndexes: []
    };

    componentDidMount() {
        const {
            transactionType,
            transactionContext,
            workOrderNumber,
            activityCode,
            projectCode,
            budgetCode,
            equipmentCode,
            employeeCode,
            departmentCode,
            currentTransaction,
            transLines,
        } = this.props;
        if (transactionType && !transLines.currentTransaction.transactionType) {
            this.props.updateTransactionType(transactionType);
        }
        if (transactionContext) {
            switch (transactionContext.toUpperCase()) {
                case TransactionOn.WORKORDER:
                    this.props.setTransactionOnWorkorder();
                    this.fetchPartsForWO(workOrderNumber, activityCode);
                    break;
                case TransactionOn.PROJECT:
                    this.props.setTransactionOnProject();
                    break;
                case TransactionOn.EQUIPMENT:
                    this.props.setTransactionOnEquipment();
                    break;
                case TransactionOn.EMPLOYEE:
                    this.props.setTransactionOnEmployee();
                    break;
                default:
                    return null;
            }
        } else {
            this.props.resetTransactionOn();
        }
        /*
         * Update entity context
         */
        if (workOrderNumber) {
            this.props.updateWorkOrder(workOrderNumber);
        }
        if (activityCode) {
            this.props.updateActivity(activityCode);
        }
        if (projectCode) {
            this.props.updateProject(projectCode);
        }
        if (budgetCode) {
            this.props.updateBudget(budgetCode);
        }
        if (equipmentCode) {
            this.props.updateEquipment(equipmentCode);
        }
        if (employeeCode) {
            this.props.updateEmployee(employeeCode);
        }
        if (departmentCode) {
            this.props.updateDepartmentCode(departmentCode);
        }
    }

    updateProperty = (key, value) => {
        this.setState(() => ({
            [key]: value
        }));
    };

    fetchPartsForWO = (wonumber, activity) => {
        if (!this.props.currentStore) {
            setTimeout(this.fetchPartsForWO, 250, wonumber, activity);
            return;
        }
        WS.getPartsForWO(this.props.currentStore.code, wonumber, activity)
            .then(response => {
                const data = response.body.data;

                data.forEach(line => {
                    this.props.addLineToCurrentTransaction(line);
                })
            })
            .catch(err => {
                this.props.handleError(err);
            })
    };

    render() {

        if (!this.props.currentStore) {
            return null
        }

        if (this.props.transactionType !== 'issue' && this.props.transactionType !== 'return') {
            return <TransactionInfo transactionType={this.props.transactionType}
                                    storeCode={this.props.currentStore.code}
                                    history={this.props.history}/>
        }

        if (this.props.transactionContext !== TransactionOn.WORKORDER &&
            this.props.transactionContext !== TransactionOn.PROJECT &&
            this.props.transactionContext !== TransactionOn.EQUIPMENT &&
            this.props.transactionContext !== TransactionOn.EMPLOYEE) {
            return <EntityContextInfo transactionType={this.props.transactionType}
                                      transactionContext={this.props.transactionContext}
                                      storeCode={this.props.currentStore.code}
                                      history={this.props.history}/>
        }

        return (
            <div style={{height: '100%', overflowY: 'auto'}}>
                <StoreInfo currentStore={this.props.currentStore} history={this.props.history} kioskMode={this.props.kioskMode} logout={this.props.logout}/>
                <SelectionPartsContainer
                    selectedRowIndexes={this.state.selectedRowIndexes}
                    updateProperty={this.updateProperty}
                />
                <TransactionsTableContainer
                    history={this.props.history}
                    selectedRowIndexes={this.state.selectedRowIndexes}
                    updateProperty={this.updateProperty}
                />
            </div>
        );
    }
}

export default PartsAssetsSelectionPage;