import {UPDATE_LAYOUT, SET_PAGE} from '../actions/layoutActions'

export default function application(state = buildLayoutObject(), action) {
    switch (action.type) {
        case UPDATE_LAYOUT:
            return {
                ...state,
                ...action.value
            };
        case SET_PAGE: {
            return {
                ...state,
                currentPage: action.page
            };
        }
        default:
            return state
    }
}

function buildLayoutObject() {
    return {
        lang: "EN",
        currentPage: ''
    }
}
