import React from 'react';
// import Button from '@mui/material/Button';
import { Button } from '@mui/material';

class TransactionInfo extends React.Component {

    goToIssueReturnSelection = () => {
        this.props.history.push(process.env.REACT_APP_FRONTEND + `${this.props.storeCode}`)
    };

    render() {
        if (this.props.transactionType !== 'issue' && this.props.transactionType !== 'return') {
            return (
                <div style={{
                    fontWeight: 'bold',
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                }}>
                    <div style={{marginRight: '50px', marginTop: '10px'}}>
                        {`${this.props.transactionType} TRANSACTION TYPE ERROR`}
                    </div>
                    <Button variant="contained" color="primary" onClick={this.goToIssueReturnSelection} style={{marginTop: '10px'}}>
                        Change
                    </Button>
                </div>
            )
        }
    }
}

export default TransactionInfo;