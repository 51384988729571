import { handleError, openConfirmDialog, showSuccess, showError } from '../../../react-eis-components/actions/uiActions';

import actions from '../../../actions/inventoryActions';
import { connect } from 'react-redux';
import { logout } from "../../../actions/applicationActions";
import { getRoutes } from "../../../tools/routes";
import { inventoryGettersWithState } from "../../../reducers/inventoryReducer";

const mapStateToProps = (state, ownProps) => {
    const language = state.layout.lang;
    const store = state.application.currentStore;
    const storeConfiguration = store ? state.application.storesConfiguration[store.code] : {};
    const storeCode = ownProps.match.params.storeCode;
    const inventoryGetters = inventoryGettersWithState(state);
    const inventoryCode = ownProps.match.params.inventory

    return {
        kioskMode: state.application.kioskMode,
        applicationData: state.application.applicationData,
        storeConfiguration,
        translations: state.application.translations[language]['KIOSK_' + storeCode],
        storeCode,
        requestProcess: state.requestProcess,
        routes: getRoutes(process.env.REACT_APP_FRONTEND, storeCode),
        inventoryCode,
        ...inventoryGetters,
        storeAccess: state.application.storeAccess,
        currentStore: state.application.currentStore,
    }
};

const Containerize = Component => connect(
    mapStateToProps, {
        logout,
        handleError,
        showSuccess,
        showError,
        openConfirmDialog,
        ...actions
    }
)(Component);

export default Containerize;
