import React from 'react';
import Paper from '@mui/material/Paper';
import WS from './../../../tools/rest/WS';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import KioskButton from '../../components/buttons/KioskButton';
import axios from 'axios';
import InfoPage from "../../components/infopage/InfoPage";
import EAMTextField from 'eam-components/dist/ui/components/inputs-ng/EAMTextField';
import { createOnChangeHandler } from 'eam-components/dist/ui/components/inputs-ng/tools/input-tools';


class UserIdentificationPage extends React.Component {

    state = {
        userInput: "",
    };

    flexContainerStyle = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        flexDirection: "column"
    };

    paperStyle = {
        width: "80%",
        minWidth: 300,
        height: 400,
        display: "flex",
        alignItems: "center",
        flexDirection: "column"
    };

    blockUiStyle = {
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    };

    blockUiStyleDiv = {
        display: "flex",
        height: 60,
        alignItems: "flex-end"
    };

    componentWillMount() {
        delete axios.defaults.headers.common.Authorization;
    }

    handleInput = (key, value) => {
        this.setState(() => ({
            userInput: value
        }));
    };

    handleKeyUp = event => {
        if (event.keyCode === 13) {
            event.preventDefault();
            event.target.blur();
        }
    };

    fetchUserInfo = (user = null) => {
        this.setState(() => ({
            fetching: true
        }));

        let userId = user || this.state.userInput;

        WS.getIdentificationResult(userId, this.props.store)
            .then(response => {
                this.setState(() => ({
                    firstName: response.body.data.firstName,
                    lastName: response.body.data.lastName,
                    userName: response.body.data.userName,
                    personID: response.body.data.personID,
                    cernID: response.body.data.cernID,
                    fetching: false,
                    sourceInput: userId
                }))
            }).catch(error => {
                this.props.handleError(error);
                this.setState(() => ({
                    fetching: false
                }));
            })
    };

    handleLoginButtonClick = () => {
        this.fetchUserInfo();
    }

    handleNextButtonClick = () => {
        if (!this.state.userName) {
            return;
        }

        const auth = btoa(this.state.userName + ":" + this.props.store);

        this.setState(() => ({
            fetching: true
        }));

        WS.postLoginData(auth)
            .then(response => {
                this.setState(() => ({
                    fetching: false
                }));

                const token = response.body.data;

                if (token === "NOK") {
                    this.props.showError("Login invalid");
                } else {
                    // we got a proper token
                    this.props.login(token);

                    /**
                     * We need the token in the store to be sure that the user has logged in, but we do not want to send it to the server side to avoid problems with
                     * access rights to stores.
                     */
                    //axios.defaults.headers.common.Authorization = token;
                    //this.props.getInitData();
                    this.props.updateEmployee(this.state.cernID, this.state.firstName+' '+this.state.lastName);
                    this.props.history.push(process.env.REACT_APP_FRONTEND + this.props.store);
                }
            }).catch(error => {
                this.props.handleError(error);
                this.setState(() => ({
                    fetching: false
                }));
            })
    };

    render() {
        //TODO: not very good logic
        const isKioskMode = true;

        if (!isKioskMode) {
            return (
                <InfoPage title="Identification not available"
                          message="Identification only available in store kiosks"
                />
            )
        }

        if (!this.props.store) {
            return (
                <InfoPage title="Store not defined"
                          message="Store has to be defined to enable authentication."
                />
            )
        }

        return (
            <div style={this.flexContainerStyle}>
                <div><h2>Please scan your badge or input your username</h2></div>
                <Paper style={this.paperStyle}>
                    <div style={{width: "50%", minWidth: "300px", paddingTop: 50}} >
                        <EAMTextField
                            label="Username, CERN ID, or Person ID"
                            value={this.state.userInput}
                            onChange={createOnChangeHandler(
                                'userInput', null, null, this.handleInput, this.fetchUserInfo
                            )}
                            onKeyUp={this.handleKeyUp}
                            autoFocus
                        />
                    </div>
                {!this.state.fetching && this.state.firstName && (
                    <React.Fragment>
                        <div style={{padding: 10}}>
                            <h3>{this.state.firstName} {this.state.lastName}</h3>
                            <h3>Username: {this.state.userName}</h3>
                        </div>
                        <div style={{padding: 10}}>
                            <KioskButton onClick={this.handleNextButtonClick}>
                                Next
                            </KioskButton>
                        </div>
                    </React.Fragment>
                )}
                {this.state.fetching && (
                    <BlockUi tag="div" blocking={true} style={this.blockUiStyle}>
                        <div style={this.blockUiStyleDiv}>Loading data ...</div>
                    </BlockUi>
                )}
                {(!this.state.fetching && this.state.firstName === null && this.state.userInput && this.state.userInput === this.state.sourceInput) && (
                    <div>
                        <h2 style={{color: "red"}}>User not found</h2>
                    </div>
                )}
                {!this.state.fetching && !this.state.firstName &&
                    <div style={{padding: 10}}>
                        <KioskButton onClick={this.handleLoginButtonClick}>
                            Login
                        </KioskButton>
                    </div>
                }
                </Paper>
            </div>
        );
    }
}

export default UserIdentificationPage;