import React from 'react';
import Button from '@mui/material/Button';
import TransactionOn from "../../../enums/TransactionOn";

class EntityContextInfo extends React.Component {

    goToEntityContextSelection = () => {
        this.props.history.push(process.env.REACT_APP_FRONTEND + `${this.props.storeCode}/${this.props.transactionType}`)
    };

    render() {
        if (this.props.transactionContext !== TransactionOn.WORKORDER &&
            this.props.transactionContext !== TransactionOn.PROJECT &&
            this.props.transactionContext !== TransactionOn.EQUIPMENT &&
            this.props.transactionContext !== TransactionOn.EMPLOYEE) {
            return (
                <div style={{
                    fontWeight: 'bold',
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                }}>
                    <div style={{marginRight: '50px', marginTop: '10px'}}>
                        {`${this.props.transactionContext} TRANSACTION CONTEXT ENTITY ERROR`}
                    </div>
                    <Button variant="contained" color="primary" onClick={this.goToEntityContextSelection}
                            style={{marginTop: '10px'}}>
                        Change
                    </Button>
                </div>
            )
        } else
            return null;
    }
}

export default EntityContextInfo;