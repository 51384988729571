import { connect } from 'react-redux'
import SearchEquipmentPage from './SearchEquipmentPage'
import { addLinesToCurrentTransaction } from "../../../actions/currentTransactionActions";
import { showWarning } from '../../../react-eis-components/actions/uiActions';
import {logout} from "../../../actions/applicationActions";
import { updateTransactionType } from '../../../actions/currentTransactionActions';

const mapStateToProps = (state, ownProps) => {
    const store = state.application.currentStore;
    return {
        kioskMode: state.application.kioskMode,
        gridName: state.application.applicationData.searchPartGridName,
        currentStore: store,
        storeAccess: state.application && state.application.storeAccess,
    }
};

const SearchEquipmentPageContainer = connect(
    mapStateToProps, {
        logout,
        addLinesToCurrentTransaction,
        updateTransactionType,
        showWarning
    }
)(SearchEquipmentPage);

export default SearchEquipmentPageContainer;