import { createTheme } from '@mui/material/styles';
import { blue, green, red, lightBlue, lightGreen, deepOrange } from '@mui/material/colors';

function muiSettings(theme) {
    const primaryColor = theme.primaryColor;
    const secondaryColor = theme.secondaryColor;
    const primaryExtraLight = theme.primaryExtraLight;

    return {
        palette: {
            primary: {
                main: primaryColor,
                extraLight: primaryExtraLight
            },
            secondary: {
                main: secondaryColor
            },
            info: {
                main: '#e0e0e0'
            }
        },
        typography: {
            body1: {
                fontSize: '14px'
            }
        },
        overrides: {
            MuiButton: {
                root: {
                    color: "#585858"
                },
            },
        },
    }
};

const BLUE = createTheme(muiSettings({ primaryColor: blue[500], secondaryColor: lightBlue[900], primaryExtraLight: blue[50] }));
const GREEN = createTheme(muiSettings({ primaryColor: green[600], secondaryColor: lightGreen[900], primaryExtraLight: green[50] }));
const RED = createTheme(muiSettings({ primaryColor: red[500], secondaryColor: deepOrange[900] }));
const DANGER = createTheme(muiSettings({ primaryColor: red[900], secondaryColor: red[500] }));

export default {
    BLUE,
    GREEN,
    RED,
    DANGER
}
