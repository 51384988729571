import React from 'react';
import { withStyles } from '@mui/styles';
// import Button from '@mui/material/Button';
import Button from '@mui/material/Button';

const styles = theme => ({
    root: {
        boxShadow: '0 3px 5px 2px rgba(128, 128, 128, .20)',
        fontSize: 18
    },
    '@media ( min-width : 641px)': {
        sizeLarge: {
            minWidth: "130px",
            minHeight: "65px",
            marginLeft: '3px',
            marginRight: '3px'
        }
    },
    '@media ( max-width : 640px)': {
        sizeLarge: {
            marginLeft: '2px',
            marginRight: '2px'
        }
    },
});

class KioskButton extends React.Component {

    render() {
        const {classes, onClick, children, ...other} = this.props;

        return (
            <Button
                size="medium"
                color="primary"
                variant="contained"
                onClick={onClick}
                style={{minWidth: '270px', minHeight: '80px', margin: '5px 20px 5px 20px', padding: '4px'}}
                classes={{
                    root: classes.root,
                    sizeLarge: classes.sizeLarge
                }}
                {...other}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    width: '100%'
                }}>
                    <div style={{fontSize: '20px', textAlign: 'center'}}>
                        {
                            children
                        }
                    </div>
                </div>
            </Button>
        );
    }
}

export default withStyles(styles)(KioskButton);
