function getRoutes(baseRoute, store) {
    return {
        MAIN_PAGE: `${baseRoute}`,
        STORE_MAIN_PAGE: `${baseRoute}store/${store}`,
    };
}

export {
    getRoutes
};
