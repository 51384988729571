import EAMGrid from "eam-components/dist/ui/components/grids/eam/EAMGrid";
import { EAMGridContextProvider } from "eam-components/dist/ui/components/grids/eam/EAMGridContext";

const AMMGrid = ({ screenCode, rowStyler, getRowProps, onSelectRow, allowRowSelection, extraColumns, ...props}) => <EAMGridContextProvider
        searchOnMount
        gridName={screenCode}
        tableInstanceProps={{
            selectable: allowRowSelection
        }}
        onChangeSelectedRows={onSelectRow}
        modifyEAMGridColumns={(s) => [...(extraColumns?.map(({ width, t, label }) => ({ name: t, label: label ?? "", width: width?.replaceAll('px', '')})) ?? []), ...s]}
        {...props}
    >
        <EAMGrid
            getRowProps={(...args) => ({style: rowStyler?.(...args)})}
        />
    </EAMGridContextProvider>

export default AMMGrid;