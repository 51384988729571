import {connect} from 'react-redux';
import {setPage} from "../../../../actions/layoutActions";
import {handleError, showWarning} from '../../../../react-eis-components/actions/uiActions';
import SelectionParts from "./SelectionParts";
import {updateApplication} from "../../../../actions/applicationActions";
import { addLineToCurrentTransaction, removeLineFromCurrentTransaction } from "../../../../actions/currentTransactionActions";
import Tools from "../../../../tools/Tools";

const mapStateToProps = (state, ownProps) => {
    return {
        currentStore: state.application.currentStore,
        currentTransaction: state.currentTransaction,
        partCode:  Tools.getURLParameterByName("partCode"),
        bin:  Tools.getURLParameterByName("bin"),
        lot:  Tools.getURLParameterByName("lot"),
        configuration: state.application.storesConfiguration?.[state.application.currentStore?.code]?.extraConfiguration,
    }
};

const SelectionPartsContainer = connect(
    mapStateToProps, {
        setPage,
        updateApplication,
        handleError,
        showWarning,
        addLineToTransaction: addLineToCurrentTransaction,
        removeLineFromTransaction: removeLineFromCurrentTransaction
    }
)(SelectionParts);

export default SelectionPartsContainer;
