import React from 'react';
import KioskButton from '../../../components/buttons/KioskButton';
import ContextSelectionWorkOrder from "./SelectionPages/KITContextSelectionWorkOrder";
import KITContextSelectionEquipment from "./SelectionPages/KITContextSelectionEquipment";
import TransactionOn from "../../../../enums/TransactionOn";
import WSFlexBuilding from "../../../../tools/rest/WSFlexBuilding";

class ContextSelectionPage extends React.Component {

    goToPartsAssetsSelection = entityType => () => {
        const {history, currentStore, currentTransaction, kitcode} = this.props;
        const {transactionOn} = currentTransaction;

        switch (transactionOn) {
            case TransactionOn.WORKORDER:
                const {workOrderNumber, activityCode} = currentTransaction;
                if (!workOrderNumber || !activityCode) {
                    this.props.showWarning("Wrong workorder number or activity code");
                    return;
                }
                WSFlexBuilding.updateWOWithKit({
                    kitcode: kitcode,
                    storeCode: currentStore.code,
                    workorder: workOrderNumber,
                    activity: activityCode,
                    equipmentCode: null
                }).then(response => {
                    console.log('updateWOWithKit', response);
                    history.push(process.env.REACT_APP_FRONTEND + `${currentStore.code}/outbound/${kitcode}/${entityType}?wo=${workOrderNumber}&activity=${activityCode}`);
                }).catch((error) => {
                    this.props.handleError(error);
                });
                break;
            case TransactionOn.EQUIPMENT:
                const {equipmentCode} = currentTransaction;
                if (!equipmentCode) {
                    this.props.showWarning("Wrong equipment code");
                    return;
                }
                WSFlexBuilding.createWOWithKit({
                    kitcode: kitcode,
                    storeCode: currentStore.code,
                    workorder: null,
                    activity: null,
                    equipmentCode: equipmentCode
                }).then(response => {
                    console.log('createWOWithKit', response);
                    const {activity, workorder} = response.body.data;
                    history.push(process.env.REACT_APP_FRONTEND + `${currentStore.code}/outbound/${kitcode}/${entityType}?equipment=${equipmentCode}&wo=${workorder}&activity=${activity}`);
                }).catch((error) => {
                    this.props.handleError(error);
                });
                break;
            default:
                return null;
        }
    };

    renderEntityComponent() {
        const {transactionOn} = this.props.currentTransaction;
        switch (transactionOn) {
            case TransactionOn.WORKORDER:
                return <ContextSelectionWorkOrder {...this.props}/>;
            case TransactionOn.EQUIPMENT:
                return <KITContextSelectionEquipment {...this.props}/>;
            default:
                return null;
        }
    }

    render() {
        const entityComponent = this.renderEntityComponent();

        return (
            <div style={{margin: '20px'}}>
                {
                    this.props.currentTransaction && entityComponent &&
                    <div style={{ borderRadius: '5px',  }}>
                        {
                            entityComponent
                        }
                        <div style={{display: 'flex', justifyContent: 'center', flexGrow: 1}}>
                            <KioskButton style={{margin:'15px'}} onClick={this.goToPartsAssetsSelection(this.props.currentTransaction.transactionOn.toLowerCase())}>
                                Next
                            </KioskButton>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default ContextSelectionPage;