import {connect} from 'react-redux';
import {setPage} from "../../../../actions/layoutActions";
import ContextSelectionPageDetails from "./KITContextSelectionPageDetails";
import {logout, updateApplication} from "../../../../actions/applicationActions";
import {updateTransaction} from "../../../../actions/currentTransactionActions";
import {handleError, openConfirmDialog, showError, showWarning} from '../../../../react-eis-components/actions/uiActions';

const mapStateToProps = (state, ownProps) => {
    return {
        currentStore: state.application.currentStore,
        currentTransaction: state.currentTransaction
    }
};

const KITContextSelectionPageDetailsContainer = connect(
    mapStateToProps, {
        updateTransaction,
        setPage,
        updateApplication,
        handleError,
        openConfirmDialog,
        logout,
        showError,
        showWarning
    }
)(ContextSelectionPageDetails);

export default KITContextSelectionPageDetailsContainer;
